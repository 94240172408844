import { makeStyles, shorthands } from "@griffel/react";
import * as styleConstants from "./title-constants-fabric.styles";

export const useTitleStylesFabric = makeStyles({
  title: {
    color: styleConstants.DEFAULT_COLOR,
    fontSize: styleConstants.TITLE_FONT_SIZE,
    fontWeight: styleConstants.TITLE_FONT_WEIGHT,
    lineHeight: styleConstants.TITLE_LINE_HEIGHT,
    marginTop: styleConstants.TITLE_MARGIN_TOP,
    marginBottom: styleConstants.TITLE_MARGIN_BOTTOM,
    ...shorthands.padding(styleConstants.TITLE_PADDING),
  },
  subtitle: {
    fontSize: styleConstants.SUBTITLE_FONT_SIZE,
    fontWeight: styleConstants.SUBTITLE_FONT_WEIGHT,
    lineHeight: styleConstants.SUBTITLE_LINE_HEIGHT,
  },
});

export type TitleStylesFabric = ReturnType<typeof useTitleStylesFabric>;
