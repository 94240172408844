import { useContext } from "react";
import { type To, useLocation, useNavigate } from "react-router-dom";
import { type ViewId } from "../constants/routing-constants";
import { GlobalContext } from "../global-context";
import { GlobalActionType } from "../global-reducer";
import { HistoryContext } from "../history-context";
import { HistoryActionType } from "../history-reducer";
import { getRouteFromViewId, isViewId } from "../utilities/routing-helper";

/**
 * React hook that returns a method for changing location and setting the navigation direction.
 * Sets the navigation to "forward" when a path is provided.
 * Sets the navigation to "back" when a delta is provided, or when a path and isPathBack parameter is provided.
 * @returns A function that changes the location and dispatches the BeginNavigate action.
 */
export const useNavigateDirection = () => {
  const navigate = useNavigate();
  const { dispatchStateChange: dispatchGlobal } = useContext(GlobalContext);
  const {
    historyState: { historyIndex: currentHistoryIndex },
    dispatchStateChange: dispatchHistoryStateChange,
  } = useContext(HistoryContext);

  return (
    source: ViewId | string,
    deltaOrTo: To | number | ViewId,
    // this is to account for edge case where we navigate back with a specific path instead of delta (e.g., agreement view)
    isPathBack = false,
    options?: { replace?: boolean; state?: unknown },
  ) => {
    const navigationDirection = deltaOrTo === -1 || isPathBack ? "back" : "forward";

    let destination: string = "";
    if (typeof deltaOrTo === "string") {
      destination = deltaOrTo as string;
    }

    dispatchGlobal({
      type: GlobalActionType.BeginNavigate,
      source,
      destination,
      displayOptions: { navigationDirection },
    });

    if (deltaOrTo instanceof Number) {
      navigate(deltaOrTo as number);
    } else if (isViewId(deltaOrTo)) {
      navigate(getRouteFromViewId(deltaOrTo) as To, options);
    } else {
      navigate(deltaOrTo as To, options);
    }

    // Update history index based on navigate direction
    if (window.history.pushState) {
      if (navigationDirection === "back") {
        dispatchHistoryStateChange({
          type: HistoryActionType.SetHistoryIndexAction,
          payload: currentHistoryIndex - 1,
        });
      } else {
        dispatchHistoryStateChange({
          type: HistoryActionType.SetHistoryIndexAction,
          payload: currentHistoryIndex + 1,
        });
      }

      // Push state (current history index) to add a history entry. This is needed to actually trigger a pop state event
      // when a user clicks browser back/forward buttons. This pushed state, along with the new
      // history index value after dispatch, will determine if the page navigates back or forward
      // during a pop state event (see logic in use-on-pop-state.ts)
      window.history.pushState(currentHistoryIndex, "");
    }
  };
};

/**
 * use location properties from React to determine if the view can go back
 * documentation - https://github.com/remix-run/history/blob/main/docs/api-reference.md#locationkey
 * @returns flag to indicate whether the view can go back
 */
export const useBackButtonControl = () => {
  const location = useLocation();
  return location.key !== "default";
};
