import { type RequestOptions } from "./request-helper";

export interface InterceptorFunction {
  (url: string, requestOptions: RequestOptions): [string, RequestOptions];
}

interface RequestInterceptorItem {
  interceptor: InterceptorFunction;
}

/**
 * RequestInterceptor: This class is used to intercept the request before it is sent to the server.
 * This class is a singleton class.
 * It handles only request interception. Can be extended to handle response interception as well.
 * Example:
 * ```typescript
 * RequestInterceptor.onRequest((url: string, options: RequestOptions) => {
 *    const extendedOptions = {
 *      ...options,
 *      headers: {
 *        ...options.headers,
 *        Authorization: `Bearer ${token}`,
 *      },
 *    };
 *    return [url, extendedOptions];
 * });
 * ```
 */
export class FetchRequestInterceptor {
  reqInterceptors: Array<RequestInterceptorItem> = [];

  private static instance: FetchRequestInterceptor;

  /**
   * Do not instiate this class directly. Use the getInstance method instead.
   */
  constructor() {
    if (FetchRequestInterceptor.instance) {
      throw new Error(
        "FetchRequestInterceptor: Instantiation failed: Use FetchRequestInterceptor.getInstance() instead of new.",
      );
    }
  }

  /**
   * Method to get the instance of the Singleton class.
   * @returns The singleton instance of the FetchRequestInterceptor class.
   */
  public static getInstance(): FetchRequestInterceptor {
    if (!FetchRequestInterceptor.instance) {
      FetchRequestInterceptor.instance = new FetchRequestInterceptor();
    }

    return FetchRequestInterceptor.instance;
  }

  /**
   * Method to add an interceptor to the requests.
   * @param interceptor Callback function to be called before the request is sent to the server.
   * 2 parameters are passed to the callback function: url and options.
   * @returns The interceptor function that was passed as a parameter.
   */
  onRequest(interceptor: InterceptorFunction) {
    this.reqInterceptors.push({
      interceptor,
    });

    return interceptor;
  }

  /**
   * Removes and interceptor by reference function name.
   * @param interceptor Callback function to be removed from the interceptors list.
   */
  removeInterceptor(interceptor: InterceptorFunction) {
    this.reqInterceptors = this.reqInterceptors.filter((item) => item.interceptor !== interceptor);
  }

  /**
   * Removes all the interceptors.
   */
  reset() {
    this.reqInterceptors = [];
  }

  /**
   * The core method of the class. This method executes all the interceptors on the fetch params.
   * Each interceptor can modify the url and options and pass them to the next interceptor.
   * @param url Endpoint of the fetch
   * @param requestOptions Options of the fetch
   * @returns [url, options] The modified url and options after running all the interceptors.
   */
  runBeforeRequest(url: string, requestOptions: RequestOptions): [string, RequestOptions] {
    let urlExt = url;
    let optionsExt = requestOptions;
    for (let i = 0; i < this.reqInterceptors.length; i += 1) {
      const item = this.reqInterceptors[i];
      const [urlNew, requestOptionsNew] = item.interceptor(urlExt, optionsExt);
      urlExt = urlNew || urlExt;
      optionsExt = requestOptionsNew || optionsExt;
    }

    return [urlExt, optionsExt];
  }
}

export const RequestInterceptor = FetchRequestInterceptor.getInstance();
