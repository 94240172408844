import React from "react";
import StylesConfig from "../config/styles-config";
import { replaceTokens } from "../utilities/strings-helper";

/**
 * Page shown when a blocking error occurs
 * @param property Object with component properties.
 * @param property.errorCode Code of the error that occured.
 * @returns Error page
 */
const ErrorComponent: React.FC<{ errorCode: string }> = function ErrorComponent({ errorCode }) {
  const { useBackgroundStyles, useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;
  useStaticCommonStyles();
  const commonStyles = useCommonStyles();
  const backgroundStyles = useBackgroundStyles();
  const innerClassName = commonStyles.inner;

  return (
    <div
      id="background-container"
      itemID="backgroundContainer"
      data-testid="background-container"
      className={backgroundStyles.baseBackground}
      role="presentation"
      style={{ backgroundColor: "transparent" }}
    >
      <div id="outer" data-testid="outer" className={commonStyles.outer} role="presentation">
        <div id="middle" className={commonStyles.middle}>
          <div id="inner" data-testid="inner" className={innerClassName} role="main">
            <div
              id="pageContent"
              data-testid="paginationView"
              className={commonStyles.paginationView}
            >
              {replaceTokens(
                getLocalString("Error_SomethingWentWrong_KnownError_GetSupport"),
                errorCode,
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorComponent;
