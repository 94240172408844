import { makeStyles, shorthands } from "@griffel/react";
import * as identityBannerStyleConstants from "./constants-fabric.styles";
import * as tileStyleConstants from "./tile-constants-fabric.styles";

export const useBackArrowButtonStylesFabric = makeStyles({
  default: {
    height: `${identityBannerStyleConstants.IDENTITY_BANNER_HEIGHT}px`,
    minHeight: `${identityBannerStyleConstants.IDENTITY_BANNER_HEIGHT}px`,
    width: `${identityBannerStyleConstants.IDENTITY_BANNER_HEIGHT}px`,
    minWidth: `${identityBannerStyleConstants.IDENTITY_BANNER_HEIGHT}px`,
    float: "left",
    marginRight: "2px",
    backgroundColor: "transparent",
    ...shorthands.padding(0),
    ...shorthands.borderWidth(0),
    ...shorthands.borderRadius(`${identityBannerStyleConstants.IDENTITY_BANNER_HEIGHT / 2}px`),
    ":global([dir='rtl'])": {
      float: "right",
      marginRight: "0px",
      marginLeft: "2px",
    },
    ":hover": {
      cursor: "pointer",
      backgroundColor: tileStyleConstants.DEFAULT_HOVER_FOCUS_BACKGROUND_COLOR,
      ":focus": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      },
    },
    ":active": {
      outlineStyle: "none",
      backgroundColor: tileStyleConstants.DEFAULT_ACTIVE_BACKGROUND_COLOR,
    },
    ":focus": {
      outlineColor: "#000",
      outlineWidth: "2px",
      outlineStyle: "solid",
      backgroundColor: tileStyleConstants.DEFAULT_HOVER_FOCUS_BACKGROUND_COLOR,
    },
  },
  highContrast: {
    "@media screen and (-ms-high-contrast: active)": {
      outlineStyle: "none",
      ...shorthands.borderWidth("1px"),
      ...shorthands.borderStyle("solid"),
      ...shorthands.borderColor("window"),
      backgroundColor: "window",
      color: "windowText",
      ":hover": {
        outlineStyle: "none",
        ...shorthands.borderWidth("1px"),
        ...shorthands.borderStyle("solid"),
        ...shorthands.borderColor("highlight"),
        backgroundColor: "window",
        color: "windowText",
        ":focus": {
          outlineStyle: "none",
          ...shorthands.borderWidth("1px"),
          ...shorthands.borderStyle("solid"),
          ...shorthands.borderColor("highlight"),
          backgroundColor: "window",
          color: "windowText",
        },
      },
      ":focus": {
        outlineStyle: "none",
        ...shorthands.borderWidth("1px"),
        ...shorthands.borderStyle("dashed"),
        ...shorthands.borderColor("highlight"),
        backgroundColor: "window",
        color: "windowText",
      },
      ":active": {
        outlineStyle: "none",
        ...shorthands.borderWidth("1px"),
        ...shorthands.borderStyle("dashed"),
        ...shorthands.borderColor("highlight"),
        backgroundColor: "window",
        color: "windowText",
      },
    },
  },
});

export type BackArrowButtonStylesFabric = ReturnType<typeof useBackArrowButtonStylesFabric>;
