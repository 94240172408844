import { makeStyles, shorthands } from "@griffel/react";
import * as styleConstants from "./constants-fabric.styles";
import * as tileStyleConstants from "./tile-constants-fabric.styles";

export const useTileStylesFabric = makeStyles({
  tileContainer: {
    position: "relative",
    maxWidth: `${styleConstants.LIGHTBOX_MAX_WIDTH}px`,
  },

  rowTile: {
    marginBottom: 0,
    color: "inherit",
    display: "block",
    marginLeft: tileStyleConstants.TILE_ROW_MARGIN,
    marginRight: tileStyleConstants.TILE_ROW_MARGIN,
    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      marginLeft: tileStyleConstants.TILE_ROW_MOBILE_MARGIN,
      marginRight: tileStyleConstants.TILE_ROW_MOBILE_MARGIN,
    },
  },

  middle: {
    display: "table-cell",
    verticalAlign: "middle",
  },

  tileButtonSettings: {
    cursor: "pointer",
    width: "100%",
    display: "table",
    tableLayout: "fixed",
    paddingTop: `${styleConstants.TILE_PADDING}px`,
    paddingRight: `${styleConstants.LIGHTBOX_PADDING}px`,
    paddingBottom: `${styleConstants.TILE_PADDING}px`,
    paddingLeft: `${styleConstants.LIGHTBOX_PADDING}px`,
    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      paddingLeft: `${styleConstants.LIGHTBOX_MOBILE_PADDING}px`,
      paddingRight: `${styleConstants.LIGHTBOX_MOBILE_PADDING}px`,
    },
    backgroundColor: "transparent",
    ...shorthands.borderWidth("0px"),
    ":hover": {
      backgroundColor: tileStyleConstants.DEFAULT_HOVER_FOCUS_BACKGROUND_COLOR,
      cursor: "pointer",
    },
    ":focus": {
      outlineWidth: tileStyleConstants.OUTLINE_WIDTH,
      outlineColor: tileStyleConstants.OUTLINE_COLOR,
      outlineStyle: tileStyleConstants.OUTLINE_STYLE,
      backgroundColor: tileStyleConstants.DEFAULT_HOVER_FOCUS_BACKGROUND_COLOR,
    },
    ":active": {
      outlineWidth: tileStyleConstants.OUTLINE_WIDTH,
      outlineColor: tileStyleConstants.OUTLINE_COLOR,
      outlineStyle: tileStyleConstants.OUTLINE_STYLE,
      backgroundColor: tileStyleConstants.DEFAULT_ACTIVE_BACKGROUND_COLOR,
    },
  },

  tileTextAlign: {
    textAlign: "left",
    ":global([dir='rtl'])": {
      textAlign: "right",
    },
  },

  content: {
    lineHeight: "16px",
    paddingLeft: `${styleConstants.TILE_PADDING}px`,
    paddingRight: `${styleConstants.TILE_PADDING}px`,
    wordBreak: "break-word",
  },

  small: {
    fontSize: "80%",
    wordBreak: "break-word",
  },

  helpButton: {
    position: "absolute",
    top: "35%",
    right: 0,
    marginLeft: tileStyleConstants.HELP_BUTTON_MARGIN,
    marginRight: tileStyleConstants.HELP_BUTTON_MARGIN,
    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      marginLeft: tileStyleConstants.HELP_BUTTON_MOBILE_MARGIN,
      marginRight: tileStyleConstants.HELP_BUTTON_MOBILE_MARGIN,
    },
    ":global([dir='rtl'])": {
      left: 0,
      right: "unset",
    },
  },

  menuButton: {
    position: "absolute",
    top: "35%",
    right: 0,
    width: "23px",
  },

  tileImage: {
    position: "relative",
    verticalAlign: "middle",
    width: tileStyleConstants.TILE_IMAGE_SIZE,
    height: tileStyleConstants.TILE_IMAGE_SIZE,
  },

  moreOptionsTile: {
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    width: "calc(100% - 40px)",
    marginBottom: `${styleConstants.FOOTER_HEIGHT}px`,
    maxWidth: `${styleConstants.LIGHTBOX_MAX_WIDTH}px`,
    lineHeight: `${styleConstants.FORM_GROUP_MARGIN}px`,
    minWidth: `${styleConstants.LIGHTBOX_MIN_WIDTH}px`,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    wordBreak: "break-word",
    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      marginTop: "1px",
      marginBottom: "88px",
      paddingLeft: `${styleConstants.LIGHTBOX_MOBILE_PADDING}px`,
      paddingRight: `${styleConstants.LIGHTBOX_MOBILE_PADDING}px`,
      width: "100vw",
      minWidth: "auto",
    },
  },

  moreOptionsTileWithAppBranding: {
    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      ...shorthands.padding(0),
    },
  },

  moreOptionsRowTile: {
    backgroundColor: styleConstants.LIGHTBOX_BACKGROUND_COLOR,
    boxShadow: `${styleConstants.LIGHTBOX_SHADOW}`,
    paddingLeft: tileStyleConstants.TILE_ROW_MARGIN,
    paddingRight: tileStyleConstants.TILE_ROW_MARGIN,
    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      boxShadow: "none",
      paddingLeft: tileStyleConstants.TILE_ROW_MOBILE_MARGIN,
      paddingRight: tileStyleConstants.TILE_ROW_MOBILE_MARGIN,
      outlineWidth: tileStyleConstants.OUTLINE_WIDTH,
      outlineStyle: "solid",
      outlineColor: "rgba(0,0,0,.4)",
    },
  },

  moreOptionsTileButtonSettings: {
    cursor: "pointer",
    width: "100%",
    display: "table",
    tableLayout: "fixed",
    paddingTop: `8px`,
    paddingRight: `${styleConstants.LIGHTBOX_PADDING}px`,
    paddingBottom: `8px`,
    paddingLeft: `${styleConstants.LIGHTBOX_PADDING}px`,
    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      paddingLeft: `${styleConstants.LIGHTBOX_MOBILE_PADDING}px`,
      paddingRight: `${styleConstants.LIGHTBOX_MOBILE_PADDING}px`,
    },
    backgroundColor: "transparent",
    ...shorthands.borderWidth("0px"),
    ":hover": {
      backgroundColor: tileStyleConstants.DEFAULT_HOVER_FOCUS_BACKGROUND_COLOR,
      cursor: "pointer",
    },
    ":focus": {
      outlineWidth: tileStyleConstants.OUTLINE_WIDTH,
      outlineColor: tileStyleConstants.OUTLINE_COLOR,
      outlineStyle: tileStyleConstants.OUTLINE_STYLE,
      backgroundColor: tileStyleConstants.DEFAULT_HOVER_FOCUS_BACKGROUND_COLOR,
    },
    ":active": {
      outlineWidth: tileStyleConstants.OUTLINE_WIDTH,
      outlineColor: tileStyleConstants.OUTLINE_COLOR,
      outlineStyle: tileStyleConstants.OUTLINE_STYLE,
      backgroundColor: tileStyleConstants.DEFAULT_ACTIVE_BACKGROUND_COLOR,
    },
  },

  moreOptionsTileImage: {
    position: "relative",
    verticalAlign: "middle",
    width: "32px",
    height: "32px",
  },

  tileError: {
    position: "relative",
    minHeight: "1px",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
});

export type TileStylesFabric = ReturnType<typeof useTileStylesFabric>;
