import React, { useEffect, useRef, useState } from "react";
import { getKeyboardFocusableElements } from "../utilities/browser-helper";

/** Keyboard keys that will be tracked during keyDown events */
enum KeyboardKeys {
  Tab = "Tab",
}

// Helpers for identifying the users' tabbing direction
const tabbingBackwards = (e: KeyboardEvent) => e.key === KeyboardKeys.Tab && e.shiftKey;
const tabbingForwards = (e: KeyboardEvent) => e.key === KeyboardKeys.Tab && !e.shiftKey;

/**
 * Adds event handlers to lock focus inside a specified element
 * @param element The React ref of the element that will be locked
 * @param setSelectedElement The React dispatch function to change the selected element
 */
export const focusLock = (
  element: HTMLElement,
  setSelectedElement: (element: HTMLElement) => void,
) => {
  // On load retrieve all of the focusable children
  const focusable = getKeyboardFocusableElements(element);
  const { length } = focusable;
  const firstIndex = 0;
  const lastIndex = Math.max(length - 1, 0);
  // Helpers for navigating the focusable nodeList
  const firstElement = focusable[firstIndex];
  const lastElement = focusable[lastIndex];

  /**
   * @param idx index of the current element
   * @returns the next element in the focusable array
   */
  function getNextElement(idx: number) {
    const index = idx + 1 === length ? 0 : idx + 1;
    return focusable[index];
  }

  /**
   * @param idx index of the current element
   * @returns the previous element in the focusable array
   */
  function getPreviousElement(idx: number) {
    const index = idx === 0 ? length - 1 : idx - 1;
    return focusable[index];
  }

  for (let index = 0; index < length; index += 1) {
    if (index === firstIndex) {
      firstElement.addEventListener("keydown", (event) => {
        /** Move focus from first to last */
        if (tabbingForwards(event)) {
          setSelectedElement(getNextElement(index));
          // If there is only one focusable element, we need to prevent the default behavior to avoid getting out of the focus locker
          if (firstIndex === lastIndex) {
            event.preventDefault();
          }
        } else if (tabbingBackwards(event)) {
          lastElement.focus();
          event.preventDefault();
          setSelectedElement(lastElement);
        }
      });
    } else if (index === lastIndex) {
      lastElement.addEventListener("keydown", (event) => {
        /** Move focus from last to first */
        if (tabbingForwards(event)) {
          firstElement.focus();
          event.preventDefault();
          setSelectedElement(firstElement);
        } else if (tabbingBackwards(event)) {
          setSelectedElement(getPreviousElement(index));
        }
      });
      /** Every other focusable element just keep track of selectedElements */
    } else {
      focusable[index].addEventListener("keydown", (event) => {
        if (tabbingForwards(event)) {
          setSelectedElement(getNextElement(index));
        } else if (tabbingBackwards(event)) {
          setSelectedElement(getPreviousElement(index));
        }
      });
    }
  }
};

/**
 * A container component that tracks tab events from its children and prevents users
 * from visually navigating to the background. For accessibility purposes, the focus
 * will be transferred from last to first focusable child. Given the blocking nature
 * of the FocusLocker, its visibility should be controlled by external methods.
 * See Dialog story for examples
 * @param props FocusLockerProps
 * @returns the FocusLocker component
 */
export const FocusLocker: React.FC<{}> = function FocusLocker(props) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { children } = props;

  /**
   * A hook to keep track of the last selected focusable element
   * Used by onClickHandler
   */
  const [selectedElement, setSelectedElement] = useState<HTMLElement>();
  useEffect(() => {
    if (wrapperRef.current) {
      focusLock(wrapperRef.current, setSelectedElement);
    }
  }, []);

  /**
   * Tracks click events that could unlock the focus and returns it to the
   * last selected element
   */
  const onClickHandler = () => {
    selectedElement?.focus();
  };

  return (
    <div
      /** Prevent eslint error when adding onclick to non interactive elements */
      role="none"
      onClick={onClickHandler}
      ref={wrapperRef}
      data-testid="focus-locker"
    >
      {children}
    </div>
  );
};
