import { DefaultBrandingStyles, LayoutTemplateType } from "../constants/constants";
import {
  getAppBackgroundImageSource,
  getAppLogoImageSource,
  getBackgroundImageSource,
} from "./images-helper";
import { type ServerData } from "./server-data";
import { htmlUnescape } from "./strings-helper";

export interface IAccentColors {
  primaryButtonDefaultColor: string;
  primaryButtonHoverColor: string;
  primaryButtonFocusColor: string;
  primaryButtonStringColor: string;
}

export const defaultAccentColors: IAccentColors = {
  primaryButtonDefaultColor: "",
  primaryButtonHoverColor: "",
  primaryButtonFocusColor: "",
  primaryButtonStringColor: "",
};

export interface ICustomLayoutConfig {
  headerLogo: string;
  hidePrivacy: boolean;
  hideTOU: boolean;
  hideAccountResetCredentials: boolean;
  layoutType: LayoutTemplateType;
  showFooter: boolean;
  showHeader: boolean;
}

interface IAppBrandingAccentColors {
  primaryButtonColor: string;
  primaryButtonHoverColor: string;
  primaryButtonFocusColor: string;
  primaryButtonStringColor: string;
}

export interface IAppBranding {
  accentColors: Partial<IAppBrandingAccentColors>;
  backgroundColor: string;
  backgroundImageIndex: number;
  backgroundLogoIndex: number;
  friendlyAppName: string;
}

export interface IPageBranding {
  accentColors: IAccentColors;
  alwaysShowBackground: boolean;
  backgroundImageUrl: string;
  backgroundLogoUrl: string;
  bannerLogoUrl: string;
  bannerLogoText: string;
  color: string;
  friendlyAppName: string;
  useDarkFooter: boolean;
  useImageMask: boolean;
  useTransparentLightBox: boolean;
}

export interface IStringCustomizationFiles {
  adminConsent?: string;
  attributeCollection?: string;
  authenticatorNudgeScreen?: string;
  conditionalAccess?: string;
}

export interface ICustomizationFiles {
  customCssUrl?: string | null;
  strings?: IStringCustomizationFiles;
}

export interface ITenantBranding {
  [index: string]: string | boolean | ICustomizationFiles | ICustomLayoutConfig;
  AccessRecoveryLink: string;
  BackgroundColor: string;
  BannerLogo: string;
  BoilerPlateText: string;
  CantAccessYourAccountText: string;
  CustomizationFiles: ICustomizationFiles;
  Favicon: string;
  FooterPrivacyLink: string;
  FooterPrivacyText: string;
  FooterTOULink: string;
  FooterTOUText: string;
  ForgotPasswordText: string;
  Illustration: string;
  KeepMeSignedInDisabled: boolean;
  LayoutTemplateConfig: ICustomLayoutConfig;
  TileDarkLogo: string;
  TileLogo: string;
  UseTransparentLightBox: boolean;
  UserIdLabel: string;
  Unsafe_UserIdLabel: string;
}

export interface IBrandingStyles {
  accessRecoveryUrl: string;
  accentColors: IAccentColors;
  alwaysShowBackground: boolean;
  backgroundColor: string;
  backgroundImageUrl: string;
  backgroundLogoUrl: string;
  bannerLogoUrl: string;
  boilerPlateText: string;
  cantAccessYourAccountText: string;
  customizationFiles: ICustomizationFiles;
  faviconUrl: string;
  forgotPasswordText: string;
  friendlyAppName: string;
  headerLogoUrl: string;
  layoutTemplate: LayoutTemplateType;
  privacyText: string;
  privacyUrl: string;
  showAccountResetCredentials: boolean;
  showFooter: boolean;
  showHeader: boolean;
  showPrivacy: boolean;
  showTermsOfUse: boolean;
  termsOfUseText: string;
  termsOfUseUrl: string;
  bannerLogoText: string;
  useBackgroundImageMask: boolean;
  useDarkFooter: boolean;
  useTransparentLightbox: boolean;
}

export const brandingProperties = [
  "BoilerPlateText",
  "UserIdLabel",
  "TileLogo",
  "TileDarkLogo",
  "BannerLogo",
  "BackgroundColor",
  "Illustration",
  "KeepMeSignedInDisabled",
  "UseTransparentLightBox",
  "LayoutTemplateConfig",
  "CustomizationFiles",
  "AccessRecoveryLink",
  "CantAccessYourAccountText",
  "ForgotPasswordText",
  "FooterTOULink",
  "FooterTOUText",
  "FooterPrivacyLink",
  "FooterPrivacyText",
  "Favicon",
];

export const defaultLayoutConfig: ICustomLayoutConfig = {
  showHeader: false,
  headerLogo: "",
  layoutType: LayoutTemplateType.Lightbox,
  showFooter: true,
  hideTOU: false,
  hidePrivacy: false,
  hideAccountResetCredentials: false,
};

/**
 * @private
 */
export function getTenantBranding(
  brandingToUse: Array<Partial<ITenantBranding>> | undefined,
  useDefaultCiamBranding?: boolean,
): Partial<ITenantBranding> {
  let tenantBranding: Partial<ITenantBranding> = {};

  if (brandingToUse) {
    const fallbackBranding: Partial<ITenantBranding> = brandingToUse[0] ?? {};
    const preferredBranding: Partial<ITenantBranding> = brandingToUse[1] ?? {};

    // Merge the fallback and the preferred branding objects into the branding object.
    // The preferred branding object takes precedence over the fallback branding object.
    tenantBranding = brandingProperties.reduce((branding: Partial<ITenantBranding>, prop) => {
      const propertyName = prop as keyof ITenantBranding;

      if (prop === "LayoutTemplateConfig") {
        const currentPreferredValue: ICustomLayoutConfig =
          (preferredBranding[propertyName] as ICustomLayoutConfig) || {};
        const currentFallbackValue: ICustomLayoutConfig =
          (fallbackBranding[propertyName] as ICustomLayoutConfig) || {};

        const layoutConfig: ICustomLayoutConfig = {
          ...currentFallbackValue,
          ...currentPreferredValue,
        };

        if (Object.entries(layoutConfig).length > 0) {
          return {
            ...branding,
            LayoutTemplateConfig: layoutConfig,
          };
        }

        return branding;
      }

      if (prop === "CustomizationFiles") {
        const currentPreferredValue: ICustomizationFiles =
          (preferredBranding[propertyName] as ICustomizationFiles) || {};
        const currentFallbackValue: ICustomizationFiles =
          (fallbackBranding[propertyName] as ICustomizationFiles) || {};

        const customizationFiles: ICustomizationFiles = {
          ...currentFallbackValue,
          ...currentPreferredValue,
        };

        if (Object.entries(customizationFiles).length > 0) {
          return {
            ...branding,
            CustomizationFiles: {
              ...customizationFiles,
              strings: { ...currentFallbackValue.strings, ...currentPreferredValue.strings },
            },
          };
        }

        return branding;
      }

      const valueToAssign = preferredBranding[propertyName] ?? fallbackBranding[propertyName];

      if (valueToAssign !== undefined) {
        return {
          ...branding,
          [propertyName]: valueToAssign,
        };
      }

      return branding;
    }, tenantBranding);

    if (!tenantBranding.TileDarkLogo && tenantBranding.TileLogo) {
      tenantBranding.TileDarkLogo = tenantBranding.TileLogo;
    }

    // We want this for all traffic, not just CIAM. Using the default CIAM feature flag to rollout at the same time.
    if (useDefaultCiamBranding && tenantBranding.UserIdLabel) {
      tenantBranding.Unsafe_UserIdLabel = htmlUnescape(tenantBranding.UserIdLabel);
    }
  }

  return tenantBranding;
}

/**
 * @private
 */
export function createLayoutConfig(
  tenantBranding: Partial<ITenantBranding> | undefined,
  isCiamUserFlow?: boolean,
  useDefaultCiamBranding?: boolean,
): ICustomLayoutConfig {
  let layoutConfig = tenantBranding?.LayoutTemplateConfig;
  if (!layoutConfig || Object.entries(layoutConfig).length === 0) {
    layoutConfig = {
      ...defaultLayoutConfig,
    };

    if (useDefaultCiamBranding && isCiamUserFlow) {
      layoutConfig.showFooter = false;
    }
  }

  return layoutConfig;
}

/**
 * @private
 */
export function getMergedBranding(
  staticTenantBranding: Partial<ITenantBranding> | undefined,
  dynamicTenantBranding: Partial<ITenantBranding> | undefined,
  isGlobalTenant?: boolean,
  isCiamUserFlow?: boolean,
  useDefaultCiamBranding?: boolean,
  fallbackToResourceBranding?: boolean,
): Partial<ITenantBranding> {
  let mergedBranding: Partial<ITenantBranding>;

  if (isGlobalTenant) {
    // for the global tenant, all of the branding is dynamic
    mergedBranding = dynamicTenantBranding ?? {};
  } else {
    // For tenanted endpoints, the resource tenant branding is default,
    // but the branding properties inside the lightbox (BannerLogo, BoilerPlateText, KeepMeSignedInDisabled,
    // AccessRecoveryLink, CantAccessYourAccountText, ForgotPasswordText, hideAccountResetCredentials & CustomStrings)
    // should be assigned either home tenant branding or MS default values.
    mergedBranding = staticTenantBranding ?? {};

    if (fallbackToResourceBranding) {
      if (dynamicTenantBranding?.BannerLogo) {
        mergedBranding.BannerLogo = dynamicTenantBranding.BannerLogo;
      }

      if (dynamicTenantBranding?.BoilerPlateText) {
        mergedBranding.BoilerPlateText = dynamicTenantBranding.BoilerPlateText;
      }

      if (dynamicTenantBranding?.KeepMeSignedInDisabled) {
        mergedBranding.KeepMeSignedInDisabled = dynamicTenantBranding.KeepMeSignedInDisabled;
      }

      if (dynamicTenantBranding?.AccessRecoveryLink) {
        mergedBranding.AccessRecoveryLink = dynamicTenantBranding.AccessRecoveryLink;
      }

      if (dynamicTenantBranding?.CantAccessYourAccountText) {
        mergedBranding.CantAccessYourAccountText = dynamicTenantBranding.CantAccessYourAccountText;
      }

      if (dynamicTenantBranding?.ForgotPasswordText) {
        mergedBranding.ForgotPasswordText = dynamicTenantBranding.ForgotPasswordText;
      }
    } else {
      mergedBranding.BannerLogo = dynamicTenantBranding?.BannerLogo || "";
      mergedBranding.BoilerPlateText = dynamicTenantBranding?.BoilerPlateText || "";
      mergedBranding.KeepMeSignedInDisabled =
        dynamicTenantBranding?.KeepMeSignedInDisabled || false;
      mergedBranding.AccessRecoveryLink = dynamicTenantBranding?.AccessRecoveryLink || "";
      mergedBranding.CantAccessYourAccountText =
        dynamicTenantBranding?.CantAccessYourAccountText || "";
      mergedBranding.ForgotPasswordText = dynamicTenantBranding?.ForgotPasswordText || "";
    }

    const hideAccountResetCredentials =
      dynamicTenantBranding?.LayoutTemplateConfig?.hideAccountResetCredentials || false;

    mergedBranding.LayoutTemplateConfig =
      mergedBranding.LayoutTemplateConfig ||
      createLayoutConfig(undefined, isCiamUserFlow, useDefaultCiamBranding);
    mergedBranding.LayoutTemplateConfig.hideAccountResetCredentials = hideAccountResetCredentials;

    if (dynamicTenantBranding?.CustomizationFiles) {
      // Create new customization files override object.
      const newCustomizationFiles = {
        strings: dynamicTenantBranding.CustomizationFiles?.strings,
        customCssUrl: dynamicTenantBranding.CustomizationFiles?.customCssUrl,
      };

      // If both resource and home tenants have configured custom CSS we won't use any
      // until there is a final agreement on which takes precedence.

      if (mergedBranding?.CustomizationFiles?.customCssUrl !== newCustomizationFiles.customCssUrl) {
        newCustomizationFiles.customCssUrl = undefined;
      }

      mergedBranding.CustomizationFiles = newCustomizationFiles;
    }
  }

  return mergedBranding;
}

/**
 * @private
 */
export function getPageBranding(
  tenantBranding: Partial<ITenantBranding>,
  appBranding: Partial<IAppBranding> | undefined,
  defaultImageIndex?: number,
  isCiamUserFlow?: boolean,
  companyDisplayName?: string,
  useDefaultCiamBranding?: boolean,
): Partial<IPageBranding> {
  const pageBranding: Partial<IPageBranding> = {
    useDarkFooter: false,
    alwaysShowBackground: false,
  };

  if (tenantBranding.BannerLogo) {
    pageBranding.bannerLogoUrl = tenantBranding.BannerLogo;
  } else if (useDefaultCiamBranding && isCiamUserFlow && companyDisplayName) {
    pageBranding.bannerLogoText = companyDisplayName.toUpperCase();
  }

  if (tenantBranding.BackgroundColor || tenantBranding.Illustration) {
    // If tenant branding is present, it always takes precedence
    pageBranding.color = tenantBranding.BackgroundColor;
    pageBranding.backgroundImageUrl = tenantBranding.Illustration;
    pageBranding.useTransparentLightBox = tenantBranding.UseTransparentLightBox;

    pageBranding.useImageMask = useDefaultCiamBranding ? !isCiamUserFlow : true;

    pageBranding.useDarkFooter = true;
  } else if (
    (appBranding?.backgroundImageIndex !== undefined && appBranding.backgroundImageIndex >= 0) ||
    (appBranding?.backgroundLogoIndex !== undefined && appBranding.backgroundLogoIndex >= 0) ||
    appBranding?.backgroundColor ||
    appBranding?.friendlyAppName
  ) {
    pageBranding.useDarkFooter = true;

    // If no tenant branding is present but app branding is present, it takes precedence over our default branding
    if (appBranding.backgroundImageIndex !== undefined && appBranding.backgroundImageIndex >= 0) {
      pageBranding.backgroundImageUrl = getAppBackgroundImageSource(
        appBranding.backgroundImageIndex.toString(),
      );
    }

    if (appBranding.backgroundLogoIndex !== undefined && appBranding.backgroundLogoIndex >= 0) {
      pageBranding.backgroundLogoUrl = getAppLogoImageSource(
        appBranding.backgroundLogoIndex.toString(),
      );
      pageBranding.alwaysShowBackground = true;
    }

    pageBranding.color = appBranding.backgroundColor;
    pageBranding.friendlyAppName = appBranding.friendlyAppName;

    if (appBranding.accentColors) {
      pageBranding.accentColors = {
        primaryButtonDefaultColor: appBranding.accentColors.primaryButtonColor || "",
        primaryButtonHoverColor: appBranding.accentColors.primaryButtonHoverColor || "",
        primaryButtonFocusColor: appBranding.accentColors.primaryButtonFocusColor || "",
        primaryButtonStringColor: appBranding.accentColors.primaryButtonStringColor || "",
      };
    }
  } else if (useDefaultCiamBranding && isCiamUserFlow) {
    pageBranding.color = DefaultBrandingStyles.BackgroundColor;
  } else if (defaultImageIndex !== undefined && defaultImageIndex >= 0) {
    pageBranding.backgroundImageUrl = getBackgroundImageSource(defaultImageIndex.toString());
  }

  return pageBranding;
}

/**
 * @private
 */
export function convertBranding(
  tenantBranding: Partial<ITenantBranding>,
  layoutConfig: ICustomLayoutConfig,
  pageBranding: Partial<IPageBranding>,
): IBrandingStyles {
  return {
    // TenantBranding
    boilerPlateText: tenantBranding.BoilerPlateText || "",
    customizationFiles: tenantBranding.CustomizationFiles || {},
    faviconUrl: tenantBranding.Favicon || "",
    privacyText: tenantBranding.FooterPrivacyText || "",
    privacyUrl: tenantBranding.FooterPrivacyLink || "",
    termsOfUseText: tenantBranding.FooterTOUText || "",
    termsOfUseUrl: tenantBranding.FooterTOULink || "",
    accessRecoveryUrl: tenantBranding.AccessRecoveryLink || "",
    cantAccessYourAccountText: tenantBranding.CantAccessYourAccountText || "",
    forgotPasswordText: tenantBranding.ForgotPasswordText || "",
    // LayoutConfig
    headerLogoUrl: layoutConfig.headerLogo,
    layoutTemplate: layoutConfig.layoutType,
    showAccountResetCredentials: !layoutConfig.hideAccountResetCredentials,
    showFooter: layoutConfig.showFooter,
    showHeader: layoutConfig.showHeader,
    showPrivacy: !layoutConfig.hidePrivacy,
    showTermsOfUse: !layoutConfig.hideTOU,
    // PageBranding
    accentColors: pageBranding.accentColors ?? defaultAccentColors,
    alwaysShowBackground: pageBranding.alwaysShowBackground || false,
    backgroundColor: pageBranding.color || "",
    backgroundImageUrl: pageBranding.backgroundImageUrl || "",
    backgroundLogoUrl: pageBranding.backgroundLogoUrl || "",
    bannerLogoUrl: pageBranding.bannerLogoUrl || "",
    bannerLogoText: pageBranding.bannerLogoText || "",
    friendlyAppName: pageBranding.friendlyAppName || "",
    useBackgroundImageMask: !!pageBranding.useImageMask,
    useDarkFooter: pageBranding.useDarkFooter || false,
    useTransparentLightbox: !!pageBranding.useTransparentLightBox,
  };
}

/**
 * This method takes the various pieces of branding from ServerData and converts them into a branding interface
 * @param serverData - window.ServerData from the IDP
 * @param fallbackToResourceBranding - If true, will fallback to the resource branding if home tenant branding is not present.
 * @returns Branding styles to use
 */
export function getBranding(
  serverData: ServerData,
  fallbackToResourceBranding?: boolean,
): IBrandingStyles {
  const {
    // AAD-TODO: Login uses this property for dynamic branding: oGetCredTypeResult.EstsProperties.UserTenantBranding
    dynamicTenantBranding: dynamicBranding,
    staticTenantBranding: staticBranding,
    oAppCobranding: appCoBranding,
    iBackgroundImage: defaultImageIndex,
    isGlobalTenant,
    fIsCiamUserFlowUx: isCiamUserFlow,
    fUseNonMicrosoftDefaultBrandingForCiam: useDefaultCiamBranding,
    sCompanyDisplayName: companyDisplayName,
  } = serverData;
  const mergedBranding = getMergedBranding(
    getTenantBranding(staticBranding, useDefaultCiamBranding),
    getTenantBranding(dynamicBranding, useDefaultCiamBranding),
    isGlobalTenant,
    isCiamUserFlow,
    useDefaultCiamBranding,
    fallbackToResourceBranding,
  );
  const layoutConfig = createLayoutConfig(mergedBranding, isCiamUserFlow, useDefaultCiamBranding);
  const pageBranding = getPageBranding(
    mergedBranding,
    appCoBranding,
    defaultImageIndex,
    isCiamUserFlow,
    companyDisplayName,
    useDefaultCiamBranding,
  );
  return convertBranding(mergedBranding, layoutConfig, pageBranding);
}

/**
 * @param property A property which may have app branding values
 * @returns {boolean} Whether the property is an object, number, string, or boolean
 */
export const checkAppBrandingProperties = function checkAppBrandingProperties(
  property: Object | number | string | boolean,
) {
  // Currently we only check for Objects, numbers, strings and booleans since these
  // are the only data types contained in IAppBrandingInfo
  if (Object.prototype.toString.call(property) === "[object Object]") {
    return Object.values(property).some(checkAppBrandingProperties);
  }

  if (typeof property === "number") {
    return property >= 0;
  }

  // handle the remaining types - strings and booleans
  return !!property;
};
