import { makeStyles } from "@griffel/react";

export const useProofImageStylesFabric = makeStyles({
  icon: {
    position: "relative",
    width: "48px",
    height: "48px",
  },

  small: {
    width: "24px",
    height: "24px",
    float: "left",
    marginRight: "8px",
    ":global([dir='rtl'])": {
      float: "right",
      marginLeft: "8px",
      marginRight: "0px",
    },
  },

  animate: {
    animationDuration: "1s",
    animationTimingFunction: "ease",
    animationDelay: "0s",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationName: {
      from: {
        opacity: 0.4,
      },
    },
  },
});

export type ProofImageStylesFabric = ReturnType<typeof useProofImageStylesFabric>;
