import { makeStyles, shorthands } from "@griffel/react";
import * as styleConstants from "./constants-fabric.styles";
import * as buttonStyleConstants from "./text-button-constants-fabric.styles";

const NEGATIVE_LIGHTBOX_PADDING = `-${styleConstants.LIGHTBOX_PADDING}px`;

export const useBoilerPlateStylesFabric = makeStyles({
  boilerPlateText: {
    wordWrap: "break-word",
    backgroundColor: "#f2f2f2",
    ...shorthands.padding(
      "24px",
      `${styleConstants.LIGHTBOX_PADDING}px`,
      "36px",
      `${styleConstants.LIGHTBOX_PADDING}px`,
    ),
    ...shorthands.margin(
      `${styleConstants.LIGHTBOX_PADDING + buttonStyleConstants.MINHEIGHT}px`,
      NEGATIVE_LIGHTBOX_PADDING,
      NEGATIVE_LIGHTBOX_PADDING,
      NEGATIVE_LIGHTBOX_PADDING,
    ),

    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      ...shorthands.padding(`${styleConstants.SECTION_PADDING}px`),
      ...shorthands.margin(
        `${styleConstants.LIGHTBOX_MOBILE_PADDING + buttonStyleConstants.MINHEIGHT}px`,
        0,
        0,
        0,
      ),
    },

    "& > p:first-child": {
      marginTop: 0,
    },

    "& > p:last-child": {
      marginBottom: 0,
    },
  },
  transparent: {
    backgroundColor: "rgba(242, 242, 242, 0.2)",
  },
  verticalSplit: {
    marginBottom: 0,
    backgroundColor: "transparent",
    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      backgroundColor: "transparent",
    },
  },
  transparentVerticalSplit: {
    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      backgroundColor: "rgba(242, 242, 242, 0.2)",
    },
  },
});

export type BoilerPlateStylesFabric = ReturnType<typeof useBoilerPlateStylesFabric>;
