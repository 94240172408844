import { makeStyles, shorthands } from "@griffel/react";
import * as styleConstants from "./constants-fabric.styles";

export const useRemoteNgcViewStylesFabric = makeStyles({
  authenticatorImage: {
    width: "352px",
    height: "176px",
  },

  descriptionPadding: {
    ...shorthands.padding("7px"),
  },

  pullLeft: {
    float: "left !important",
  },

  displaySign: {
    textAlign: "center",
    fontSize: "2.5rem",
    marginTop: `${styleConstants.FORM_GROUP_MARGIN}px`,
    marginBottom: `${styleConstants.FORM_GROUP_MARGIN}px`,
  },

  displaySignRow: {
    ":before": {
      content: '""',
      display: "table",
    },
    ":after": {
      clear: "both",
      content: '""',
      display: "table",
    },
  },
});

export type RemoteNgcViewStylesFabric = ReturnType<typeof useRemoteNgcViewStylesFabric>;
