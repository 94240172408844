import { type ITelemetryEvent } from "@msidentity/telemetry";
import { type Flavors } from "../constants";
import { type FlowId, type ViewId } from "../constants/routing-constants";
import GlobalConfig from "../global-config";
import type { GlobalState } from "../global-context";
import { type ServiceDiagEventNames } from "./service-diag-event-names";
import { type SystemActionName } from "./system-action-name";
import { type UserActionName } from "./user-action-name";

export type TelemetryData =
  | {
      [key: string]: string | number | boolean | object | string[] | number[] | boolean[];
    }
  | undefined;

export interface UserActionTelemetryProps {
  actionName: UserActionName | SystemActionName;
  actionValue?: string | {};
}

export interface ServiceDiagTelemetryProps {
  metricName: ServiceDiagEventNames;
  metricValue?: string | {};
}

export const stringOrStringify = (value?: string | {}) => {
  if (!value) {
    return "";
  }

  return typeof value === "string" ? value : JSON.stringify(value);
};

export enum TelemetryEventType {
  Exception = "IDUX_ClientTelemetry_Exceptions",
  Plt = "IDUX_ClientTelemetry_PltEvents",
  ServiceDiag = "IDUX_ClientTelemetry_ServiceDiag",
  UserAction = "IDUX_ClientTelemetry_UserActions",
  Api = "IDUX_ClientTelemetry_ApiEvents",
}

export interface IStandardDimensions {
  view: ViewId & string;
  flow: FlowId & string;
  flavor: Flavors & string;
  correlationId: string;
  hostPageId: string;
}

interface IUserActionsEvent extends ITelemetryEvent {
  _table: TelemetryEventType.UserAction;
  actionName: string;
  actionValue: string;
  dimensions: IStandardDimensions;
}

interface IPltEvent extends ITelemetryEvent {
  _table: TelemetryEventType.Plt;
  dimensions: IStandardDimensions;
}

interface IExceptionEvent extends ITelemetryEvent {
  _table: TelemetryEventType.Exception;
  message: string;
  errorName: string;
  stack: string;
  source: string;
  currentState: Record<string, string>;
  dimensions: IStandardDimensions;
}

// TODO: metricName should be strongly typed
interface IServiceDiagEvent extends ITelemetryEvent {
  _table: TelemetryEventType.ServiceDiag;
  metricName: string;
  metricValue: string;
  dimensions: IStandardDimensions;
}

interface IApiEvent extends ITelemetryEvent {
  _table: TelemetryEventType.Api;
  apiName: string;
  networkDuration: number;
  totalProcessingTime: number;
  responseCode: number;
}

export type TelemetryEvents =
  | IUserActionsEvent
  | IPltEvent
  | IExceptionEvent
  | IServiceDiagEvent
  | IApiEvent;

export type TelemetryDimensions = {
  activeView: ViewId;
  activeFlow: FlowId;
  activeFlavor: Flavors;
} & Partial<GlobalState>;

/**
 * Extracts standard dimensions from the global state. These standard dimensions are used as primary filters in
 * Geneva and should therefore be added to every event.
 * @param state The global state from which to extract the standard dimensions
 * @returns The dimensions which should be added to every event
 */
export function getStandardDimensions(
  state: {
    activeView: ViewId;
    activeFlow: FlowId;
    activeFlavor: Flavors;
  } & Partial<GlobalState>,
): IStandardDimensions {
  // Do not add any PII dimensions here until scrubbing is enabled
  return {
    view: state.activeView,
    flow: state.activeFlow,
    flavor: state.activeFlavor,
    correlationId: GlobalConfig.instance.correlationId,
    hostPageId: GlobalConfig.instance.hostPageId,
    // here is where we can annotate other standard dimensions like build version, server affinity, DC etc.
  };
}

/**
 * Logs a user telemetry using the global telemetry provider
 * @param userAction The user action to log
 * @param userAction.actionName The name of the user action
 * @param userAction.actionValue The value of the user action
 * @param dimensions  The dimensions to add to the event
 */
export const logUserAction = (
  { actionName, actionValue }: UserActionTelemetryProps,
  dimensions: TelemetryDimensions,
): void => {
  const telemetryProvider = GlobalConfig.instance.telemetry;
  telemetryProvider.addEvent({
    _table: TelemetryEventType.UserAction,
    dimensions: getStandardDimensions(dimensions),
    actionName,
    actionValue: stringOrStringify(actionValue),
  });
};

/**
 * Logs a service diagnostic telemetry using the global telemetry provider
 * @param diagEvent The service diagnostic event to log
 * @param diagEvent.metricName The name of the metric
 * @param diagEvent.metricValue The value of the metric
 * @param dimensions The dimensions to add to the event
 */
export const logServiceDiagEvent = (
  { metricName, metricValue }: ServiceDiagTelemetryProps,
  dimensions: TelemetryDimensions,
): void => {
  const telemetryProvider = GlobalConfig.instance.telemetry;

  telemetryProvider.addEvent({
    _table: TelemetryEventType.ServiceDiag,
    dimensions: getStandardDimensions(dimensions),
    metricName,
    metricValue: stringOrStringify(metricValue),
  });
};
