import { createContext } from "react";
import { type IViewContext, createViewProvider } from "@msidentity/SISU/flows/view-context";
import { type ServerData } from "@msidentity/SISU/utilities/server-data";
import signUpReducer, { type SignUpActions } from "./signup-reducer";

export type SignUpState = {
  memberName: string;
};

export const defaultSignUpState: SignUpState = {
  memberName: "",
};

export const SignUpContext = createContext<IViewContext<SignUpState, SignUpActions>>({
  viewState: defaultSignUpState,
  dispatchStateChange: () => {
    throw new Error("SignUp Context not initialized");
  },
});

export const SignUpProvider: React.FC<{ initialState: SignUpState }> = createViewProvider<
  SignUpState,
  SignUpActions
>(SignUpContext, signUpReducer);

/* ********* ServerData helpers  ********** */

/**
 * Create a SignUp state object from ServerData
 * @param serverData The server data object that should be used to create the initial SignUp state
 * @returns The SignUp state object created from the server data
 */
export function createSignUpState(serverData: ServerData): SignUpState {
  const SignUpState = { ...defaultSignUpState };

  if (Object.keys(serverData).length !== 0) {
    // placeholder so unused serverData param doesn't cause lint error - update to assign server data values when needed
    SignUpState.memberName = "";
  }

  return SignUpState;
}
