import React, { useContext, useEffect, useRef, useState } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../config/styles-config";
import { LayoutTemplateType } from "../constants/constants";
import GlobalConfig from "../global-config";
import { type IDebugInfo, GlobalContext } from "../global-context";
import { getTextAreaAndCopyToClipboard } from "../utilities/clipboard-helper";
import { checkMarkIcon, closeIcon } from "../utilities/image-helpers/accessible-images";
import { AccessibleImage } from "./accessible-image";
import { DebugTrace } from "./debug-trace";
import { ImageButton } from "./image-button";

type DebugDetailsBannerProps = {
  debugInfo: IDebugInfo;
  onDebugCloseClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
  focusOnCopyLink?: boolean;
};

/**
 * DebugDetailsBanner component
 * @param props The properties for this component
 * @param props.debugInfo The debug info to render in the details pane
 * @param props.onDebugCloseClick The callback to execute when the debug details pane close button is clicked
 * @param props.focusOnCopyLink Whether focus is set on the copy details link. Default is false.
 * @returns an instance of this component
 */
const DebugDetailsBanner: React.FC<DebugDetailsBannerProps> = function DebugDetailsBanner({
  debugInfo,
  onDebugCloseClick,
  focusOnCopyLink = false,
}) {
  const { sessionId, correlationId, showCopyDebugDetailsLink } = GlobalConfig.instance;
  const { useDebugDetailsBannerStyles } = StylesConfig.instance;

  const debugDetailsBannerStyles = useDebugDetailsBannerStyles();
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const { errorCode, errorMessage: exceptionMessage, timeStamp } = debugInfo;
  const {
    globalState: {
      isWideView,
      styles: { layoutTemplate },
    },
  } = useContext(GlobalContext);

  /** @private  */
  function clickCopyTextToClipboard(e: React.SyntheticEvent<HTMLElement>) {
    e.preventDefault();
    getTextAreaAndCopyToClipboard("debugDetailsText");
    setShowCopyMessage(true);
  }

  const bannerStyles = mergeClasses(
    debugDetailsBannerStyles.banner,
    layoutTemplate === LayoutTemplateType.VerticalSplit
      ? debugDetailsBannerStyles.bannerVerticalSplit
      : "",
    isWideView ? debugDetailsBannerStyles.wide : "",
  );

  // Default action is to set focus on the copy details link when pressing enter on the debug button (ellipsis).
  // If the copy link element is not available, check if close debug button is available and set focus on that instead.
  const copyLinkRef = useRef<HTMLAnchorElement>(null);
  const closeDebugRef = useRef<HTMLButtonElement>(null);
  const getFocusableItem = () => {
    const element = copyLinkRef?.current ?? closeDebugRef?.current;
    return element;
  };

  useEffect(() => {
    if (focusOnCopyLink) {
      const elementRef = getFocusableItem();
      elementRef?.focus();
    }
  }, [focusOnCopyLink]);

  return (
    <div data-testid="debugDetailsBanner" className={bannerStyles}>
      <div className={debugDetailsBannerStyles.tableRow}>
        <div className={debugDetailsBannerStyles.tableCell}>
          {showCopyDebugDetailsLink && (
            <div className={debugDetailsBannerStyles.header}>
              <div
                id="debugDetailsHeader"
                className={debugDetailsBannerStyles.headingText}
                role="heading"
                aria-level={2}
              >
                {getLocalString("DebugDetails_Title")}
              </div>
              <div>{getLocalString("DebugDetails_Desc")}</div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                onClick={clickCopyTextToClipboard}
                role="button"
                className={debugDetailsBannerStyles.copyLink}
                aria-describedby="debugDetailsHeader"
                aria-label={getLocalString("DebugDetails_CopyLink")}
                ref={copyLinkRef}
              >
                {getLocalString("DebugDetails_CopyLink")}
              </a>
              {showCopyMessage && (
                <span
                  id="debugDetailsCopyMessage"
                  className={debugDetailsBannerStyles.detailsNotification}
                  aria-live="assertive"
                >
                  <AccessibleImage accessibleImages={checkMarkIcon} role="presentation" />
                  <span
                    role="alert"
                    className={debugDetailsBannerStyles.detailsNotification}
                    aria-label={getLocalString("DebugDetails_Notification_ScreenReader")}
                  >
                    {getLocalString("DebugDetails_Notification_CopySuccessful")}
                  </span>
                </span>
              )}
            </div>
          )}
          <div id="debugDetailsText" className={debugDetailsBannerStyles.overrideLtr}>
            {errorCode && (
              <div>
                <span className={debugDetailsBannerStyles.bold}>Error Code: </span>
                <span>{errorCode}</span>
              </div>
            )}
            {sessionId && (
              <div>
                <span className={debugDetailsBannerStyles.bold}>Request Id: </span>
                <span>{sessionId}</span>
              </div>
            )}
            <div>
              <span className={debugDetailsBannerStyles.bold}>Correlation Id: </span>
              <span>{correlationId}</span>
            </div>
            <div>
              <span className={debugDetailsBannerStyles.bold}>Timestamp: </span>
              <span data-testid="timeStamp">{timeStamp}</span>
            </div>
            {exceptionMessage && (
              <div>
                <span className={debugDetailsBannerStyles.bold}>Message: </span>
                <span>{exceptionMessage}</span>
              </div>
            )}
          </div>
          <DebugTrace />
        </div>
        <div>
          <ImageButton
            accessibleImageProps={{
              accessibleImages: closeIcon,
              altText: getLocalString("DebugDetails_Close_AlternateText"),
            }}
            buttonId="errorBannerClose"
            className={debugDetailsBannerStyles.closeButton}
            ariaLabel={getLocalString("DebugDetails_Close_AlternateText")}
            onClick={onDebugCloseClick}
            ref={closeDebugRef}
          />
        </div>
      </div>
    </div>
  );
};

export default DebugDetailsBanner;
