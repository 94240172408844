import { makeStyles, shorthands } from "@griffel/react";
import * as styleConstants from "./constants-fabric.styles";

const useFooterStylesFabric = makeStyles({
  footer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    ...shorthands.overflow("visible"),
    zIndex: "auto",
    clear: "both",
    minHeight: `${styleConstants.FOOTER_HEIGHT}px`,

    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      backgroundColor: "#fff",
      filter: "none",
    },
  },

  darkFooter: {
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    filter:
      "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#99000000', endColorstr='#99000000')",

    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      filter:
        "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#99000000', endColorstr='#99000000')",
    },
  },

  whiteMobileFooter: {
    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      backgroundColor: "#fff",
      filter: "none",
    },
  },

  footerNode: {
    ...shorthands.margin(0, 0),
    fontSize: "13px",
    float: "right",
    ":global([dir='rtl'])": {
      float: "left",

      [styleConstants.MOBILE_LAYOUT_SIZE]: {
        float: "right",
      },
    },

    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      float: "left",
      ...shorthands.margin(0, `${styleConstants.LIGHTBOX_MOBILE_PADDING}px`),
    },
  },

  footerItem: {
    color: "#000",
    fontSize: "12px",
    lineHeight: `${styleConstants.FOOTER_HEIGHT}px`,
    whiteSpace: "nowrap",
    display: "inline-block",
    marginLeft: "8px",
    marginRight: "8px",
    "text-decoration": "none",
    "& has-background": {
      color: "#fff",
    },
    ":hover": {
      color: "#000",
    },

    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      color: "#747474",
    },
  },

  darkFooterFooterItem: {
    color: "#fff",
    ":hover": {
      color: "#fff",
    },

    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      color: "#747474",
      ":hover": {
        color: "#747474",
      },
    },
  },

  whiteTextMobileFooterItem: {
    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      color: "#fff",
    },
  },

  debugItem: {
    "letter-spacing": "3px",
    lineHeight: "22px",
    verticalAlign: "top",
    fontSize: "16px",
    fontWeight: "600",
    ":hover": {
      "text-decoration": "none",
    },
  },
});

export default useFooterStylesFabric;

export type FooterStylesFabric = ReturnType<typeof useFooterStylesFabric>;
