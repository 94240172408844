import React, { useContext, useState } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../config/styles-config";
import { ExternalClassName } from "../constants/constants";
import { type GlobalState, GlobalContext } from "../global-context";
import { isCssAnimationSupported } from "../utilities/browser-helper";
import { getUTCString } from "../utilities/datetime-helper";
import ProgressIndicatorFabric from "./loading-progress/fabric/progress-indicator-fabric";
import { TileFabric } from "./tile/fabric/tile-fabric";
import BackgroundLogo from "./background-logo";
import BannerLogo from "./banner-logo";
import BoilerPlateText from "./boilerplate-text";
import DebugDetailsBanner from "./debug-details-banner";
import IdentityBanner from "./identity-banner";

export type LayoutContentStyles = {
  headerMarginClassName: string;
  innerClassName: string;
  lightboxCoverClassName: string;
  paginationViewClassName: string;
};

type LayoutContentProps = {
  styles: LayoutContentStyles;
  showDebugInfo: boolean;
  setShowDebugInfo: (showDebugInfo: boolean) => void;
  focusOnDebugCopyLink: boolean;
  setFocusOnEllipsis: (focus: boolean) => void;
};

/**
 * This method provides the business logic to define the lightboxCover class name(s) and paginationView class name(s)
 * @param globalState - GlobalState
 * @param innerClassName - The class name(s) to give the "inner" element
 * @returns LayoutContent classnames
 */
export const useLayoutContentStyles = function useLayoutContentStyles(
  globalState: GlobalState,
  innerClassName: string,
): LayoutContentStyles {
  const { useCommonStyles } = StylesConfig.instance;
  const commonStyles = useCommonStyles();
  const {
    styles: { showHeader },
    allowGrayOutLightbox,
    showIdentityBanner,
    showProgressIndicator,
    user,
  } = globalState;

  let lightboxCoverClassName = commonStyles.lightboxCover;
  if (showProgressIndicator && allowGrayOutLightbox) {
    lightboxCoverClassName = mergeClasses(lightboxCoverClassName, commonStyles.disableLightbox);
  }

  const paginationViewClassName =
    !!user?.displayUsername?.unsafeUnescapedString && showIdentityBanner
      ? commonStyles.paginationViewWithIdentityBanner
      : commonStyles.paginationView;

  const headerMarginClassName = showHeader ? commonStyles.headerMargin : "";

  return {
    headerMarginClassName,
    innerClassName,
    lightboxCoverClassName,
    paginationViewClassName,
  };
};

/**
 * @param props - Component properties
 * @param props.styles - Component CSS classes to add to specific DOM elements
 * @param props.showDebugInfo - Indicator that the debug information should be shown
 * @param props.setShowDebugInfo - Callback to update the `showDebugInfo` state
 * @param props.focusOnDebugCopyLink - Indicator that focus should be added to the debug copy link
 * @param props.setFocusOnEllipsis - Callback to update `focusOnEllipsis` state
 * @returns LayoutContent component containing the shared components/HTML between the LightboxLayout and VerticalSplitLayout components
 */
const LayoutContent: React.FC<LayoutContentProps> = function LayoutContent(props) {
  const {
    focusOnDebugCopyLink,
    setFocusOnEllipsis,
    showDebugInfo,
    setShowDebugInfo,
    styles,
    children,
  } = props;

  const {
    globalState: {
      debugInfo,
      hideBannerLogo,
      showIdentityBanner,
      showProgressIndicator,
      showMoreOptions,
      styles: { backgroundColor, backgroundImageUrl, alwaysShowBackground },
      user,
    },
  } = useContext(GlobalContext);

  const animationSupported = isCssAnimationSupported();
  // When animation is supported, this is set to false. It is updated to true when the lightbox fade-in animation is done.
  const [isLayoutReady, setIsLayoutReady] = useState(!animationSupported);

  let onAnimateLightboxEnd;
  // Add animation styles for lightbox/inner container when animation is supported
  if (animationSupported) {
    onAnimateLightboxEnd = () => {
      setIsLayoutReady(true);
    };
  }

  const displayIdBanner =
    !!user?.displayUsername?.unsafeUnescapedString && showIdentityBanner && isLayoutReady;
  const innerClassNames = mergeClasses(styles.innerClassName, ExternalClassName.signInBox);

  debugInfo.timeStamp = debugInfo.timeStamp ? debugInfo.timeStamp : getUTCString();

  return (
    <div className={styles.headerMarginClassName}>
      <BackgroundLogo />
      <div
        id="inner"
        data-testid="inner"
        className={innerClassNames}
        onAnimationEnd={onAnimateLightboxEnd}
      >
        <div id="lightbox-cover" className={styles.lightboxCoverClassName} />
        <div>
          {showProgressIndicator && <ProgressIndicatorFabric />}
          {!hideBannerLogo && <BannerLogo />}
          {displayIdBanner && <IdentityBanner />}
          {isLayoutReady && (
            <div
              id="pageContent"
              data-testid="paginationView"
              className={styles.paginationViewClassName}
            >
              {children}
              <BoilerPlateText />
            </div>
          )}
        </div>
      </div>
      {showMoreOptions && (
        <TileFabric
          isMoreOptions
          // When a background color or image is rendered, apply custom styles to the sign-in options tile
          // to align it's width with the width of the lightbox / vertical split layout
          isAppBranded={!!(backgroundColor || backgroundImageUrl) && alwaysShowBackground}
          mainText={showMoreOptions.text}
          imageUrl={showMoreOptions.accessibleImage}
          ariaLabel={showMoreOptions.text}
          onClick={showMoreOptions.onClick}
        />
      )}
      {showDebugInfo && (
        <DebugDetailsBanner
          debugInfo={debugInfo}
          focusOnCopyLink={focusOnDebugCopyLink}
          onDebugCloseClick={() => {
            setFocusOnEllipsis(true);
            setShowDebugInfo(!showDebugInfo);
          }}
        />
      )}
    </div>
  );
};

export default LayoutContent;
