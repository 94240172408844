export enum ServiceDiagEventNames {
  None = "",
  AccountTransferDenied = "AccountTransfer_Denied",
  AccountTransferErrorMissingOtc = "AccountTransferError_MissingOtc",
  AccountTransferErrorUnknownError = "AccountTransferError_UnknownError",
  AccountTransferMobileDenied = "AccountTransfer_MobileDenied",
  AccountTransferSuccess = "AccountTransfer_Success",
  AccountTransferTimeout = "AccountTransfer_Timeout",
  AcwSSOBridgeLoadSuccess = "Acw_SSOBridge_Load_Success",
  AcwSSOBridgeLoadFailed = "Acw_SSOBridge_Load_Failed",
  AcwSSOTokenRequestStart = "Acw_SSOTokenRequest_Start",
  AcwSSOTokenRequestSuccess = "Acw_SSOTokenRequest_Success",
  AcwSSOTokenRequestFailed = "Acw_SSOTokenRequest_Failed",
  AcwSSOUrlOpenSuccess = "Acw_SSOUrlOpen_Success",
  AcwSSOUrlOpenFailed = "Acw_SSOUrlOpen_Failed",
  OtcCredentialUndefined = "OtcCredential_Undefined",
  QrLogoNotFound = "QrLogo_NotFound",
  Redirect = "Redirect",
}
