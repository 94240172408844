import { makeStyles, shorthands } from "@griffel/react";

export const useHeaderStylesFabric = makeStyles({
  headerContainer: {
    display: "table-cell",
    position: "absolute",
    width: "100%",
    zIndex: 10,
  },
  header: {
    width: "100%",
    height: "48px",
    ...shorthands.padding("12px", "24px"),
  },
  headerLogo: {
    maxHeight: "24px",
    maxWidth: "150px",
    verticalAlign: "middle",
    ...shorthands.border(0),
  },
});

export type HeaderStylesFabric = ReturnType<typeof useHeaderStylesFabric>;
