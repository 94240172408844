import React from "react";
import StylesConfig from "../config/styles-config";
import GlobalConfig from "../global-config";

type DirectionWrapperProps = {
  testID?: string;
};

/**
 * @param root Root properties
 * @param root.children Child components to render in the direction wrapper
 * @param root.testID Optional test ID to add to the div
 * @returns DirectionWrapper component to provide `rtl` functionality
 */
const DirectionWrapper: React.FC<DirectionWrapperProps> = function DirectionWrapper({
  children,
  testID,
}) {
  const { isRtlMarket } = GlobalConfig.instance;
  const { useCommonStyles } = StylesConfig.instance;
  const testIds = ["directionWrapper"];
  if (testID) {
    testIds.push(testID);
  }

  const { directionWrapper } = useCommonStyles();

  return (
    <div
      dir={isRtlMarket ? "rtl" : ""}
      data-testid={testIds.join(" ")}
      className={directionWrapper}
    >
      {children}
    </div>
  );
};

export default DirectionWrapper;
