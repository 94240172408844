import { makeStyles, shorthands } from "@griffel/react";
import { LIGHTBOX_BACKGROUND_COLOR, LIGHTBOX_MAX_WIDTH } from "./constants-fabric.styles";

export const useDialogStylesFabric = makeStyles({
  outer: {
    position: "fixed",
    zIndex: 100,
    backgroundColor: "rgba(0, 0, 0, 0.55)",
  },
  middle: {
    display: "table-cell",
    verticalAlign: "middle",
  },
  inner: {
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    ...shorthands.padding("28px"),
    maxWidth: `${LIGHTBOX_MAX_WIDTH + 122}px`,
    backgroundColor: LIGHTBOX_BACKGROUND_COLOR,
    ...shorthands.border("2px", "solid", "#4F74B2"),
    zIndex: 100,
  },
  fullScreen: {
    position: "fixed",
    zIndex: 100,
    minWidth: "100%",
    minHeight: "100%",
  },
  header: {
    display: "flex",
  },
  content: {
    position: "relative",
    "& a": {
      display: "inline-block",
      boxShadow: "none",
      ":focus": {
        boxShadow: "0 0 0 1.5px black",
      },
    },
  },
  font: {
    fontWeight: 400,
    lineHeight: "1.25rem",
  },
  buttonContainer: {
    textAlign: "right",
    ...shorthands.padding("10px", "0", "0", "0"),
    "& button": {
      ...shorthands.margin(0, "10px", 0, 0),
      ":last-child": {
        ...shorthands.margin(0),
      },
    },
  },
});

export type DialogStylesFabric = ReturnType<typeof useDialogStylesFabric>;
