import React, { useContext, useEffect, useState } from "react";
import StylesConfig from "../../../config/styles-config";
import { LayoutTemplateType } from "../../../constants/constants";
import FeaturesConfig from "../../../features-config";
import GlobalConfig from "../../../global-config";
import { GlobalContext } from "../../../global-context";
import { HistoryContext } from "../../../history-context";
import { useLoadTelemetry } from "../../../hooks/use-load-telemetry";
import { useOnPopStateEventListener } from "../../../hooks/use-on-pop-state";
import { useRenderPromises } from "../../../hooks/use-render-promises";
import { initialPostRedirectState, PostRedirectProvider } from "../../../post-redirect-context";
import { ExceptionHelper, GenericErrorCodes } from "../../../telemetry-helpers/exception-helper";
import { useTelemetryState } from "../../../telemetry-helpers/use-telemetry-state";
import { useUnhandledRejection } from "../../../telemetry-helpers/use-unhandled-rejection";
import { isHistorySupported } from "../../../utilities/browser-helper";
import ErrorComponent from "../../error-component";
import LightboxLayout from "../../layout/fabric/lightbox-layout-fabric";
import VerticalSplitLayout from "../../layout/fabric/vertical-split-layout-fabric";
import { PostRedirectForm } from "../../post-redirect-form";

/**
 * Shell component for Fabric flavor
 * @param props The properties for this component
 * @param props.children The child elements to render inside this layout
 * @returns The rendered component (either vertical split or lightbox style depending on the layout template)
 */
const ShellFabric: React.FC = function ShellFabric({ children }) {
  const { bannerEnvironment } = GlobalConfig.instance;
  const { loadGamepadNavigationModule } = FeaturesConfig.instance;
  const {
    globalState: {
      styles: { faviconUrl, layoutTemplate },
      renderPromises,
    },
  } = useContext(GlobalContext);

  const {
    historyState: { historyIndex: currentHistoryIndex },
  } = useContext(HistoryContext);

  const [isPageBlank, setPageBlank] = useState(false);
  const telemetryState = useTelemetryState();
  useUnhandledRejection(telemetryState); // Set up the unhandled rejection handler window event listener
  const LayoutComponent =
    layoutTemplate === LayoutTemplateType.VerticalSplit ? VerticalSplitLayout : LightboxLayout;

  const { useCommonStyles, useStaticCommonStyles } = StylesConfig.instance;
  useStaticCommonStyles();
  const commonStyles = useCommonStyles();

  useRenderPromises(renderPromises);

  useEffect(() => {
    /**
     * Lazy loading Gamepad Navigation Module
     */
    async function loadModule() {
      if (loadGamepadNavigationModule) {
        const gamepadModule = await import(
          /* webpackChunkName: "gamepad-navigation" */ "@gaming/react-gamepad-navigation-lite"
        );
        gamepadModule.initGamepadNavigation();
      }
    }

    loadModule();
  }, [loadGamepadNavigationModule]);

  const popStateEvent = useOnPopStateEventListener();

  useEffect(() => {
    if (isHistorySupported()) {
      // make sure history state is updated with most recent history index
      window.history.replaceState(currentHistoryIndex, "");
      window.addEventListener("popstate", popStateEvent);
    }

    return () => {
      if (isHistorySupported()) {
        window.removeEventListener("popstate", popStateEvent);
      }
    };
  }, [currentHistoryIndex, popStateEvent]);

  /** @private */
  function checkPageBlank() {
    const coreElement = document.getElementById("outer");

    if (
      coreElement?.offsetHeight === undefined ||
      coreElement?.offsetWidth === undefined ||
      coreElement?.getClientRects().length === undefined
    ) {
      ExceptionHelper.logPageIsBlankError(telemetryState);
      setPageBlank(true);
    }
  }

  useLoadTelemetry(() => {
    checkPageBlank();
  });

  useEffect(() => {
    const favicon = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (favicon && faviconUrl) {
      favicon.href = faviconUrl;
    }
  }, [faviconUrl]);

  return isPageBlank ? (
    <ErrorComponent errorCode={GenericErrorCodes.BlankPageError} />
  ) : (
    <LayoutComponent styles={commonStyles} bannerEnvironment={bannerEnvironment}>
      <PostRedirectProvider initialState={initialPostRedirectState}>
        <PostRedirectForm />

        {children}
      </PostRedirectProvider>
    </LayoutComponent>
  );
};

export default ShellFabric;
