import React, { useEffect, useRef } from "react";
import { FOCUS_TIMEOUT } from "../../../styles/fabric/layout-animate-fabric.styles";
import type { IButtonProps } from "../button-interface";
import { type IButtonFabricProps } from "./button-fabric-interface";

export type ButtonFabricProps = IButtonProps & IButtonFabricProps;

/**
 * Button component for fabric
 * @param props The properties for this component
 * @param forwardRef Optionally pass down a reference to a DOM element created by a parent component. This enables the parent to modify actions, such as setting focus on the element.
 * @returns an instance of the shared button component
 */
export const ButtonFabric = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ButtonFabricProps>
>((props, forwardRef) => {
  const { children, ...buttonParams } = props;
  const {
    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy,
    ariaExpanded,
    ariaHasPopup,
    buttonId,
    dataTestId,
    disabled = false,
    onClick,
    className,
    style = {},
    hasFocus = false,
    isButtonType = true,
    onMouseOver = () => {},
    onMouseOut = () => {},
    onFocus = () => {},
    onBlur = () => {},
  } = buttonParams;

  const elementReference = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // We delay focus until animation is nearly completed. This prevents the browser from immediately switching to the focused element and pulling
    // it into view, which causes the identity banner and logo to pull right and interrupts the animation.
    /*  Note: Providing a `forwardRef` and `hasFocus` would mean `hasFocus` isn't honored.
          Future changes to this file should address this potential for misuse. */
    if (!forwardRef && hasFocus) {
      setTimeout(() => {
        elementReference?.current?.focus();
      }, FOCUS_TIMEOUT);
    }
  }, [hasFocus, forwardRef]);

  return (
    <button
      type={isButtonType ? "button" : "submit"}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      aria-describedby={ariaDescribedBy}
      aria-expanded={ariaExpanded}
      aria-haspopup={ariaHasPopup}
      ref={forwardRef ?? elementReference}
      id={buttonId}
      data-testid={dataTestId}
      className={className}
      style={style}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {children}
    </button>
  );
});
