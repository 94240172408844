import React, { createContext, useContext, useMemo, useReducer } from "react";
import { type AccessibleImages } from "./components/accessible-image";
import { Flavors, LayoutTemplateType } from "./constants/constants";
import { FlowId, ViewId } from "./constants/routing-constants";
import type { IStandardDimensions } from "./telemetry-helpers/telemetry-helper";
import type { IBrandingStyles } from "./utilities/branding-helper";
import { defaultAccentColors, getBranding } from "./utilities/branding-helper";
import { getRenderPromises } from "./utilities/render-promises-helper";
import type { ServerData } from "./utilities/server-data";
import { filterLinks } from "./utilities/strings-helper";
import { type UntrustedExternalInputText } from "./utilities/untrusted-external-input-text";
import globalReducer, {
  type GlobalActions,
  type UserOptions,
  getUpdatedUser,
} from "./global-reducer";

export interface ICustomizableStyles extends IBrandingStyles {
  // Add non-branding styles here
}

export interface IDebugInfo {
  errorCode: string;
  errorMessage: string;
  debugModeActive: boolean;
  timeStamp: string;
}

export interface TelemetryState {
  dimensions: IStandardDimensions;
  currentState?: {};
}

export interface IMoreOptionsProps {
  accessibleImage: AccessibleImages;
  text: string;
  onClick: () => void;
}

export interface IUser {
  displayUsername: UntrustedExternalInputText;
  username: UntrustedExternalInputText;
}

export type NavigationDirection = "forward" | "back";

export type GlobalState = {
  activeFlow: FlowId;
  activeView: ViewId;
  activeFlavor: Flavors;
  styles: ICustomizableStyles;
  user: IUser;
  debugInfo: IDebugInfo;
  showProgressIndicator: boolean;
  allowGrayOutLightbox: boolean;
  hideBannerLogo: boolean;
  showIdentityBanner: boolean;
  showBackButtonOnActiveView: boolean;
  isWideView: boolean;
  navigationDirection: NavigationDirection;
  renderPromises: Promise<void>[];
  showMoreOptions?: IMoreOptionsProps;
};

export const defaultStyles: ICustomizableStyles = {
  accessRecoveryUrl: "",
  accentColors: defaultAccentColors,
  alwaysShowBackground: false,
  backgroundColor: "#f2f2f2",
  backgroundImageUrl: "",
  backgroundLogoUrl: "",
  bannerLogoUrl: "",
  bannerLogoText: "",
  boilerPlateText: "",
  cantAccessYourAccountText: "", // TODO: Get localized string when ready.
  customizationFiles: {},
  faviconUrl: "",
  forgotPasswordText: "", // TODO: Get localized string when ready.
  friendlyAppName: "",
  headerLogoUrl: "",
  layoutTemplate: LayoutTemplateType.Lightbox,
  privacyText: getLocalString("General_PrivacyAndCookies"), // @TODO: Base strings should be moved to a flavored, common strings file with branding strings applied overtop.
  privacyUrl: "",
  showAccountResetCredentials: true,
  showFooter: true,
  showHeader: false,
  showPrivacy: true,
  showTermsOfUse: true,
  termsOfUseText: getLocalString("General_TermsOfUse"), // @TODO: Base strings should be moved to a flavored, common strings file with branding strings applied overtop.
  termsOfUseUrl: "",
  useBackgroundImageMask: false,
  useDarkFooter: false,
  useTransparentLightbox: false,
};

export const defaultDebugInfo: IDebugInfo = {
  errorCode: "",
  errorMessage: "",
  debugModeActive: false,
  timeStamp: "",
};

export const defaultUser: IUser = {
  displayUsername: {} as UntrustedExternalInputText,
  username: {} as UntrustedExternalInputText,
};

export interface IGlobalContext {
  globalState: GlobalState;
  dispatchStateChange: React.Dispatch<GlobalActions>;
}

export const initialGlobalState: GlobalState = {
  activeFlow: FlowId.None,
  activeView: ViewId.None,
  activeFlavor: Flavors.None,
  styles: defaultStyles,
  user: defaultUser,
  debugInfo: defaultDebugInfo,
  showProgressIndicator: false,
  allowGrayOutLightbox: true,
  hideBannerLogo: false,
  showIdentityBanner: true,
  showBackButtonOnActiveView: false,
  isWideView: false,
  navigationDirection: "forward",
  renderPromises: [],
};

export const GlobalContext = createContext<IGlobalContext>({
  globalState: initialGlobalState,
  dispatchStateChange: () => {
    throw new Error("GlobalContext not initialized");
  },
});

export const useGlobalContext = () => useContext(GlobalContext);

/**
 *
 * @param props The input props for the global provider
 * @param props.initialState The initial state that the global provider should be initialized with. Note that this is NOT the default state
 * (fallback values provided at design time) but rather the state extracted from ServerData or otherwise representing the initial state of
 * the application at runtime
 * @param props.children The child components to render inside this provider
 * @returns The instantiated Provider component
 */
export const GlobalProvider: React.FC<{ initialState: GlobalState }> = function GlobalProvider({
  initialState,
  children,
}) {
  const [state, dispatch] = useReducer(globalReducer, initialState);
  const value: IGlobalContext = useMemo(
    () => ({
      globalState: state,
      dispatchStateChange: dispatch,
    }),
    [state],
  );

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

/* ********* ServerData helpers ********** */

/**
 * @private
 */
export const createStyles = function createStyles(serverData: ServerData) {
  const brandingStyles = getBranding(serverData);

  const styles: Partial<ICustomizableStyles> = {};

  styles.faviconUrl = brandingStyles.faviconUrl || serverData?.urlDefaultFavicon || "";
  styles.friendlyAppName = brandingStyles.friendlyAppName;
  styles.accentColors = brandingStyles.accentColors;
  styles.alwaysShowBackground = brandingStyles.alwaysShowBackground;
  styles.showAccountResetCredentials = brandingStyles.showAccountResetCredentials;
  styles.showHeader = brandingStyles.showHeader;
  styles.showFooter = brandingStyles.showFooter;
  styles.showTermsOfUse = brandingStyles.showTermsOfUse;
  styles.showPrivacy = brandingStyles.showPrivacy;
  styles.useBackgroundImageMask = brandingStyles.useBackgroundImageMask;
  styles.useDarkFooter = brandingStyles.useDarkFooter;
  styles.useTransparentLightbox = brandingStyles.useTransparentLightbox;
  styles.termsOfUseUrl =
    brandingStyles.termsOfUseUrl || serverData?.urlFooterTOU || serverData?.urlHostedTOULink;
  styles.privacyUrl =
    brandingStyles.privacyUrl || serverData?.urlFooterPrivacy || serverData?.urlHostedPrivacyLink;
  styles.accessRecoveryUrl = brandingStyles.accessRecoveryUrl || serverData?.urlResetPassword;

  if (brandingStyles.cantAccessYourAccountText) {
    styles.cantAccessYourAccountText = brandingStyles.cantAccessYourAccountText;
  }

  if (brandingStyles.forgotPasswordText) {
    styles.forgotPasswordText = brandingStyles.forgotPasswordText;
  }

  if (brandingStyles.termsOfUseText) {
    styles.termsOfUseText = brandingStyles.termsOfUseText;
  }

  // @TODO: Base strings should be moved to a flavored, common strings file with branding strings applied overtop.
  if (brandingStyles.privacyText) {
    styles.privacyText = brandingStyles.privacyText;
  } else if (serverData.fShowUpdatedKoreanPrivacyFooter) {
    styles.privacyText = getLocalString("General_PrivacyAndCookies_AltKorean");
  }

  if (brandingStyles.layoutTemplate) {
    styles.layoutTemplate = brandingStyles.layoutTemplate;
  }

  if (brandingStyles.bannerLogoUrl) {
    styles.bannerLogoUrl = brandingStyles.bannerLogoUrl;
  }

  if (brandingStyles.bannerLogoText) {
    styles.bannerLogoText = brandingStyles.bannerLogoText;
  }

  if (brandingStyles.backgroundLogoUrl) {
    styles.backgroundLogoUrl = brandingStyles.backgroundLogoUrl;
  }

  if (brandingStyles.backgroundImageUrl) {
    styles.backgroundImageUrl = brandingStyles.backgroundImageUrl;
  }

  if (brandingStyles.backgroundColor) {
    styles.backgroundColor = brandingStyles.backgroundColor;
  }

  if (brandingStyles.headerLogoUrl) {
    styles.headerLogoUrl = brandingStyles.headerLogoUrl;
  }

  if (brandingStyles.boilerPlateText) {
    styles.boilerPlateText = brandingStyles.boilerPlateText;

    if (serverData.fIsHosted) {
      styles.boilerPlateText = filterLinks(styles.boilerPlateText);
    }
  }

  if (brandingStyles.customizationFiles) {
    styles.customizationFiles = brandingStyles.customizationFiles;
  }

  return styles;
};

/**
 * Create a global state object from ServerData. This function should be called once per App, outside
 * of the component render cycle.
 * Any properties that are used inside a React component's lifecycle (hook or render function)
 * AND can change during the component's lifecycle should go into the state. Constant properties
 * or properties that are not used by a React component can be stored in config instead to improve
 * performance and prevent unnecessary re-rendering.
 * @param serverData The IDP-specific server data object that should be used to create the global state
 * @param activeFlavor Render flavor
 * @param userOptions UserOptions data to initialize the user object for this flow.
 * @returns The IDP-agnostic global state object created from the server data
 */
export function createGlobalState(
  serverData: ServerData,
  activeFlavor: Flavors,
  userOptions?: UserOptions,
): GlobalState {
  const globalState = {
    ...initialGlobalState,
    activeFlavor,
    styles: { ...defaultStyles, ...createStyles(serverData) },
    debugInfo: { ...defaultDebugInfo },
  };

  if (serverData?.sErrorCode) {
    globalState.debugInfo.errorCode = serverData.sErrorCode;
  }

  if (serverData?.strServiceExceptionMessage) {
    globalState.debugInfo.errorMessage = serverData.strServiceExceptionMessage;
  }

  if (serverData?.fIsDebugTracingEnabled) {
    globalState.debugInfo.debugModeActive = true;
  }

  if (serverData?.strTimestamp) {
    globalState.debugInfo.timeStamp = serverData.strTimestamp;
  }

  if (serverData?.fAllowGrayOutLightBox) {
    globalState.allowGrayOutLightbox = serverData.fAllowGrayOutLightBox;
  }

  if (userOptions) {
    globalState.user = getUpdatedUser(initialGlobalState.user, userOptions);
  }

  globalState.renderPromises = getRenderPromises(globalState.styles.customizationFiles);

  return globalState;
}
