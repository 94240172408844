import { makeStyles } from "@griffel/react";
import { MOBILE_LAYOUT_SIZE } from "./constants-fabric.styles";

export const useBackgroundStylesFabric = makeStyles({
  baseBackground: {
    top: 0,
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "center",
    backgroundPositionY: "center",

    [MOBILE_LAYOUT_SIZE]: { display: "none" },
  },

  normalBackground: {
    position: "fixed",
    left: 0,
  },

  verticalSplitBackground: {
    position: "absolute",
  },

  alwaysShow: {
    [MOBILE_LAYOUT_SIZE]: { display: "inherit" },
  },

  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.55)",
    filter:
      "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#8C000000', endColorstr='#8C000000')",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
});

export type BackgroundStylesFabric = ReturnType<typeof useBackgroundStylesFabric>;
