import React, { useContext } from "react";
import { Routes } from "react-router-dom";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../config/styles-config";
import { GlobalContext } from "../global-context";
import { useRouteAnimation } from "../hooks/use-route-animation";

/**
 * A shared component for applying view transition animation properties with the `useRouteAnimation` hook.
 * @param props The component properties
 * @param props.children The child elements to render inside this component. This element must be declared inside a route component.
 * @returns PLACEHOLDER - NEEDS TO BE UPDATED
 */
const RouteAnimation: React.FC = function RouteAnimation({ children }) {
  const {
    globalState: {
      styles: { boilerPlateText },
    },
  } = useContext(GlobalContext);
  const { useRouteAnimationStyles } = StylesConfig.instance;
  const [displayLocation, transitionView, onAnimationEnd] = useRouteAnimation();
  const styles = useRouteAnimationStyles();
  const classNames = mergeClasses(
    boilerPlateText ? styles.routeContent : "",
    styles.paginationViewMinHeight,
    transitionView,
  );

  return (
    <div data-testid="routeAnimation" className={classNames} onAnimationEnd={onAnimationEnd}>
      <Routes location={displayLocation}>{children}</Routes>
    </div>
  );
};

export default RouteAnimation;
