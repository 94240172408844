import { useCallback } from "react";
import { type ITelemetryProvider } from "@msidentity/telemetry";
import { type Flavors } from "../constants";
import { type FlowId, type ViewId } from "../constants/routing-constants";
import { type GlobalState } from "../global-context";
import {
  type ServiceDiagTelemetryProps,
  type UserActionTelemetryProps,
  getStandardDimensions,
  stringOrStringify,
  TelemetryEventType,
} from "./telemetry-helper";

export const useTelemetry = (
  telemetryProvider: ITelemetryProvider,
  dimensions: {
    activeView: ViewId;
    activeFlow: FlowId;
    activeFlavor: Flavors;
  } & Partial<GlobalState>,
) => ({
  logUserAction: useCallback(
    ({ actionName, actionValue }: UserActionTelemetryProps): void => {
      telemetryProvider.addEvent({
        _table: TelemetryEventType.UserAction,
        dimensions: getStandardDimensions(dimensions),
        actionName,
        actionValue: stringOrStringify(actionValue),
      });
    },
    [telemetryProvider, dimensions],
  ),
  logServiceDiagEvent: useCallback(
    ({ metricName, metricValue }: ServiceDiagTelemetryProps): void => {
      telemetryProvider.addEvent({
        _table: TelemetryEventType.ServiceDiag,
        dimensions: getStandardDimensions(dimensions),
        metricName,
        metricValue: stringOrStringify(metricValue),
      });
    },
    [telemetryProvider, dimensions],
  ),
});
