import { Flavors } from "../constants";
import {
  useAgreementViewStylesFabric,
  useArkoseEnforcementStylesFabric,
  useBackArrowButtonStylesFabric,
  useBackgroundLogoStylesFabric,
  useBackgroundStylesFabric,
  useBannerLogoStylesFabric,
  useBoilerPlateStylesFabric,
  useCheckboxStylesFabric,
  useCommonStylesFabric,
  useDebugDetailsBannerStylesFabric,
  useDebugTraceStylesFabric,
  useDialogStylesFabric,
  useEnvironmentBannerStylesFabric,
  useFooterStylesFabric,
  useHeaderStylesFabric,
  useHelpButtonStylesFabric,
  useHipChallengeStylesFabric,
  useHipChallengeTwoUiStylesFabric,
  useIdentityBannerStylesFabric,
  useLayoutAnimateStylesFabric,
  useLinkButtonStylesFabric,
  useOptionsMenuButtonStylesFabric,
  usePhoneCountryDropdownStylesFabric,
  usePhoneNumberStylesFabric,
  useProgressIndicatorStylesFabric,
  useProofImageStylesFabric,
  usePushNotificationsViewStylesFabric,
  useRadioButtonStylesFabric,
  useRemoteNgcViewStylesFabric,
  useRouteAnimationStylesFabric,
  useSecondaryContentStylesFabric,
  useStaticCommonStylesFabric,
  useStaticHipChallengeStylesFabric,
  useStaticHipChallengeTwoUiStylesFabric,
  useTextButtonContainerStylesFabric,
  useTextButtonStylesFabric,
  useTextInputStylesFabric,
  useTileStylesFabric,
  useTitleStylesFabric,
  useVerticalSplitStylesFabric,
} from "./fabric";
import type { StylesConfig } from "./types.styles";

export const StylesConfigFabric: StylesConfig = {
  useAgreementViewStyles: useAgreementViewStylesFabric,
  useArkoseEnforcementStyles: useArkoseEnforcementStylesFabric,
  useBackArrowButtonStyles: useBackArrowButtonStylesFabric,
  useBackgroundLogoStyles: useBackgroundLogoStylesFabric,
  useBackgroundStyles: useBackgroundStylesFabric,
  useBannerLogoStyles: useBannerLogoStylesFabric,
  useBoilerPlateStyles: useBoilerPlateStylesFabric,
  useCheckboxStyles: useCheckboxStylesFabric,
  useCommonStyles: useCommonStylesFabric,
  useDebugDetailsBannerStyles: useDebugDetailsBannerStylesFabric,
  useDebugTraceStyles: useDebugTraceStylesFabric,
  useDialogStyles: useDialogStylesFabric,
  useEnvironmentBannerStyles: useEnvironmentBannerStylesFabric,
  useFooterStyles: useFooterStylesFabric,
  useHeaderStyles: useHeaderStylesFabric,
  useHelpButtonStyles: useHelpButtonStylesFabric,
  useHipChallengeStyles: useHipChallengeStylesFabric,
  useHipChallengeTwoUiStyles: useHipChallengeTwoUiStylesFabric,
  useIdentityBannerStyles: useIdentityBannerStylesFabric,
  useLayoutAnimateStyles: useLayoutAnimateStylesFabric,
  useLinkButtonStyles: useLinkButtonStylesFabric,
  useOptionsMenuButtonStyles: useOptionsMenuButtonStylesFabric,
  usePhoneCountryDropdownStyles: usePhoneCountryDropdownStylesFabric,
  usePhoneNumberStyles: usePhoneNumberStylesFabric,
  useProgressIndicatorStyles: useProgressIndicatorStylesFabric,
  useProofImageStyles: useProofImageStylesFabric,
  usePushNotificationsViewStyles: usePushNotificationsViewStylesFabric,
  useRadioButtonStyles: useRadioButtonStylesFabric,
  useRemoteNgcViewStyles: useRemoteNgcViewStylesFabric,
  useRouteAnimationStyles: useRouteAnimationStylesFabric,
  useSecondaryContentStyles: useSecondaryContentStylesFabric,

  // Static styles
  useStaticCommonStyles: useStaticCommonStylesFabric,
  useStaticHipChallengeStyles: useStaticHipChallengeStylesFabric,
  useStaticHipChallengeTwoUiStyles: useStaticHipChallengeTwoUiStylesFabric,

  useTextButtonContainerStyles: useTextButtonContainerStylesFabric,
  useTextButtonStyles: useTextButtonStylesFabric,
  useTextInputStyles: useTextInputStylesFabric,
  useTileStyles: useTileStylesFabric,
  useTitleStyles: useTitleStylesFabric,
  useVerticalSplitStyles: useVerticalSplitStylesFabric,
};

// @TODO: Override styles hook where necessary
export const StylesConfigWin11OobeFabric: StylesConfig = { ...StylesConfigFabric };

export const FlavorToStylesConfigMap = new Map<Flavors, StylesConfig>([
  [Flavors.Fabric, StylesConfigFabric],
  [Flavors.WindowsFabric, StylesConfigWin11OobeFabric],
]);
