import { makeStyles } from "@griffel/react";

export const useRouteAnimationStylesFabric = makeStyles({
  // Necessary to position BoilerPlateText properly and still have route animation working
  routeContent: {
    display: "inline",
  },
  // inherit min-height from the parent paginationView div as this is not done automatically
  paginationViewMinHeight: {
    minHeight: "inherit",
  },
});

export type RouteAnimationStylesFabric = ReturnType<typeof useRouteAnimationStylesFabric>;
