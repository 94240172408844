import React from "react";
import ReactDOM from "react-dom";
import { MemoryRouter } from "react-router-dom";
import Shell from "@msidentity/SISU/components/shell/fabric/shell-fabric";
import { Flavors } from "@msidentity/SISU/constants";
import { GlobalProvider } from "@msidentity/SISU/global-context";
import { SignupRoutesFabric } from "../../flows/signup/fabric/signup-flow-fabric";
import { initSignUpFromServerData } from "./init-signup-from-server-data";

const serverData = window.ServerData;

// Initialize context and configs from ServerData
const { initialGlobalState, initialSignUpState } = initSignUpFromServerData(
  serverData,
  Flavors.Fabric,
);

/**
 * The app encapsulating the SignUp flow. This is the outermost component
 * and will be rendered onto the page using ReactDOM.render.
 * @returns An instance of the SignUp app
 */
export const SignupAppFabric: React.FC = function SignupAppFabric() {
  return (
    <GlobalProvider initialState={initialGlobalState}>
      <Shell>
        <SignupRoutesFabric initialSignUpState={initialSignUpState} />
      </Shell>
    </GlobalProvider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <MemoryRouter>
      <SignupAppFabric />
    </MemoryRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);
