import React, { useContext } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../config/styles-config";
import { ExternalClassName } from "../constants/constants";
import GlobalConfig from "../global-config";
import { GlobalContext } from "../global-context";
import { msLogoIcon } from "../utilities/image-helpers/accessible-images";
import { htmlUnescape } from "../utilities/strings-helper";
import { AccessibleImage } from "./accessible-image";

/**
 * BannerLogo component
 * @returns Returns a logo component used in the banner.
 * If no banner logo URL is supplied, the banner logo text is displayed.
 * If no banner logo URL or text is supplied (and we're not a CIAM tenant or China DC), the Microsoft logo is displayed.
 */
const BannerLogo: React.FC = function BannerLogo() {
  const { isChinaDc, isCiamUserFlow } = GlobalConfig.instance;
  const { useBannerLogoStyles } = StylesConfig.instance;

  const logoStyles = useBannerLogoStyles();
  const {
    globalState: {
      styles: { bannerLogoUrl, bannerLogoText },
    },
  } = useContext(GlobalContext);

  if (bannerLogoUrl) {
    const bannerLogoClassNames = mergeClasses(logoStyles.bannerLogo, ExternalClassName.bannerLogo);
    return (
      <img data-testid="bannerLogo" className={bannerLogoClassNames} src={bannerLogoUrl} alt="" />
    );
  }

  if (bannerLogoText) {
    return (
      <div data-testid="bannerLogoText" className={logoStyles.bannerLogoText}>
        {htmlUnescape(bannerLogoText)}
      </div>
    );
  }

  if (!isChinaDc && !isCiamUserFlow) {
    return (
      <AccessibleImage
        style={logoStyles.logo}
        accessibleImages={msLogoIcon}
        altText={getLocalString("General_Microsoft_Brand")}
      />
    );
  }

  return null;
};

export default BannerLogo;
