import React, { createContext, useMemo, useReducer } from "react";
import historyReducer, { type HistoryActions } from "./history-reducer";

/**
 * This history index is responsible for keeping track of the browser history state so that
 * we know when to go backward or forward when a pop state event is fired
 */
export type HistoryState = {
  historyIndex: number;
};

export interface IHistoryContext {
  historyState: HistoryState;
  dispatchStateChange: React.Dispatch<HistoryActions>;
}

export const initialHistoryState: HistoryState = {
  historyIndex: 0,
};

export const HistoryContext = createContext<IHistoryContext>({
  historyState: initialHistoryState,
  dispatchStateChange: () => {
    throw new Error("HistoryContext not initialized");
  },
});

/**
 * This will allow the state of browser history to be stored in all views.
 * @param props The input props for the history provider
 * @param props.initialState The initial state that the history provider should be initialized with
 * @param props.children The child components to render inside this provider
 * @returns The instantiated Provider component
 */
export const HistoryProvider: React.FC<{ initialState: HistoryState }> = function HistoryProvider({
  initialState,
  children,
}) {
  const [state, dispatch] = useReducer(historyReducer, initialState);
  const value: IHistoryContext = useMemo(
    () => ({
      historyState: state,
      dispatchStateChange: dispatch,
    }),
    [state],
  );

  return <HistoryContext.Provider value={value}>{children}</HistoryContext.Provider>;
};

/**
 * Create a history state object which initializes history index as 0. This function should be called once per App,
 * outside of the component render cycle.
 * Any properties related to authentication (such as flow token names and values) that are used inside
 * a React component's lifecycle (hook or render function) AND can change during the component's lifecycle
 * should go into the state. Constant authentication properties or properties that are not used by a React
 * component can be stored in global or flow-specific config instead to improve performance and prevent
 * unnecessary re-rendering.
 * @returns The history state object
 */
export function createHistoryState(): HistoryState {
  const historyState = {
    ...initialHistoryState,
  };

  return historyState;
}
