import { type AccessibleImages } from "../../components/accessible-image";
/** Please keep alphabetical. */

/* Accessible images can be pre-defined here for ease of reuse, but it's not
 * required. getAccessibleImage() will generate one if passed the name of an image
 * from one of the image-helpers if it is not found in definedAccessibleImages.
 * Note that if an accessible image is not defined here, thee dark/light images
 * must have the same base name in the appropriate image helper, the light image
 * suffixed with "_white".
 */

export const accessPassCredIcon: AccessibleImages = {
  dark: "cred_option_accesspass",
  light: "cred_option_accesspass_white",
};

export const arrowLeft: AccessibleImages = {
  dark: "arrow_left",
  light: "arrow_left_white",
};

export const authenticatorIcon: AccessibleImages = {
  dark: "picker_verify_code",
  light: "picker_verify_code_white",
};

export const authenticatorCredIcon: AccessibleImages = {
  dark: "cred_option_authenticator",
  light: "cred_option_authenticator_white",
};

export const authenticatorV2fluentIcon: AccessibleImages = {
  dark: "picker_verify_fluent_authenticator",
  light: "picker_verify_fluent_authenticator_white",
  extension: "svg",
};

export const authenticatorV2Icon: AccessibleImages = {
  dark: "picker_verify_authenticator",
  light: "picker_verify_authenticator_white",
};

export const arrowRight: AccessibleImages = {
  dark: "arrow_right",
  light: "arrow_right_white",
};

export const callIcon: AccessibleImages = {
  dark: "picker_verify_call",
  light: "picker_verify_call_white",
};

export const certificateCredIcon: AccessibleImages = {
  dark: "cred_option_certificate",
  light: "cred_option_certificate_white",
};

export const checkMarkIcon: AccessibleImages = {
  dark: "check_small",
  light: "check_small_white",
};

export const closeIcon: AccessibleImages = {
  dark: "close",
  light: "close_white",
};

export const documentationIcon: AccessibleImages = {
  dark: "documentation",
  light: "documentation_white",
};

export const emailIcon: AccessibleImages = {
  dark: "picker_verify_email",
  light: "picker_verify_email_white",
};

export const downArrowIcon: AccessibleImages = {
  dark: "dropdown_caret",
  light: "dropdown_caret_white",
};

export const fidoCredIcon: AccessibleImages = {
  dark: "cred_option_fido",
  light: "cred_option_fido_white",
};

export const passkeyCredIcon: AccessibleImages = {
  dark: "cred_option_passkey",
  light: "cred_option_passkey_white",
};

export const forgotCredIcon: AccessibleImages = {
  dark: "cred_option_forgot",
  light: "cred_option_forgot_white",
};

export const genericAppCredIcon: AccessibleImages = {
  dark: "cred_option_generic_app",
  light: "cred_option_generic_app_white",
};

export const githubCredIcon: AccessibleImages = {
  dark: "cred_option_github",
  light: "cred_option_github_white",
};

export const linkedInCredIcon: AccessibleImages = {
  dark: "cred_option_linkedin",
  light: "cred_option_linkedin_white",
};

export const marchingAntsGif: AccessibleImages = {
  dark: "marching_ants",
  light: "marching_ants_white",
  extension: "gif",
};

export const msLogoIcon: AccessibleImages = {
  dark: "microsoft_logo",
  light: "microsoft_logo_white",
};

export const passwordCredIcon: AccessibleImages = {
  dark: "cred_option_password",
  light: "cred_option_password_white",
};

export const personalAccountIcon: AccessibleImages = {
  dark: "personal_account",
  light: "personal_account_white",
};

export const pickerAccountAadIcon: AccessibleImages = {
  dark: "picker_account_aad",
  light: "picker_account_aad",
};

export const pickerAccountAddIcon: AccessibleImages = {
  dark: "picker_account_add",
  light: "picker_account_add",
};

export const pickerAccountMsaIcon: AccessibleImages = {
  dark: "picker_account_msa",
  light: "picker_account_msa",
};

export const pickerMoreIcon: AccessibleImages = {
  dark: "picker_more",
  light: "picker_more_white",
};

export const signInOptionsIcon: AccessibleImages = {
  dark: "signin_options",
  light: "signin_options_white",
};

export const smsIcon: AccessibleImages = {
  dark: "picker_verify_sms",
  light: "picker_verify_sms_white",
};

export const definedAccessibleImages = new Map<string, AccessibleImages>([
  ["accessPassCredIcon", accessPassCredIcon],
  ["arrowLeft", arrowLeft],
  ["arrowRight", arrowRight],
  ["authenticatorIcon", authenticatorIcon],
  ["authenticatorCredIcon", authenticatorCredIcon],
  ["authenticatorV2fluentIcon", authenticatorV2fluentIcon],
  ["authenticatorV2Icon", authenticatorV2Icon],
  ["callIcon", callIcon],
  ["certificateCredIcon", certificateCredIcon],
  ["checkMarkIcon", checkMarkIcon],
  ["closeIcon", documentationIcon],
  ["downArrowIcon", downArrowIcon],
  ["emailIcon", emailIcon],
  ["fidoCredIcon", fidoCredIcon],
  ["forgotCredIcon", forgotCredIcon],
  ["githubCredIcon", githubCredIcon],
  ["linkedInCredIcon", linkedInCredIcon],
  ["marchingAntsGif", marchingAntsGif],
  ["msLogoIcon", msLogoIcon],
  ["passkeyCredIcon", passkeyCredIcon],
  ["passwordCredIcon", passwordCredIcon],
  ["personalAccountIcon", personalAccountIcon],
  ["pickerAccountAad", pickerAccountAadIcon],
  ["pickerAccountAddIcon", pickerAccountAddIcon],
  ["pickerAccountMsaIcon", pickerAccountMsaIcon],
  ["pickerMoreIcon", pickerMoreIcon],
  ["smsIcon", smsIcon],
]);
