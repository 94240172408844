import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../config/styles-config";
import { ExternalClassName, LayoutTemplateType } from "../../../constants/constants";
import { ViewId } from "../../../constants/routing-constants";
import GlobalConfig from "../../../global-config";
import { GlobalContext } from "../../../global-context";
import { GlobalActionType } from "../../../global-reducer";
import { getRouteFromViewId } from "../../../utilities/routing-helper";
import { htmlUnescape } from "../../../utilities/strings-helper";
import { type LinkAgreementState, AgreementType } from "../../../views/agreement-view-interface";
import { type IFooterProps } from "../footer-interface";

/**
 * LightboxFooter component
 * @param props The properties for this component
 * @param props.isDebugInfoShown Whether the debug details info pane should be shown
 * @param props.onDebugItemClick Callback to execute when the debug item is clicked
 * @param props.focusOnEllipsis Whether focus is set on the ellipsis button. Default is false.
 * @returns an instance of this component
 */
const FooterFabric: React.FC<IFooterProps> = function FooterFabric({
  isDebugInfoShown = false,
  onDebugItemClick,
  focusOnEllipsis = false,
}) {
  const {
    footerImpressumUrl,
    footerA11yConformeUrl,
    isHosted,
    isChinaDc,
    logoutUrl,
    hideDebugDetails,
    showLogoutOnFooter,
  } = GlobalConfig.instance;
  const {
    dispatchStateChange: dispatchGlobal,
    globalState: {
      styles: {
        alwaysShowBackground,
        termsOfUseUrl,
        termsOfUseText,
        privacyUrl,
        privacyText,
        showFooter,
        showTermsOfUse,
        showPrivacy,
        useDarkFooter,
        layoutTemplate,
      },
    },
  } = useContext(GlobalContext);
  const { useFooterStyles } = StylesConfig.instance;
  const styles = useFooterStyles();
  const shouldUseDarkFooter = useDarkFooter && layoutTemplate !== LayoutTemplateType.VerticalSplit;
  const useWhiteFooterForMobile = !alwaysShowBackground && shouldUseDarkFooter;
  const useWhiteTextForMobile = alwaysShowBackground && shouldUseDarkFooter;
  const footerStyles = mergeClasses(
    styles.footer,
    shouldUseDarkFooter ? mergeClasses(styles.darkFooter, ExternalClassName.hasBackground) : "",
    useWhiteFooterForMobile ? styles.whiteMobileFooter : "",
    alwaysShowBackground ? ExternalClassName.backgroundAlwaysVisible : "",
    ExternalClassName.footer,
  );
  const footerItemStyles = mergeClasses(
    styles.footerItem,
    shouldUseDarkFooter
      ? mergeClasses(styles.darkFooterFooterItem, ExternalClassName.hasBackground)
      : "",
    useWhiteTextForMobile ? styles.whiteTextMobileFooterItem : "",
    alwaysShowBackground ? ExternalClassName.backgroundAlwaysVisible : "",
    ExternalClassName.footerItem,
    ExternalClassName.footerContent,
  );
  const footerLinksStyles = mergeClasses(styles.footerNode, ExternalClassName.footerLinks);
  const mergedDebugItemClasses = mergeClasses(
    footerItemStyles,
    styles.debugItem,
    ExternalClassName.debugItem,
  );

  const agreementRoute = getRouteFromViewId(ViewId.ViewAgreement);

  const location = useLocation();
  const [prevPath, setPrevPath] = useState("");
  useEffect(() => {
    // Keep track of the current non-agreement view, so that user can return to it when clicking "Back"
    // Navigate back using the stored prevPath instead of using "-1" to account for scenarios where user is navigating within the same view/route
    if (location.pathname !== agreementRoute) {
      setPrevPath(location.pathname);
    }
  }, [agreementRoute, location]);

  // Resets focus on the debug banner button when the debug details component is closed with pressing enter.
  // The onDebugCloseClick onClick handler in the lightbox-layout updates this value.
  const debugLinkRef = useRef<HTMLAnchorElement>(null);
  useEffect(() => {
    if (focusOnEllipsis && !isDebugInfoShown) {
      debugLinkRef?.current?.focus();
    }
  }, [focusOnEllipsis, isDebugInfoShown]);

  const createAgreementLinks = () => {
    // show links that navigate to the agreement view component
    if (isHosted && !isChinaDc) {
      return (
        <>
          {showTermsOfUse && !!termsOfUseUrl && (
            <Link
              to={agreementRoute}
              id="ftrTerms"
              className={footerItemStyles}
              state={{ prevPath, agreementType: AgreementType.TermsOfUse } as LinkAgreementState}
              onClick={() =>
                dispatchGlobal({
                  type: GlobalActionType.BeginNavigate,
                  source: prevPath,
                  destination: ViewId.ViewAgreement,
                  displayOptions: { navigationDirection: "forward" },
                })
              }
            >
              {htmlUnescape(termsOfUseText)}
            </Link>
          )}
          {showPrivacy && !!privacyUrl && (
            <Link
              to={agreementRoute}
              id="ftrPrivacy"
              className={footerItemStyles}
              state={{ prevPath, agreementType: AgreementType.Privacy } as LinkAgreementState}
              onClick={() =>
                dispatchGlobal({
                  type: GlobalActionType.BeginNavigate,
                  source: prevPath,
                  destination: ViewId.ViewAgreement,
                  displayOptions: { navigationDirection: "forward" },
                })
              }
            >
              {htmlUnescape(privacyText)}
            </Link>
          )}
          {!!footerImpressumUrl && (
            <Link
              to={agreementRoute}
              id="ftrImpressum"
              className={footerItemStyles}
              state={{ prevPath, agreementType: AgreementType.Impressum } as LinkAgreementState}
              onClick={() =>
                dispatchGlobal({
                  type: GlobalActionType.BeginNavigate,
                  source: prevPath,
                  destination: ViewId.ViewAgreement,
                  displayOptions: { navigationDirection: "forward" },
                })
              }
            >
              {getLocalString("Impressum_Link")}
            </Link>
          )}
          {!!footerA11yConformeUrl && (
            <Link
              to={agreementRoute}
              id="ftrA11yConforme"
              className={footerItemStyles}
              state={{ prevPath, agreementType: AgreementType.A11yConforme } as LinkAgreementState}
              onClick={() =>
                dispatchGlobal({
                  type: GlobalActionType.BeginNavigate,
                  source: prevPath,
                  destination: ViewId.ViewAgreement,
                  displayOptions: { navigationDirection: "forward" },
                })
              }
            >
              {getLocalString("A11y_Conformance_Footer_Url_Text")}
            </Link>
          )}
        </>
      );
    }

    // show external links to the agreements
    return (
      <>
        {showTermsOfUse && !!termsOfUseUrl && (
          <a
            id="ftrTerms"
            href={termsOfUseUrl}
            target="_blank"
            rel="noreferrer noopener"
            className={footerItemStyles}
          >
            {htmlUnescape(termsOfUseText)}
          </a>
        )}
        {showPrivacy && !!privacyUrl && (
          <a
            id="ftrPrivacy"
            href={privacyUrl}
            target="_blank"
            rel="noreferrer noopener"
            className={footerItemStyles}
          >
            {htmlUnescape(privacyText)}
          </a>
        )}
        {!!footerImpressumUrl && (
          <a
            id="ftrImpressum"
            href={footerImpressumUrl}
            target="_blank"
            rel="noreferrer noopener"
            className={footerItemStyles}
          >
            {getLocalString("Impressum_Link")}
          </a>
        )}
        {!!footerA11yConformeUrl && (
          <a
            id="ftrA11yConforme"
            href={footerA11yConformeUrl}
            target="_blank"
            rel="noreferrer noopener"
            className={footerItemStyles}
          >
            {getLocalString("A11y_Conformance_Footer_Url_Text")}
          </a>
        )}
      </>
    );
  };

  const logoutUrlContent = showLogoutOnFooter && logoutUrl && (
    <a id="logoutUrl" data-testid="logoutUrl" href={logoutUrl} className={footerItemStyles}>
      {getLocalString("SignOut_Text")}
    </a>
  );

  return (
    <div id="footer" data-testid="footer" role="contentinfo" className={footerStyles}>
      <div id="footerLinks" className={footerLinksStyles}>
        {showFooter && (
          <>
            {logoutUrlContent}
            {createAgreementLinks()}
          </>
        )}
        {
          // Disabling role rule since we want this to look like a link, however on click the action does not take the user
          // out of the context of what they were doing, an instead behaves as a button
          !hideDebugDetails && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              id="moreOptions"
              href="#"
              role="button"
              onClick={onDebugItemClick}
              className={mergedDebugItemClasses}
              aria-label={getLocalString("Troubleshooting_Ellipsis_AriaLabel")}
              aria-expanded={isDebugInfoShown}
              ref={debugLinkRef}
            >
              ...
            </a>
          )
        }
      </div>
    </div>
  );
};

export default FooterFabric;
