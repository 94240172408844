import type { SignUpState } from "./signup-context";

export enum SignUpActionType {
  SetMemberName,
}

export type SetMemberNameAction = {
  actionType: SignUpActionType.SetMemberName;
  payload: string;
};

export type SignUpActions = SetMemberNameAction;

/**
 * SignUp state reducer.
 * @param state The current SignUp state
 * @param action The action to perform on the current state
 * @returns The updated SignUp state
 */
export default function signUpReducer(state: SignUpState, action: SignUpActions): SignUpState {
  const { actionType } = action;
  switch (actionType) {
    case SignUpActionType.SetMemberName:
      return { ...state, memberName: action.payload };
    default:
      throw new Error(`SignUpReducer received unexpected action ${actionType}`);
  }
}
