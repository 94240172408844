import React from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../config/styles-config";
import { FormattedTextWithBindings } from "../../../utilities/formatted-text-with-bindings";
import type { IDescriptionProps } from "../description-interface";

interface IBaseFabricProps extends Omit<IDescriptionProps, "children"> {}

interface IFormattedEmbeddedBindingsFabricProps extends IBaseFabricProps {
  children: string;
  /** The object mapping to use with formatjs */
  embeddedBindings?: {};
}

interface IDefaultFabricProps extends IBaseFabricProps {
  embeddedBindings?: never;
  children: React.ReactNode;
}

export type IDescriptionFabricProps = IFormattedEmbeddedBindingsFabricProps | IDefaultFabricProps;

/**
 * DescriptionFabric component
 * @param props - Component properties
 * @returns Fabric description component
 */
export const DescriptionFabric: React.FC<IDescriptionFabricProps> = React.memo(
  (props: IDescriptionFabricProps) => {
    const { children, descriptionId, descriptionClass, embeddedBindings } = props;
    const { useCommonStyles } = StylesConfig.instance;
    const commonStyles = useCommonStyles();

    const description = embeddedBindings ? (
      <FormattedTextWithBindings embeddedBindings={embeddedBindings} textWithBindings={children} />
    ) : (
      children
    );

    return (
      <div className={mergeClasses(commonStyles.row, commonStyles.textBody)}>
        <div
          id={descriptionId}
          className={mergeClasses(
            commonStyles.textBlockBody,
            commonStyles.noMarginTop,
            descriptionClass,
          )}
        >
          {description}
        </div>
      </div>
    );
  },
);
