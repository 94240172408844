import React, { useRef, useState } from "react";
import StylesConfig from "../../config/styles-config";
import { type AccessibleImages } from "../accessible-image";
import { FocusLocker } from "../focus-locker";
import { ImageButton } from "../image-button";
import { LinkButton } from "../link-button";
import { useCloseOnOutsideClick } from "./hooks/use-close-on-outside-click";

export interface IOptionsMenuItemProps {
  itemId: string;
  itemText: string;
  isVisible?: boolean;
  hasFocus?: boolean;
  onClick: (event: React.SyntheticEvent<HTMLElement>) => void;
}

export interface IOptionsMenuButtonProps {
  icon: AccessibleImages;
  menuButtonIconClassName?: string;
  menuItemList: IOptionsMenuItemProps[];
  menuTestId?: string;
  ariaLabel?: string;
}

/**
 *
 * @param props The properties required for this component
 * @returns OptionsMenuButton component
 */
export const OptionsMenuButton: React.FC<IOptionsMenuButtonProps> = function OptionsMenuButton(
  props,
) {
  const { icon, menuButtonIconClassName, menuItemList, menuTestId, ariaLabel } = props;
  const { useOptionsMenuButtonStyles } = StylesConfig.instance;
  const menuButtonStyles = useOptionsMenuButtonStyles();
  const optionsMenu = useRef<HTMLInputElement>(null);
  const menuButton = useRef<HTMLButtonElement>(null);
  const [openMenu, setOpenMenu] = useState(false);

  // call hook that closes menu when user clicks outside of it
  useCloseOnOutsideClick(optionsMenu, setOpenMenu);

  /**
   * Handle when Escape is pressed on the menu items pop-up
   * @param ev the keyboard event that triggered this function
   */
  const handleEscape = (ev: React.KeyboardEvent<HTMLUListElement>) => {
    if (ev.key === "Escape") {
      setOpenMenu(false);
      menuButton.current?.focus();
    }
  };

  const menuItems = menuItemList.map((item) => (
    <div key={item.itemId}>
      {item.isVisible && (
        <li role="presentation" className={menuButtonStyles.menuMargin}>
          <LinkButton
            buttonRole="menuitem"
            linkId={item.itemId}
            className={menuButtonStyles.menuLink}
            text={item.itemText}
            onClick={item.onClick}
            hasFocus={item.hasFocus}
          />
        </li>
      )}
    </div>
  ));

  return (
    <div ref={optionsMenu}>
      <span className={menuButtonIconClassName} data-testid="iconImage">
        <ImageButton
          ref={menuButton}
          buttonId="menubutton"
          dataTestId={menuTestId || "options-menu-button"}
          ariaHasPopup="menu"
          ariaLabel={ariaLabel}
          ariaExpanded={openMenu}
          className={menuButtonStyles.menuButtonImageStyle}
          accessibleImageProps={{
            accessibleImages: icon,
            style: menuButtonStyles.accessibleImageAlign,
          }}
          onClick={() => setOpenMenu(!openMenu)}
        />
      </span>

      {openMenu && (
        <FocusLocker>
          <ul
            className={menuButtonStyles.menu}
            role="menu"
            aria-labelledby="menubutton"
            onKeyDown={handleEscape}
          >
            {menuItems}
          </ul>
        </FocusLocker>
      )}
    </div>
  );
};
