import React, { type ReactNode, useRef, useState } from "react";
import StylesConfig from "../config/styles-config";
import { TextButtonFabric } from "./text-button/fabric/text-button-fabric";
import { type AccessibleImages } from "./accessible-image";
import { ImageButton } from "./image-button";
import { ModalDialog } from "./modal-dialog";

export interface IHelpButtonProps {
  helpDialogIcon: AccessibleImages;
  helpDialogIconTestId?: string;
  /** optional header for popup */
  helpDialogHeader?: ReactNode;
  /** optional description for popup */
  helpDialogDescription?: ReactNode;
  helpDialogIconClassName: string;
  helpDialogAriaLabel?: string;
}

/**
 * Helper Button Component
 * @param props The properties required for the component
 * @returns instance of a HelperButton
 */
export const HelpButton: React.FC<IHelpButtonProps> = function HelpButton(props) {
  const { useHelpButtonStyles } = StylesConfig.instance;
  const {
    helpDialogIcon,
    helpDialogHeader,
    helpDialogDescription,
    helpDialogIconClassName,
    helpDialogIconTestId,
    helpDialogAriaLabel,
  } = props;
  const helpButtonStyles = useHelpButtonStyles();
  const [visibleDialog, setVisibleDialog] = useState(false);
  const helpButtonRef = useRef<HTMLButtonElement>(null);

  const dialogFooter = (
    isVisible: boolean,
    setVisibleState: React.Dispatch<React.SetStateAction<boolean>>,
  ) => (
    <TextButtonFabric
      hasFocus
      label={getLocalString("General_Close")}
      isPrimary
      onClick={() => {
        setVisibleState(!isVisible);
        helpButtonRef?.current?.focus(); // set keyboard focus back to help button
      }}
    />
  );

  return (
    <>
      <span className={helpDialogIconClassName} data-testid="iconImage">
        <ImageButton
          ref={helpButtonRef}
          className={helpButtonStyles.helpButtonImageStyle}
          accessibleImageProps={{
            accessibleImages: helpDialogIcon,
            dataTestId: helpDialogIconTestId || "help-button",
          }}
          ariaLabel={helpDialogAriaLabel}
          onClick={() => setVisibleDialog(!visibleDialog)}
        />
      </span>

      {visibleDialog && (
        <ModalDialog
          headerComponent={helpDialogHeader}
          descriptionComponent={helpDialogDescription}
          footerComponent={dialogFooter(visibleDialog, setVisibleDialog)}
        />
      )}
    </>
  );
};
