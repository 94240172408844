import { ConfigWrapper } from "@msidentity/SISU/utilities/config-wrapper";
import type { ServerData } from "@msidentity/SISU/utilities/server-data";

export const defaultSignUpConfig = {
  signUpDescription: "",
};

/**
 * The config wrapper that should be used to access SignUp config properties
 */
const configWrapper = new ConfigWrapper(defaultSignUpConfig);
export default configWrapper;

/* ********* ServerData helpers ********** */

/**
 * Initialize the SignUp config object from ServerData
 * @param serverData The MSA server data object that should be used to initialize the SignUp config
 */
export function initSignUpConfig(serverData: ServerData) {
  const mappedServerData: Partial<typeof defaultSignUpConfig> = {};

  if (serverData?.oAppCobranding) {
    const appBranding = serverData.oAppCobranding;

    if (appBranding?.signupDescription) {
      mappedServerData.signUpDescription = appBranding.signupDescription;
    }
  }

  configWrapper.initialize(mappedServerData);
}
