import { doubleJoin, findOwnProperty } from "./object-helper";

/**
 * Replaces indexed portions of a string with the corresponding value passed in the arguments.
 * ie: If the string was 'Text {0} text {1} text {0}', each {0} would be replaced with the second
 * argument passed in, and each {1} would be replaced with the third argument passed in, etc.
 * @param str The string to replace tokens in
 * @param args Strings to replace each occurence of {n} with
 * @returns A new string with tokens replaced
 */
export function replaceTokens(str: string, ...args: string[]) {
  let returnStr = str;
  if (str) {
    for (let i = 0; i < args.length; i += 1) {
      returnStr = returnStr.replace(new RegExp(`\\{${i}\\}`, "g"), args[i]);
    }
  }

  return returnStr;
}

/**
 * Returns URL with QS params removed
 * @param url The URL to trim
 * @returns trimmed URL
 */
export const trimQsParams = (url: string) =>
  url.includes("?") ? url.slice(0, url.indexOf("?")) : url;

/**
 * Remove HTML tags from string
 * @param html HTML content
 * @returns string
 */
export const removeHtmlTags = (html: string): string => {
  const div = document.createElement("div");
  div.innerHTML = html;

  return div.textContent || div.innerText || "";
};

/**
 * Escapes html characters in a string
 * @param html The "unsafe" string to add html escape characters to
 * @returns "safe" escaped input text
 */
export const htmlEscape = (html: string): string => {
  if (!html) {
    return "";
  }

  const textNode = document.createTextNode(html);
  const p = document.createElement("p");
  p.appendChild(textNode);

  return p.innerHTML;
};

/**
 * Unscapes html characters in a string -- returned string should be named "unsafe"
 * @param text The string to remove html escape characters from
 * @returns "unsafe" unescaped string
 */
export const htmlUnescape = (text: string): string => {
  if (!text) {
    return "";
  }

  // If we see something that looks like an html tag, don't attempt to unescape since we should only unescape already-escaped input
  // (note: the DOMParser approach results in anything that looks like an HTML tag to be removed, but the rest of the string is unescaped)
  if (text.match(/<[^<>]+>/)) {
    return text;
  }

  // NOTE: Use textarea instead of div to mitigate XSS
  // https://stackoverflow.com/questions/3700326/decode-amp-back-to-in-javascript
  const textArea = document.createElement("textarea");
  textArea.innerHTML = text;

  const html = textArea.childNodes.length === 0 ? "" : textArea.childNodes[0].nodeValue;

  return html!;
};

/**
 * Creates a dictionary by splitting a String with two delimeters. First, splits this string using delimeter one.
  After the split, each section will be split into two parts on the first occurence of delimiterTwo, resulting in a KEY string and a VALUE string.
  If the VALUE string is empty (the section doesn't contain delimiterTwo, or the only occurence is at the end of the section),
  the value in the dictionary for that KEY will be null. Empty Keys are stored as Empty Strings, but empty values are stored as null.
  By default, if the VALUE string is at least 1 character long, the entire VALUE string will be stored as the value for that Key.
  However, if multi-valued keys are enabled (true passed as param), the VALUE string will be split using delimiterTwo, and the resulting array
  will be stored as the value for that KEY.
 * @param str The string to split
 * @param delimiterOne The delimeter sepearting each node of the dictionary
 * @param delimiterTwo The delimeter sepearting the key/value(s) of each node
 * @param multiValuedKeys If TRUE, the value for each KEY will either be null, or an array of 1 or more values,
 * the result of splitting each section by delimiterTwo (except for the 1st value, which is used as the KEY)
 * @param keyTransformFunc If defined the key will be passed to the transformer before being used in the dictionary.
 * @returns A dictionary
 */
export function doubleSplit(
  str: string,
  delimiterOne: string,
  delimiterTwo: string,
  multiValuedKeys?: boolean,
  keyTransformFunc?: Function,
): { [key: string]: null | string | Array<string> } {
  const result: { [key: string]: null | string | Array<string> } = {};

  if (str) {
    str.split(delimiterOne).forEach((item) => {
      if (item) {
        const parts = item.split(delimiterTwo);
        let key = parts[0];
        if (keyTransformFunc) {
          key = keyTransformFunc(key);
        }

        if (parts.length === 1) {
          result[key] = null;
        } else if (multiValuedKeys) {
          result[key] = parts.slice(1);
        } else {
          result[key] = parts.slice(1).join(delimiterTwo);
        }
      }
    });
  }

  return result;
}

/**
 * Removes all leading and trailing white space from the String
 * @param str The string to trim
 * @returns The trimmed string
 */
export function trim(str: string) {
  return str.replace(/^\s+|\s+$/g, "");
}

/**
 * Checks if string is an email address
 * @param str The string to check
 * @returns TRUE if valid email address, FALSE otherwise
 */
export function isEmailAddress(str: string) {
  const newStr = trim(str);
  // Cannot Start with invalid char or contain spaces
  if (newStr.charAt(0) > "~" || newStr.indexOf(" ") !== -1) {
    return false;
  }

  // Must contain @ and text after the @ must have a .
  const atIndex = newStr.indexOf("@");
  if (atIndex === -1 || str.indexOf(".", atIndex) === -1) {
    return false;
  }

  // Can only contain 1 @ and text before + after @ must be longer than length 1
  const parts = newStr.split("@");
  if (parts.length > 2 || parts[0].length < 1 || parts[1].length < 2) {
    return false;
  }

  // Check if the character is an ascii char between 21 to 7E inclusive
  const asciiRegex = /^[\x21-\x7E]+$/;
  if (!newStr.match(asciiRegex)) {
    return false;
  }

  return true;
}

/**
 * Check if string is valid skype name
 * @param str The string to check
 * @returns TRUE if string is valid skype name false otherwise
 */
export function isSkypeName(str: string) {
  const newStr = trim(str);
  const skypeRegex = /^[a-zA-Z][a-zA-Z0-9.,\-_:']{0,128}$/;
  return !!newStr.match(skypeRegex);
}

/**
 * Check if string is valid phone number
 * @param str The string to check
 * @returns TRUE if string is valid phone number false otherwise
 */
export function isPhoneNumber(str: string) {
  const digits = str.replace(/\D+/g, "");

  return digits.length >= 4 && digits.length <= 50;
}

/**
 * Returns a new string equivalent to this String, but cleansed of leading, trailing and formatting characters (for phone numbers)
 * @param str The string to cleanse
 * @param preserveLeadingPlusSign bool that specifies to preserve leading plus sign or not
 * @returns A new cleansed string
 */
export function cleanseUsername(str: string, preserveLeadingPlusSign?: boolean) {
  if (!str) {
    return "";
  }

  const returnStr = trim(str).toLowerCase();

  if (!isEmailAddress(returnStr) && !isSkypeName(returnStr) && isPhoneNumber(returnStr)) {
    let prefix = "";
    if (preserveLeadingPlusSign && returnStr.charAt(0) === "+") {
      prefix = "+";
    }

    return prefix + returnStr.replace(/\D+/g, "");
  }

  return returnStr;
}

/**
 * @private
 */
export const getLinkInnerText = (innerText: string, parentheticalText: string | null) => {
  if (innerText !== parentheticalText) {
    return `${innerText} (${parentheticalText})`;
  }

  return innerText;
};

/**
 * This method is used to modify certain HTML anchor elements (links) to remove on-click behavior
 * @param text - HTML or text to filter
 * @param allowContactProtocols - Indicator that `mailto:` and `tel:` protocols for anchor elements should be filtered as well. Defaults to true.
 * @returns Modified text/HTML where anchor elements' `href` attributes are replaced with parentheticalText; Possibly doing the same for `mailto:` or `tel:` protocols.
 */
export const filterLinks = (text: string, allowContactProtocols: boolean = true): string => {
  const div = document.createElement("div");
  div.innerHTML = text;

  const links = div.getElementsByTagName("a");
  const numLinks = links.length;

  for (let idx = numLinks - 1; idx >= 0; idx -= 1) {
    const link = links[idx];
    let { innerText } = link;
    const { protocol } = link;
    let skipReplacement = false;

    if (protocol === "mailto:" || protocol === "tel:") {
      if (allowContactProtocols) {
        skipReplacement = true;
      } else {
        innerText = getLinkInnerText(innerText, link.pathname);
      }
    } else {
      innerText = getLinkInnerText(innerText, link.getAttribute("href"));
    }

    if (!skipReplacement) {
      const span = document.createElement("span");
      span.innerText = innerText;

      link.parentNode?.replaceChild(span, link);
    }
  }

  return div.innerHTML;
};

interface IParsedUrl {
  originAndPath: string;
  query: Record<string, unknown> | null;
  fragment: Record<string, unknown> | null;
}

/**
 * Parses a URL and extracts the query string and fragment converted into maps.
 * @param url The URL to parse
 * @returns The parsed URL
 */
export const parseQueryString = (url: string): IParsedUrl => {
  let originAndPath = url;
  let query = null;
  let fragment = null;

  if (url) {
    const queryStartIndex = url.indexOf("?");
    const fragmentStartIndex = url.indexOf("#");

    if (
      fragmentStartIndex !== -1 &&
      (queryStartIndex === -1 || fragmentStartIndex < queryStartIndex)
    ) {
      // The URL only has a fragment and no query
      originAndPath = url.substring(0, fragmentStartIndex);
      fragment = doubleSplit(url.substring(fragmentStartIndex + 1), "&", "=");
    } else if (queryStartIndex !== -1 && fragmentStartIndex === -1) {
      // The URL only has query and no fragment
      originAndPath = url.substring(0, queryStartIndex);
      query = doubleSplit(url.substring(queryStartIndex + 1), "&", "=");
    } else if (queryStartIndex !== -1 && fragmentStartIndex !== -1) {
      // The URL has both query and fragment
      originAndPath = url.substring(0, queryStartIndex);
      query = doubleSplit(url.substring(queryStartIndex + 1, fragmentStartIndex), "&", "=");
      fragment = doubleSplit(url.substring(fragmentStartIndex + 1), "&", "=");
    }
  }

  const parsedUrl = {
    originAndPath,
    query,
    fragment,
  };

  return parsedUrl;
};

/**
 * Joins a URL that has been previously parsed into extracted query string and fragment maps.
 * @param parsedUrl The parsed URL to join
 * @returns The joined URL
 */
export const joinUrl = (parsedUrl: IParsedUrl): string => {
  let url = parsedUrl.originAndPath || "";

  if (parsedUrl.query) {
    url += `?${doubleJoin(parsedUrl.query, "&", "=")}`;
  }

  if (parsedUrl.fragment) {
    url += `#${doubleJoin(parsedUrl.fragment, "&", "=")}`;
  }

  return url;
};

/**
 * Adds (or overwrites) the given key-value pairs to a URL's query string
 * TODO: Determine use-case for this vs `appendOrReplaceQueryStringParams`
 * @param url URL to modify
 * @param keyValuePairs The query string parameters to add
 * @returns Modified URL
 */
export const addQueryStrings = (url: string, keyValuePairs: Map<string, string>): string => {
  if (url && keyValuePairs.size) {
    const parsedUrl = parseQueryString(url);
    const query = parsedUrl.query || {};

    if (query !== null) {
      keyValuePairs.forEach((value, key) => {
        query[key] = value;
      });

      parsedUrl.query = query;
      return joinUrl(parsedUrl);
    }
  }

  return url;
};

/**
 * transfer missing query parameters to target urls
 * @param queryParamString query parameter string
 * @param targetUrlString target URL string
 * @returns target url with missing query parameters from source
 */
export const copyQueryStringParameters = (queryParamString: string, targetUrlString: string) => {
  const queryParams = new URLSearchParams(queryParamString);
  const targetUrl = new URL(targetUrlString);

  queryParams.forEach((value, name) => {
    if (!targetUrl.searchParams.has(name)) {
      targetUrl.searchParams.set(name, value);
    }
  });

  return targetUrl.href;
};

/**
 * Returns domain portion of the given e-mail address.
 * @param str - The email address for which to extract the domain.
 * @param removeDomainSuffix - If true, removes all characters after the last '.'. If false, returns the full domain.
 * @param includeDomainSeparator - If true, includes the '@' in the domain. If false, returns the domain without the '@'.
 * @returns The domain portion of this e-mail address formatted string. If not a valid e-mail address, return this string as is.
 */
export const extractDomain = (
  str: string,
  removeDomainSuffix: boolean,
  includeDomainSeparator: boolean,
): string => {
  if (!isEmailAddress(str)) {
    return str;
  }

  const domain = trim(str).split("@")[1];
  const separator = includeDomainSeparator ? "@" : "";

  if (removeDomainSuffix) {
    return separator + domain.slice(0, domain.lastIndexOf(".") + 1);
  }

  return separator + domain;
};

/**
 * Removes a URL's query string parameters
 * @param url The URL to modify.
 * @param params An array with the parameters to remove.
 * @returns The modified URL
 */
export const removeQueryStringParameters = (url: string, params: string[]) => {
  const parsedUrl = parseQueryString(url);
  params.forEach((param: string) => {
    parsedUrl.query = parsedUrl.query || {};
    const existingParam = findOwnProperty(parsedUrl.query, param, true);
    if (existingParam) {
      delete parsedUrl.query[existingParam];
    }
  });

  return joinUrl(parsedUrl);
};

/**
 * Gets a current query string parameter value
 * @param param The name of the query string parameter to extract
 * @param urlOrQueryString A full URL in which to search or just the query string part including "?".
 * Defaults to `document.location.search`.
 * @returns The Query String parameter value if found. Otherwise an empty string.
 */
export const extractQueryStringParam = (param: string, urlOrQueryString?: string): string => {
  let url = urlOrQueryString;
  if (!url && url !== "") {
    url = window.location.search;
  }

  const parsedUrl = parseQueryString(url);
  parsedUrl.query = parsedUrl.query || {};

  const existingParam = findOwnProperty(parsedUrl.query, param, true);
  return existingParam ? (parsedUrl.query[existingParam] as string) : "";
};

/**
 * Adds or modifies a URL's query string parameters. Note that if a record is passed in for queryString,
 * the keys will be lower-cased before being added to the url
 *
 * TODO: Determine use-case for this vs `addQueryStrings`
 *
 * @param url URL to modify
 * @param queryString Query string with params to append or replace
 * @param lowerCaseParamKeys Whether parameter keys should be lower-cased. Keys are not lower-cased by default
 * @param maxLength Maximum length of new URL allowed
 * @returns Modified URL
 */
export const appendOrReplaceQueryStringParams = (
  url: string,
  queryString: string | Record<string, unknown>,
  lowerCaseParamKeys?: boolean,
  maxLength?: number,
): string => {
  const parsedUrl = parseQueryString(url);
  const existingParams = parsedUrl.query || {};

  const newParams =
    typeof queryString === "string"
      ? // convert to a record if queryString is a string
        doubleSplit(queryString, "&", "=")
      : queryString;

  // add new params to existing params
  parsedUrl.query = Object.keys(newParams).reduce((acc: Record<string, unknown>, key: string) => {
    const finalKey = lowerCaseParamKeys ? key.toLowerCase() : key;
    acc[finalKey] = newParams[key] || null;
    return acc;
  }, existingParams);

  const modifiedUrl = joinUrl(parsedUrl);
  return maxLength && modifiedUrl.length > maxLength ? url : modifiedUrl;
};

/**
 * Returns the given URL with its query string and fragment stripped out.
 * @param url the url whose base URL is to be returned
 * @returns the base url (with the query string and fragment removed) of the input url
 */
export const stripQueryStringAndFragment = (url: string) => parseQueryString(url).originAndPath;
