import { makeStyles } from "@griffel/react";

export const FOCUS_TIMEOUT = 150;
const DURATION = "0.25s";
const DISTANCE = "200";
const TIMING_FUNCTION = "cubic-bezier(0.5, 0, 0.5, 1)";
const FADE_ANIMATION_DURATION = ".3s";
const FADE_IN_TRANSITION = "ease-in";
const FADE_OUT_TRANSITION = "ease-out";

const useLayoutAnimateStylesFabric = makeStyles({
  animate: {
    position: "relative",
    animationDuration: DURATION,
    animationTimingFunction: TIMING_FUNCTION,
    animationFillMode: "both",
    transitionProperty: "left",
  },

  slideOutNext: {
    animationName: {
      // hide-to-left
      from: { left: "0px", opacity: 1 },
      to: { left: `-${DISTANCE}px`, opacity: 0 },
    },
  },

  slideInNext: {
    animationName: {
      // show-from-right
      from: { left: `${DISTANCE}px`, opacity: 0 },
      to: { left: "0px", opacity: 1 },
    },
  },

  slideOutBack: {
    animationName: {
      // hide-to-right
      from: { left: "0px", opacity: 1 },
      to: { left: `${DISTANCE}px`, opacity: 0 },
    },
  },

  slideInBack: {
    animationName: {
      // show-from-left
      from: { left: `-${DISTANCE}px`, opacity: 0 },
      to: { left: "0px", opacity: 1 },
    },
  },

  // Used to fade-in the lightbox and identity banner
  fadeIn: {
    position: "relative",
    transitionTimingFunction: FADE_IN_TRANSITION,
    animationTimingFunction: TIMING_FUNCTION,
    animationDuration: FADE_ANIMATION_DURATION,
    animationName: {
      "0%": {
        opacity: 0,
      },
    },
    WebkitTransitionTimingFunction: FADE_IN_TRANSITION,
    WebkitAnimationTimingFunction: TIMING_FUNCTION,
    WebkitAnimationDuration: FADE_ANIMATION_DURATION,

    MozTransitionTimingFunction: FADE_IN_TRANSITION,
    MozAnimationTimingFunction: TIMING_FUNCTION,
    MozAnimationDuration: FADE_ANIMATION_DURATION,
  },

  fadeOut: {
    position: "relative",
    transitionTimingFunction: FADE_OUT_TRANSITION,
    animationDuration: FADE_ANIMATION_DURATION,
    animationName: {
      "100%": {
        opacity: 0,
      },
    },
    WebkitTransitionTimingFunction: FADE_OUT_TRANSITION,
    WebkitAnimationTimingFunction: TIMING_FUNCTION,
    WebkitAnimationDuration: FADE_ANIMATION_DURATION,

    MozTransitionTimingFunction: FADE_OUT_TRANSITION,
    MozAnimationTimingFunction: TIMING_FUNCTION,
    MozAnimationDuration: FADE_ANIMATION_DURATION,
  },
});

export default useLayoutAnimateStylesFabric;

export type LayoutAnimateStylesFabric = ReturnType<typeof useLayoutAnimateStylesFabric>;
