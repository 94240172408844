import type { FlowId, ViewId } from "./constants/routing-constants";
import { getStandardDimensions, TelemetryEventType } from "./telemetry-helpers/telemetry-helper";
import {
  type UntrustedExternalInputText,
  isTextUntrustedExternalInputText,
  setUntrustedExternalInputText,
} from "./utilities/untrusted-external-input-text";
import { type Flavors } from "./constants";
import GlobalConfig from "./global-config";
import type { GlobalState, IMoreOptionsProps, IUser, NavigationDirection } from "./global-context";

/**
 * Action types that are accepted by the global reducer
 */
export enum GlobalActionType {
  ActivateView,
  BeginNavigate,
  DataLoaded,
  DeactivateView,
  HideBackArrowButton,
  SetShowProgressIndicator,
  SetUser,
  ToggleDebugMode,
}

export interface ViewOptions {
  showIdentityBanner: boolean;
  showBackButtonOnActiveView: boolean;
  isWideView: boolean;
  hideBannerLogo: boolean;
  loadingData: boolean;
  showMoreOptions?: IMoreOptionsProps;
}

export const defaultViewOptions: ViewOptions = {
  showIdentityBanner: true,
  showBackButtonOnActiveView: false,
  isWideView: false,
  hideBannerLogo: false,
  loadingData: false,
};

export interface UserOptions {
  username?: string | UntrustedExternalInputText;
  displayUsername?: string | UntrustedExternalInputText;
}

type ActivateViewAction = {
  type: GlobalActionType.ActivateView;
  view: ViewId;
  flow: FlowId;
  flavor: Flavors;
  viewOptions: ViewOptions;
};

type BeginNavigateAction = {
  type: GlobalActionType.BeginNavigate;
  source: ViewId | string;
  destination?: ViewId | string;
  displayOptions?: {
    navigationDirection?: NavigationDirection;
  };
};

type DataLoadedViewAction = {
  type: GlobalActionType.DataLoaded;
  view: ViewId;
};

type DeactivateViewAction = { type: GlobalActionType.DeactivateView; view: ViewId };

type SetUserAction = {
  type: GlobalActionType.SetUser;
  payload: UserOptions;
};

type ToggleDebugMode = { type: GlobalActionType.ToggleDebugMode };

/**
 * Action used to set the `showBackButtonOnActiveView` property in GlobalContext which is used to decide whether to show the back arrow button
 * in the upper left corner of the lightbox.
 */
type HideBackArrowButtonAction = {
  type: GlobalActionType.HideBackArrowButton;
  payload: boolean;
};

/**
 * Action used to set the `showProgressIndicator` property in GlobalContext which is used to
 * decide whether to show the marching ants progress indicator on the upper edge of the lightbox
 */
type SetShowProgressIndicatorAction = {
  type: GlobalActionType.SetShowProgressIndicator;
  payload: boolean;
};

/**
 * Union of all actions that can be dispatched to the global reducer to update global state
 */
export type GlobalActions =
  | ActivateViewAction
  | BeginNavigateAction
  | DataLoadedViewAction
  | DeactivateViewAction
  | HideBackArrowButtonAction
  | ToggleDebugMode
  | SetUserAction
  | SetShowProgressIndicatorAction;

/**
 * Creates a user object from the username or displayUsername
 * @param currentUser Existing user object from state
 * @param options UserOptions passed in for setting the username and/or displayUsername
 * @returns An IUser object
 */
export function getUpdatedUser(currentUser: IUser, options: UserOptions): IUser {
  const updatedUser = currentUser;

  if (options.username) {
    updatedUser.username = isTextUntrustedExternalInputText(options.username)
      ? (options.username as UntrustedExternalInputText)
      : setUntrustedExternalInputText(options.username);
  }

  if (options.displayUsername) {
    updatedUser.displayUsername = isTextUntrustedExternalInputText(options.displayUsername)
      ? (options.displayUsername as UntrustedExternalInputText)
      : setUntrustedExternalInputText(options.displayUsername);
  }

  return updatedUser;
}

/**
 * Sets the username or displayUsername inside the user object
 * @param state The current state
 * @param action SetUsernameAction, SetDisplayUsernameAction, or SetUsernameAndDisplayNameAction
 *      payload is the UserOptions with the username/displayName to be set
 * @returns The updated state
 */
function setUser(state: GlobalState, action: SetUserAction): GlobalState {
  const user = getUpdatedUser(state.user, action.payload);
  return {
    ...state,
    user,
  };
}

/**
 * Handle an ActivateView action dispatched to the global reducer
 * @param state The current state
 * @param action The action object representing the parameters for this action
 * @returns The updated state
 */
function handleActivateView(state: GlobalState, action: ActivateViewAction): GlobalState {
  const {
    showIdentityBanner,
    showBackButtonOnActiveView,
    isWideView,
    hideBannerLogo,
    loadingData,
    showMoreOptions,
  } = action.viewOptions;
  return {
    ...state,
    showProgressIndicator: loadingData,
    activeView: action.view,
    activeFlow: action.flow,
    showIdentityBanner,
    showBackButtonOnActiveView,
    isWideView,
    hideBannerLogo,
    showMoreOptions,
  };
}

/**
 * Handle a BeginNavigate action dispatched to the global reducer.
 * @param state The current global state
 * @param action The action object representing the parameters for this action
 * @returns The updated global state
 */
export const handleBeginNavigate = function handleBeginNavigate(
  state: GlobalState,
  action: BeginNavigateAction,
): GlobalState {
  GlobalConfig.instance?.telemetry?.addEvent({
    _table: TelemetryEventType.UserAction,
    actionName: "BeginNavigation",
    actionValue: action.destination || "",
    dimensions: getStandardDimensions(state),
  });

  const navigationDirection =
    action.displayOptions?.navigationDirection ?? state.navigationDirection;

  return {
    ...state,
    showProgressIndicator: true,
    navigationDirection,
  };
};

/**
 * Global state reducer
 * @param state The current state
 * @param action The action to perform on the current state
 * @returns The new state
 */
export default function globalReducer(state: GlobalState, action: GlobalActions): GlobalState {
  const actionType = action.type;
  switch (actionType) {
    case GlobalActionType.BeginNavigate:
      return handleBeginNavigate(state, action);
    case GlobalActionType.ActivateView: // this is called from use-activate-view hook
      return handleActivateView(state, action);
    case GlobalActionType.DeactivateView:
      // perform any required cleanup that should happen before the new view activates
      return state;
    case GlobalActionType.DataLoaded:
      return { ...state, showProgressIndicator: false };
    case GlobalActionType.HideBackArrowButton:
      return { ...state, showBackButtonOnActiveView: !action.payload };
    case GlobalActionType.ToggleDebugMode:
      return {
        ...state,
        debugInfo: { ...state.debugInfo, debugModeActive: !state.debugInfo.debugModeActive },
      };
    case GlobalActionType.SetUser:
      return setUser(state, action);
    case GlobalActionType.SetShowProgressIndicator:
      return {
        ...state,
        showProgressIndicator: action.payload,
      };

    default:
      throw new Error(`GlobalReducer received unexpected action ${actionType}`);
  }
}
