import React from "react";
import { Route, Routes } from "react-router-dom";
import RouteAnimation from "@msidentity/SISU/components/route-animation";
import { FlowId, ViewId } from "@msidentity/SISU/constants/routing-constants";
import { getRouteFromViewId } from "@msidentity/SISU/utilities/routing-helper";
import AgreementViewFabric from "@msidentity/SISU/views/fabric/agreement-view-fabric";
import { type SignUpState, SignUpProvider } from "../signup-context";
import { UsernameViewFabric } from "../views/username/fabric/username-view-fabric";

/**
 * The Signup flow component
 * @param props The component properties
 * @param props.initialSignUpState The initial signup state
 * @returns An instance of the Signup flow component
 */
export const SignUpFlowFabric: React.FC<{ initialSignUpState: SignUpState }> =
  function SignUpFlowFabric({ initialSignUpState }) {
    return (
      <SignUpProvider initialState={initialSignUpState}>
        <Routes>
          <Route path="*" element={<UsernameViewFabric />} />
        </Routes>
      </SignUpProvider>
    );
  };

/**
 * A wrapper component for the routes defined for Signup app
 * @param props The component properties
 * @param props.initialSignUpState The initial signup state
 * @returns An instance of the sign up routes fabric component
 */
export const SignupRoutesFabric: React.FC<{ initialSignUpState: SignUpState }> =
  function SignupRoutesFabric({ initialSignUpState }) {
    return (
      <RouteAnimation>
        <Route path="*" element={<SignUpFlowFabric initialSignUpState={initialSignUpState} />} />
        <Route
          path={getRouteFromViewId(ViewId.ViewAgreement)}
          element={<AgreementViewFabric hostingFlow={FlowId.Signup} />}
        />
      </RouteAnimation>
    );
  };
