import React from "react";
import StylesConfig from "../../../config/styles-config";
import type { ITitleProps } from "../title-interface";

export type ITitleFabricProps = ITitleProps & {
  /** The subtitle beneath the title */
  subtitle?: string;
  /** Whether to display the subtitle */
  showSubtitle?: boolean;
};

/**
 * Title component for Fabric
 * @param props ITitleFabricProps properties for this component
 * @returns An instance of this component
 */
export const TitleFabric: React.FC<ITitleFabricProps> = function TitleFabric(props) {
  const { title, titleId, role = "heading", ariaLevel = 1, subtitle, showSubtitle } = props;
  const { useTitleStyles } = StylesConfig.instance;
  const titleStyles = useTitleStyles();
  const showSubtitleFlag = showSubtitle !== false && subtitle;

  return (
    <div className={titleStyles.title}>
      <div role={role} aria-level={ariaLevel} id={titleId}>
        {title}
      </div>

      {showSubtitleFlag && <div className={titleStyles.subtitle}>{subtitle}</div>}
    </div>
  );
};
