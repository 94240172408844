import { useContext } from "react";
import { FlowId } from "../constants";
import * as LoginHooks from "../flows/login/hooks/login-hooks";
import { GlobalContext } from "../global-context";
import { useNavigateBack } from "./use-navigate-back";
import { useBackButtonControl } from "./use-navigate-direction";

// This map contains flow-specific hooks that implement the logic for redirecting back on the initial view
// using the cancel and/or back urls. Specifying this hook is a must for flows that may conditionally show
// the back arrow or secondary back button on their initial views. If this map does not contain a hook for
// a flow that renders the back button on the initial view, we default to a no-op hook - meaning when the
// user clicks on such a back button, nothing happens.
export const FlowIdToUseRedirectBackHookMap = new Map<FlowId, () => () => void>([
  [FlowId.Login, () => LoginHooks.useOnCancelOrBack()],
]);

/**
 * React hook used to
 * 1. redirect to the cancel / back url if the current view is the initial view OR
 * 2. navigate back to the previous view if the current view is NOT the initial view
 *
 * @returns A function that navigates or redirects back.
 */
export const useNavigateOrRedirectBack = () => {
  const {
    globalState: { activeFlow },
  } = useContext(GlobalContext);

  const isInitialView = !useBackButtonControl();

  // Default to a no-op hook if the current flow ID is not specified in the map
  const useRedirectBack = FlowIdToUseRedirectBackHookMap.get(activeFlow) ?? (() => () => {});

  const redirectBack = useRedirectBack();
  const navigateBack = useNavigateBack();

  // If it is the initial view, there is no previous view to navigate back to,
  // so we redirect back to the cancel or back url
  //
  // If it is NOT the initial view, just navigate back to the previous view
  return isInitialView ? redirectBack : navigateBack;
};
