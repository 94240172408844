import { makeStaticStyles, makeStyles, shorthands } from "@griffel/react";
import * as styleConstants from "./constants-fabric.styles";
import * as dropdownConstants from "./dropdown-fabric.styles";
import * as textButtonConstants from "./text-button-constants-fabric.styles";

const minHeightOne = {
  minHeight: "1px",
};

export const useStaticCommonStylesFabric = makeStaticStyles({
  "*": {
    boxSizing: "border-box",
    "-webkit-box-sizing": "border-box",
    "-moz-box-sizing": "border-box",
  },
  body: {
    fontWeight: 400,
    fontFamily:
      "Segoe UI, -apple-system, Helvetica Neue, Lucida Grande, Roboto, Ebrima, Nirmala UI, Gadugi, Segoe Xbox Symbol, Segoe UI Symbol, Meiryo UI, Khmer UI, Tunga, Lao UI, Raavi, Iskoola Pota, Latha, Leelawadee, Microsoft YaHei UI, Microsoft JhengHei UI, Malgun Gothic, Estrangelo Edessa, Microsoft Himalaya, Microsoft New Tai Lue, Microsoft PhagsPa, Microsoft Tai Le, Microsoft Yi Baiti, Mongolian Baiti, MV Boli, Myanmar Text, Cambria Math",
    fontSize: ".9375rem",
    lineHeight: "1.25rem",
    margin: 0,
    backgroundColor: "rgba(255, 255, 255, 1.0)",
  },
  pre: {
    overflow: "auto",
    fontFamily: "inherit",
  },
  "button, input, select, textarea": {
    fontWeight: "inherit",
    fontFamily: "inherit",
    fontSize: "inherit",
    lineHeight: "inherit",
    maxWidth: "100%",
    margin: "0px",
  },
  "input[type='checkbox']": {
    width: "20px",
    height: "20px",
    boxSizing: "border-box",
    ...shorthands.padding(0),
  },
  "input[type='checkbox']:focus": {
    outlineWidth: "5px",
    outlineStyle: "auto",
    outlineColor: "-webkit-focus-ring-color",
    outlineOffset: 0,
  },
  a: {
    color: "#0067b8",
    "text-decoration": "none",
  },
  "a:hover": {
    "text-decoration": "underline",
    color: "#666",
  },
  ul: {
    paddingLeft: 0,
    listStyle: "none",
  },
  "ol, ul": {
    marginTop: "20px",
    marginBottom: "20px",
  },
  li: {
    marginTop: "12px",
    marginBottom: "12px",
  },
  select: {
    ...dropdownConstants.dropdownBaseStyle,
  },
  "select:focus": {
    ...dropdownConstants.dropdownBaseStyle,
    ...dropdownConstants.hasFocus,
  },
  option: {
    maxWidth: "100%",
    lineHeight: "inherit",
    fontWeight: "normal",
    display: "block",
    whiteSpace: "nowrap",
    minHeight: "1.2em",
    paddingTop: "0px",
    paddingRight: "2px",
    paddingBottom: "1px",
    paddingLeft: "2px",
    backgroundColor: "#fff",
  },
  label: {
    display: "inline-block",
    maxWidth: "100%",
  },
  "#root": minHeightOne,
});

const useCommonStylesFabric = makeStyles({
  directionWrapper: minHeightOne,

  outer: {
    display: "table",
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },

  middle: {
    display: "table-cell",
    verticalAlign: "middle",

    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      verticalAlign: "top",
    },
  },

  inner: {
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    maxWidth: `${styleConstants.LIGHTBOX_MAX_WIDTH}px`,
    width: "calc(100% - 40px)",
    ...shorthands.padding(`${styleConstants.LIGHTBOX_PADDING}px`),
    marginBottom: `${styleConstants.FOOTER_HEIGHT}px`,
    backgroundColor: styleConstants.LIGHTBOX_BACKGROUND_COLOR,
    WebkitBoxShadow: styleConstants.LIGHTBOX_SHADOW,
    "-moz-box-shadow": styleConstants.LIGHTBOX_SHADOW,
    boxShadow: styleConstants.LIGHTBOX_SHADOW,
    minWidth: `${styleConstants.LIGHTBOX_MIN_WIDTH}px`,
    minHeight: `${styleConstants.LIGHTBOX_MIN_HEIGHT}px`,
    ...shorthands.overflow("hidden"),

    "& has-popup": {
      marginBottom: "20px",
    },

    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      ...shorthands.padding(`${styleConstants.LIGHTBOX_MOBILE_PADDING}px`),
      marginTop: `${styleConstants.LIGHTBOX_MOBILE_MARGIN_TOP}px`,
      marginBottom: `${styleConstants.LIGHTBOX_MOBILE_MARGIN_BOTTOM}px`,
      width: "100vw",
      minWidth: "auto",
      "-webkit-box-shadow": "none",
      "-moz-box-shadow": "none",
      "box-shadow": "none",
      ...shorthands.border(0),
    },
    zIndex: 1,
  },

  innerWithPopup: {
    marginBottom: "20px",
    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      paddingBottom: 0,
      marginBottom: 0,
    },
  },

  innerWithAppBranding: {
    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      paddingBottom: `${styleConstants.LIGHTBOX_MOBILE_PADDING}px`,
      marginBottom: "20px",
    },
  },

  innerAlwaysShowBackground: {
    [styleConstants.MOBILE_LAYOUT_SIZE]: {
      ...shorthands.border("1px", "solid", "rgba(0, 0, 0, 0.4)"),
      boxShadow: styleConstants.LIGHTBOX_SHADOW,
      minWidth: "304px",
      width: "calc(100vw - 16px)",
    },
  },

  headerMargin: {
    marginTop: "48px",
  },

  bold: {
    fontWeight: "bold",
  },

  lightboxCover: {
    backgroundColor: "white",
    opacity: 0,
    filter: "alpha(opacity=0)",
    zIndex: -1,
    height: "100%",
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    transitionProperty: "all .5s ease-in",
    "-o-transition": "all .5s ease-in",
    "-moz-transition": "all .5s ease-in",
    "-webkit-transition": "all .5s ease-in",
  },

  disableLightbox: {
    zIndex: 10,
    opacity: 0.5,
    filter: "alpha(opacity=0)",
  },

  paginationView: {
    position: "relative",
    minHeight: `${
      styleConstants.LIGHTBOX_MIN_HEIGHT -
      styleConstants.LIGHTBOX_PADDING * 2 -
      styleConstants.LOGO_HEIGHT -
      styleConstants.SECTION_PADDING
    }px`,
  },

  paginationViewWithIdentityBanner: {
    position: "relative",
    minHeight: `${
      styleConstants.LIGHTBOX_MIN_HEIGHT -
      styleConstants.LIGHTBOX_PADDING * 2 -
      styleConstants.LOGO_HEIGHT -
      styleConstants.SECTION_PADDING -
      styleConstants.IDENTITY_BANNER_HEIGHT -
      styleConstants.IDENTITY_BANNER_MARGIN_BOTTOM -
      styleConstants.FORM_GROUP_MARGIN
    }px`,
  },

  wide: {
    maxWidth: `${styleConstants.LIGHTBOX_WIDE_MAX_WIDTH}px`,
  },

  formGroup: {
    marginBottom: `${styleConstants.FORM_GROUP_MARGIN}px`,
  },

  formGroupHeader: {
    marginTop: "36px",
    marginBottom: "36px",
  },

  formControl: {
    display: "block",
    width: "100%",
    backgroundImage: "none",
  },

  row: {
    marginLeft: 0,
    marginRight: 0,
    ...minHeightOne,
  },

  textBody: {
    marginTop: `${styleConstants.FORM_GROUP_MARGIN}px`,
    marginBottom: `${styleConstants.TILE_PADDING}px`,
    ...shorthands.padding(0),
  },

  noMarginBottom: {
    marginBottom: 0,
  },

  noMarginTop: {
    marginTop: 0,
  },

  overFlowHidden: {
    ...shorthands.overflow("hidden"),
  },

  textBlockBody: {
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
  },

  section: {
    marginTop: 0,
    marginBottom: 0,
  },

  textCenter: {
    textAlign: "center",
  },

  visibilityHidden: {
    visibility: "hidden",
  },

  moveOffScreen: {
    position: "fixed",
    bottom: "0px",
    right: "0px",
    height: "0px !important",
    width: "0px !important",
    opacity: 0,
    filter: "alpha(opacity=0)",
    ...shorthands.overflow("hidden"),
  },

  positionButtons: {
    "& > div:nth-child(1)": {
      display: "inline-block",
      width: "100%",
    },
  },

  // This should really be just `positionButtons` but since `positionButtons` is already
  // being used in a great number of views, a new class is being introduced to avoid
  // changing the look of / spacing in these existing views
  positionButtonsWithBottomMargin: {
    "& > div:nth-child(1)": {
      display: "inline-block",
      width: "100%",
      marginBottom: `${
        textButtonConstants.MINHEIGHT +
        styleConstants.SECTION_PADDING -
        styleConstants.FORM_GROUP_MARGIN
      }px`,
    },
  },

  inputContainerWithoutBoilerPlate: {
    "& > div:nth-child(1)": {
      marginBottom: `${
        textButtonConstants.MINHEIGHT +
        styleConstants.SECTION_PADDING -
        styleConstants.FORM_GROUP_MARGIN
      }px`,
    },
  },

  inputContainerWithBoilerPlate: {
    position: "relative",
  },

  transparent: {
    backgroundColor: "rgba(255, 255, 255, 0.65)",
  },

  alertMarginBottom: {
    marginBottom: styleConstants.ALERT_PADDING,
  },

  alertError: {
    color: styleConstants.ERROR_COLOR,
    ...shorthands.margin(`${styleConstants.ERROR_MARGIN}`),
  },

  anchorSize: {
    fontSize: "0.85rem",
  },

  orderedList: {
    paddingLeft: "15px",
  },

  displaySign: {
    textAlign: "center",
    fontSize: "2.5rem",
    lineHeight: "2.5rem",
    marginTop: `${styleConstants.FORM_GROUP_MARGIN}px`,
    marginBottom: `${styleConstants.FORM_GROUP_MARGIN}px`,
  },

  error: {
    color: styleConstants.ERROR_COLOR,
  },

  buttonMargin: {
    display: "inline-block",
    marginBottom: "36px",
    width: "100%",
  },

  centerBlock: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },

  linkButtonFontSize: {
    fontSize: ".8125rem",
  },

  wrapContent: {
    wordWrap: "break-word",
  },
});

export default useCommonStylesFabric;

export type CommonStylesFabric = ReturnType<typeof useCommonStylesFabric>;
