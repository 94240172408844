import React, { useContext } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../config/styles-config";
import { ExternalClassName, LayoutTemplateType } from "../constants/constants";
import { GlobalContext } from "../global-context";
import { useRouteAnimation } from "../hooks/use-route-animation";
import { isCssAnimationSupported } from "../utilities/browser-helper";

/**
 * Renders the BoilerPlateText if it is defined
 * @returns BoilerPlateText component
 */
const BoilerPlateText: React.FC = function BoilerPlateText() {
  const {
    globalState: {
      styles: { useTransparentLightbox, layoutTemplate, boilerPlateText },
    },
  } = useContext(GlobalContext);
  const { useBoilerPlateStyles } = StylesConfig.instance;

  const styles = useBoilerPlateStyles();
  const useAnimation = isCssAnimationSupported();
  const [, transitionView, onAnimationEnd] = useRouteAnimation();
  const useVerticalSplit = layoutTemplate === LayoutTemplateType.VerticalSplit;
  const classNames = mergeClasses(
    ExternalClassName.boilerPlateText,
    styles.boilerPlateText,
    useTransparentLightbox ? styles.transparent : "",
    useTransparentLightbox && useVerticalSplit ? styles.transparentVerticalSplit : "",
    useVerticalSplit ? styles.verticalSplit : "",
    useAnimation ? transitionView : "",
  );

  if (!boilerPlateText) return null;

  const html = { __html: boilerPlateText };

  return (
    <div
      data-testid="boilerPlateText"
      className={classNames}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={html}
      onAnimationEnd={onAnimationEnd}
    />
  );
};

export default BoilerPlateText;
