import React from "react";
import { HighContrastTheme } from "../constants/constants";
import GlobalConfig from "../global-config";
import { getHighContrastTheme } from "../utilities/browser-helper";
import { definedAccessibleImages } from "../utilities/image-helpers/accessible-images";
import { type ImagesExtension, getImageSource } from "../utilities/images-helper";

/**
 * If file extension is not supplied, SVG will be used if supported,
 *  else PNG will be used.
 */
export type AccessibleImages = { dark: string; light: string; extension?: ImagesExtension };

/**
 * getAccessibleImage generates an accessible image for use with the AccessibleImage
 * component. This will retrieve a pre-defined accessible image from accessible-images
 * or generate one based on the file name passed in.
 * @param image (Required) Image index number or string name of the primary/dark image
 * @param ext (Optional) Image extension - defaults is svg if not set
 * @returns Object of type AccessibleImages
 */
export function getAccessibleImage(image: number | string, ext?: ImagesExtension) {
  const darkImageName = typeof image === "number" ? image.toString() : image;

  if (definedAccessibleImages.has(darkImageName)) {
    return definedAccessibleImages.get(darkImageName)!;
  }

  const lightImageName = `${darkImageName}_white`;

  return {
    dark: darkImageName,
    light: lightImageName,
    extension: ext,
  } as AccessibleImages;
}

export interface IAccessibleImageProps {
  /** The dark and light versions of an image that will change with High Contrast Theme */
  accessibleImages: AccessibleImages;
  /** CSS class name used to style the image */
  style?: string;
  /**
   * If the AccessibleImageOptions are not checked in and instead are fetched via
   * external URL, isUrl should be set to true.
   */
  isUrl?: boolean;
  /** Alt text for the image */
  altText?: string;
  /**
   * Role will be set to "img" by default if not supplied to avoid
   * long-standing issues with screen readers that require this role for SVGs
   */
  role?: string;
  /** Optional data-test id for testing purposes Set to accessibleImg by default */
  dataTestId?: string;
}

/**
 * Returns light image for high contrast dark or if hasDefaultDarkBackground is true and
 * high contrast light theme is not enabled.
 * @param name The image name(s) for different background types
 * @returns A path to the appropriate accessible image or empty string
 */
export function useAccessibleImage(name?: AccessibleImages): string {
  if (!name) {
    return "";
  }

  const hcTheme = getHighContrastTheme();
  const { hasDefaultDarkBackground } = GlobalConfig.instance;

  return hcTheme === HighContrastTheme.dark ||
    (hasDefaultDarkBackground && hcTheme !== HighContrastTheme.light)
    ? name.light
    : name.dark;
}

/**
 * AccessibleImage component
 * @param props IAccessibleImageProps
 * @returns img with the appropriate source (dark or light) based on high contrast settings.
 */
export const AccessibleImage: React.FC<IAccessibleImageProps> = function AccessibleImage(props) {
  const {
    accessibleImages,
    dataTestId = "accessibleImg",
    style,
    isUrl = false,
    altText = "",
    role = "",
  } = props;
  const image = useAccessibleImage(accessibleImages);

  if (!image) {
    return null;
  }

  let imgSrc = image;

  if (!isUrl) {
    try {
      imgSrc = getImageSource(image, accessibleImages.extension);
    } catch {
      return null;
    }
  }

  return <img data-testid={dataTestId} className={style} src={imgSrc} alt={altText} role={role} />;
};
