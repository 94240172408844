import React, { useContext } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../config/styles-config";
import { ExternalClassName } from "../constants/constants";
import { GlobalContext } from "../global-context";

/**
 * Renders a header component unless globalState.styles.showHeader is false
 * @returns Header component
 */
const Header: React.FC = function Header() {
  const { useHeaderStyles } = StylesConfig.instance;
  const headerStyles = useHeaderStyles();
  const {
    globalState: {
      styles: { headerLogoUrl, showHeader },
    },
  } = useContext(GlobalContext);

  if (!showHeader) {
    return null;
  }

  const headerClassNames = mergeClasses(headerStyles.header, ExternalClassName.header);
  const headerLogoClassNames = mergeClasses(headerStyles.headerLogo, ExternalClassName.headerLogo);

  return (
    <div className={headerStyles.headerContainer} data-testid="header" role="banner">
      <div id="header" className={headerClassNames}>
        <img
          src={headerLogoUrl}
          alt={getLocalString("Header_Logo_AltText")}
          className={headerLogoClassNames}
        />
      </div>
    </div>
  );
};

export default Header;
