import React, { useContext, useEffect } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../config/styles-config";
import { ExternalClassName } from "../../../constants/constants";
import { GlobalContext } from "../../../global-context";
import * as styleConstants from "../../../styles/fabric/text-button-constants-fabric.styles";
import { checkAppBrandingProperties } from "../../../utilities/branding-helper";
import { ButtonFabric } from "../../button/fabric/button-fabric";
import { type IButtonFabricProps } from "../../button/fabric/button-fabric-interface";
import type { ITextButtonProps } from "../text-button-interface";

export type TextButtonFabricProps = ITextButtonProps &
  IButtonFabricProps & {
    /** Custom class to apply to the text button */
    customCss?: string;
  };

/**
 * Text Button component for fabric
 * @param props - TextButtonFabric component properties
 * @param forwardRef Optionally pass down a reference to a DOM element created by a parent component. This enables the parent to modify actions, such as setting focus on the element.
 * @returns an instance of text button component for fabric
 */
export const TextButtonFabric = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<TextButtonFabricProps>
>((props, forwardRef) => {
  const { children, customCss, ...buttonParams } = props;
  const { hasFocus, label, isPrimary } = buttonParams;

  // Apply app-branding accent colors if this is a primary text button and accent colors are specified.
  const {
    globalState: {
      styles: { accentColors },
    },
  } = useContext(GlobalContext);

  // Figure out which accent colors are specified and which we can use default values for
  const defaultColor =
    accentColors.primaryButtonDefaultColor || styleConstants.PRIMARY_BACKGROUND_COLOR;
  const hoverColor =
    accentColors.primaryButtonHoverColor || styleConstants.PRIMARY_HOVER_FOCUS_BACKGROUND_COLOR;
  const focusColor =
    accentColors.primaryButtonFocusColor || styleConstants.PRIMARY_HOVER_FOCUS_BACKGROUND_COLOR;
  const stringColor = accentColors.primaryButtonStringColor || styleConstants.PRIMARY_COLOR;

  // These state variables should help us manually keep track of whether the button is in default/hover/focus
  // states. Note that when both `hover` and `focus` are false, the button is in the default state.
  // Initially, the button should be not in the hover state but might be in the focus state depending on the
  // input value of `hasFocus`
  const [hover, setHover] = React.useState(false);
  const [focus, setFocus] = React.useState(hasFocus);

  // This state variable keeps track of the button's current background color. Over time the value of this
  // variable should change depending on the default/hover/focus states of the button.
  // Initially this variable should be either the default or focus color depending on `hasFocus`
  const [buttonBackgroundColor, setButtonBackgroundColor] = React.useState(
    hasFocus ? focusColor : defaultColor,
  );

  // These handler functions act as listeners for mouse events and change the `hover` and `focus` state variables
  // appropriately. `mouseOverHandler` and `mouseOutHandler` are for the hover state while `mouseFocusHandler` and
  // `mouseBlurHandler` are for the focus state
  const mouseOverHandler = () => setHover(true);
  const mouseOutHandler = () => setHover(false);
  const mouseFocusHandler = () => setFocus(true);
  const mouseBlurHandler = () => setFocus(false);

  // Every time `hover` and/or `focus` changes, this hook should fire and update the button's background
  // color accordingly. Note that although `focusColor`, `hoverColor` and `defaultColor` are specified as
  // dependencies, we are not expecting these to change throughout the lifecycle of the button component
  useEffect(() => {
    if (focus) {
      setButtonBackgroundColor(focusColor);
    } else if (hover) {
      setButtonBackgroundColor(hoverColor);
    } else {
      setButtonBackgroundColor(defaultColor);
    }
  }, [focus, focusColor, hover, hoverColor, defaultColor]);

  // We only apply accent colors to buttons when it's a primary button and at least one accent color is
  // specified by the server
  const applyAccentColors = isPrimary && checkAppBrandingProperties(accentColors);

  // If we are supposed to apply accent colors, populate the style attribute for the button element so it can
  // override the default colors applied by Griffel. Note that if we are not supposed to apply accent colors,
  // pass in an empty object - this is effectively the same as not specifying a style attribute so Griffel
  // can do it's job in peace
  const styleAttribute = applyAccentColors
    ? {
        color: stringColor,
        borderColor: defaultColor,
        // Unlike the other properties, `backgroundColor` is set to a React state variable so the background color
        // can change throughout the button's lifecycle depending on mouse events and states
        backgroundColor: buttonBackgroundColor,
      }
    : {};

  // Merge and dedupe styles. The order in which these are listed matters. Use a custom class, primary styles, or
  // fallback to default styles.
  const { useTextButtonStyles } = StylesConfig.instance;
  const buttonStyles = useTextButtonStyles();
  const mergedClasses = mergeClasses(
    buttonStyles.default,
    buttonStyles.highContrast,
    isPrimary
      ? mergeClasses(buttonStyles.primary, ExternalClassName.primary)
      : ExternalClassName.secondary,
    ExternalClassName.button,
    customCss,
  );
  const buttonItemClasses = mergeClasses(buttonStyles.buttonItem, ExternalClassName.buttonItem);

  const buttonProps = {
    ...buttonParams,
    className: mergedClasses,
    style: styleAttribute,
    onMouseOver: mouseOverHandler,
    onMouseOut: mouseOutHandler,
    onFocus: mouseFocusHandler,
    onBlur: mouseBlurHandler,
  };

  return (
    <div className={buttonItemClasses}>
      <ButtonFabric {...buttonProps} ref={forwardRef}>
        {label}
        {children}
      </ButtonFabric>
    </div>
  );
});

interface ITextButtonContainerProps {
  customCss?: string;
}

/**
 * TextButtonContainer component
 * When used in views such as the KMSI view, text buttons are typically enclosed in a div with specific layout
 * styles (e.g., width, RTL, LTR styles etc.). This container is implemented to avoid having each view implement
 * these styles separately
 * @param props The properties for this component
 * @param props.children The child elements to render inside this layout
 * @param props.customCss Custom CSS to apply to this component
 * @returns an instance of the shared text button container component
 */
export const TextButtonContainer: React.FC<ITextButtonContainerProps> =
  function TextButtonContainer({ children, customCss }) {
    const { useTextButtonContainerStyles } = StylesConfig.instance;
    const layoutStyles = useTextButtonContainerStyles();
    const {
      globalState: {
        styles: { boilerPlateText },
      },
    } = useContext(GlobalContext);
    const mergedClasses = mergeClasses(
      ExternalClassName.buttonFieldContainer,
      layoutStyles.lightboxLayout,
      boilerPlateText ? layoutStyles.movedButtons : "",
      customCss,
    );

    return (
      <div className={mergedClasses} data-testid="textButtonContainer">
        {children}
      </div>
    );
  };
