import { makeStyles } from "@griffel/react";

export const useSecondaryContentStylesFabric = makeStyles({
  smallFontSize: {
    fontSize: ".8125rem",
  },

  containerLayout: {
    position: "relative",
    minHeight: "1px",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
});

export type SecondaryContentStylesFabric = ReturnType<typeof useSecondaryContentStylesFabric>;
