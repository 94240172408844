import arrow_left_png from "../../images/arrow_left.png";
import arrow_left_svg from "../../images/arrow_left.svg";
import arrow_left_white_png from "../../images/arrow_left_white.png";
import arrow_left_white_svg from "../../images/arrow_left_white.svg";
import arrow_right_png from "../../images/arrow_right.png";
import arrow_right_svg from "../../images/arrow_right.svg";
import arrow_right_white_png from "../../images/arrow_right_white.png";
import arrow_right_white_svg from "../../images/arrow_right_white.svg";
import authenticatorinfo_gif from "../../images/authenticatorinfo.gif";
import check_small_png from "../../images/check_small.png";
import check_small_svg from "../../images/check_small.svg";
import check_small_white_png from "../../images/check_small_white.png";
import check_small_white_svg from "../../images/check_small_white.svg";
import close_png from "../../images/close.png";
import close_svg from "../../images/close.svg";
import close_white_png from "../../images/close_white.png";
import close_white_svg from "../../images/close_white.svg";
import cred_option_accesspass_png from "../../images/CredentialOptions/cred_option_accesspass.png";
import cred_option_accesspass_svg from "../../images/CredentialOptions/cred_option_accesspass.svg";
import cred_option_accesspass_white_png from "../../images/CredentialOptions/cred_option_accesspass_white.png";
import cred_option_accesspass_white_svg from "../../images/CredentialOptions/cred_option_accesspass_white.svg";
import cred_option_authenticator_png from "../../images/CredentialOptions/cred_option_authenticator.png";
import cred_option_authenticator_svg from "../../images/CredentialOptions/cred_option_authenticator.svg";
import cred_option_authenticator_white_png from "../../images/CredentialOptions/cred_option_authenticator_white.png";
import cred_option_authenticator_white_svg from "../../images/CredentialOptions/cred_option_authenticator_white.svg";
import cred_option_certificate_png from "../../images/CredentialOptions/cred_option_certificate.png";
import cred_option_certificate_svg from "../../images/CredentialOptions/cred_option_certificate.svg";
import cred_option_certificate_white_png from "../../images/CredentialOptions/cred_option_certificate_white.png";
import cred_option_certificate_white_svg from "../../images/CredentialOptions/cred_option_certificate_white.svg";
import cred_option_fido_png from "../../images/CredentialOptions/cred_option_fido.png";
import cred_option_fido_svg from "../../images/CredentialOptions/cred_option_fido.svg";
import cred_option_fido_white_png from "../../images/CredentialOptions/cred_option_fido_white.png";
import cred_option_fido_white_svg from "../../images/CredentialOptions/cred_option_fido_white.svg";
import cred_option_forgot_png from "../../images/CredentialOptions/cred_option_forgot.png";
import cred_option_forgot_svg from "../../images/CredentialOptions/cred_option_forgot.svg";
import cred_option_forgot_white_png from "../../images/CredentialOptions/cred_option_forgot_white.png";
import cred_option_forgot_white_svg from "../../images/CredentialOptions/cred_option_forgot_white.svg";
import cred_option_generic_app_png from "../../images/CredentialOptions/cred_option_generic_app.png";
import cred_option_generic_app_svg from "../../images/CredentialOptions/cred_option_generic_app.svg";
import cred_option_generic_app_white_png from "../../images/CredentialOptions/cred_option_generic_app_white.png";
import cred_option_generic_app_white_svg from "../../images/CredentialOptions/cred_option_generic_app_white.svg";
import cred_option_github_png from "../../images/CredentialOptions/cred_option_github.png";
import cred_option_github_svg from "../../images/CredentialOptions/cred_option_github.svg";
import cred_option_github_white_png from "../../images/CredentialOptions/cred_option_github_white.png";
import cred_option_github_white_svg from "../../images/CredentialOptions/cred_option_github_white.svg";
import cred_option_linkedin_png from "../../images/CredentialOptions/cred_option_linkedin.png";
import cred_option_linkedin_svg from "../../images/CredentialOptions/cred_option_linkedin.svg";
import cred_option_linkedin_white_png from "../../images/CredentialOptions/cred_option_linkedin_white.png";
import cred_option_linkedin_white_svg from "../../images/CredentialOptions/cred_option_linkedin_white.svg";
import cred_option_passkey_png from "../../images/CredentialOptions/cred_option_passkey.png";
import cred_option_passkey_svg from "../../images/CredentialOptions/cred_option_passkey.svg";
import cred_option_passkey_white_png from "../../images/CredentialOptions/cred_option_passkey_white.png";
import cred_option_passkey_white_svg from "../../images/CredentialOptions/cred_option_passkey_white.svg";
import cred_option_password_png from "../../images/CredentialOptions/cred_option_password.png";
import cred_option_password_svg from "../../images/CredentialOptions/cred_option_password.svg";
import cred_option_password_white_png from "../../images/CredentialOptions/cred_option_password_white.png";
import cred_option_password_white_svg from "../../images/CredentialOptions/cred_option_password_white.svg";
import documentation_png from "../../images/documentation.png";
import documentation_svg from "../../images/documentation.svg";
import documentation_white_png from "../../images/documentation_white.png";
import documentation_white_svg from "../../images/documentation_white.svg";
import dropdown_caret_png from "../../images/dropdown_caret.png";
import dropdown_caret_svg from "../../images/dropdown_caret.svg";
import dropdown_caret_white_png from "../../images/dropdown_caret_white.png";
import dropdown_caret_white_svg from "../../images/dropdown_caret_white.svg";
import generic_app_gif from "../../images/generic_app.gif";
import marching_ants_gif from "../../images/marching_ants.gif";
import marching_ants_white_gif from "../../images/marching_ants_white.gif";
import microsoft_logo_png from "../../images/microsoft_logo.png";
import microsoft_logo_svg from "../../images/microsoft_logo.svg";
import microsoft_logo_white_png from "../../images/microsoft_logo_white.png";
import microsoft_logo_white_svg from "../../images/microsoft_logo_white.svg";
import personal_account_png from "../../images/personal_account.png";
import personal_account_svg from "../../images/personal_account.svg";
import personal_account_white_png from "../../images/personal_account_white.png";
import personal_account_white_svg from "../../images/personal_account_white.svg";
import picker_account_aad_png from "../../images/picker_account_aad.png";
import picker_account_aad_svg from "../../images/picker_account_aad.svg";
import picker_account_add_png from "../../images/picker_account_add.png";
import picker_account_add_svg from "../../images/picker_account_add.svg";
import picker_account_msa_png from "../../images/picker_account_msa.png";
import picker_account_msa_svg from "../../images/picker_account_msa.svg";
import picker_more_png from "../../images/picker_more.png";
import picker_more_svg from "../../images/picker_more.svg";
import picker_more_white_png from "../../images/picker_more_white.png";
import picker_more_white_svg from "../../images/picker_more_white.svg";
import picker_verify_authenticator_png from "../../images/picker_verify_authenticator.png";
import picker_verify_authenticator_svg from "../../images/picker_verify_authenticator.svg";
import picker_verify_authenticator_white_png from "../../images/picker_verify_authenticator_white.png";
import picker_verify_authenticator_white_svg from "../../images/picker_verify_authenticator_white.svg";
import picker_verify_call_png from "../../images/picker_verify_call.png";
import picker_verify_call_svg from "../../images/picker_verify_call.svg";
import picker_verify_call_white_png from "../../images/picker_verify_call_white.png";
import picker_verify_call_white_svg from "../../images/picker_verify_call_white.svg";
import picker_verify_code_png from "../../images/picker_verify_code.png";
import picker_verify_code_svg from "../../images/picker_verify_code.svg";
import picker_verify_code_white_png from "../../images/picker_verify_code_white.png";
import picker_verify_code_white_svg from "../../images/picker_verify_code_white.svg";
import picker_verify_email_png from "../../images/picker_verify_email.png";
import picker_verify_email_svg from "../../images/picker_verify_email.svg";
import picker_verify_email_white_png from "../../images/picker_verify_email_white.png";
import picker_verify_email_white_svg from "../../images/picker_verify_email_white.svg";
import picker_verify_fluent_authenticator_svg from "../../images/picker_verify_fluent_authenticator.svg";
import picker_verify_fluent_authenticator_white_svg from "../../images/picker_verify_fluent_authenticator_white.svg";
import picker_verify_sms_png from "../../images/picker_verify_sms.png";
import picker_verify_sms_svg from "../../images/picker_verify_sms.svg";
import picker_verify_sms_white_png from "../../images/picker_verify_sms_white.png";
import picker_verify_sms_white_svg from "../../images/picker_verify_sms_white.svg";
import signin_options_png from "../../images/signin_options.png";
import signin_options_svg from "../../images/signin_options.svg";
import signin_options_white_png from "../../images/signin_options_white.png";
import signin_options_white_svg from "../../images/signin_options_white.svg";
import xbox_remote_qrcode_svg from "../../images/xbox_remote_qrcode.svg";

export const images = new Map<string, string>([
  ["arrow_left_png", arrow_left_png],
  ["arrow_left_white_png", arrow_left_white_png],
  ["arrow_left_svg", arrow_left_svg],
  ["arrow_left_white_svg", arrow_left_white_svg],
  ["arrow_right_png", arrow_right_png],
  ["arrow_right_white_png", arrow_right_white_png],
  ["arrow_right_svg", arrow_right_svg],
  ["arrow_right_white_svg", arrow_right_white_svg],
  ["authenticatorinfo_gif", authenticatorinfo_gif],
  ["check_small_png", check_small_png],
  ["check_small_white_png", check_small_white_png],
  ["check_small_svg", check_small_svg],
  ["check_small_white_svg", check_small_white_svg],
  ["close_png", close_png],
  ["close_white_png", close_white_png],
  ["close_svg", close_svg],
  ["close_white_svg", close_white_svg],
  ["cred_option_accesspass_png", cred_option_accesspass_png],
  ["cred_option_accesspass_svg", cred_option_accesspass_svg],
  ["cred_option_accesspass_white_png", cred_option_accesspass_white_png],
  ["cred_option_accesspass_white_svg", cred_option_accesspass_white_svg],
  ["cred_option_authenticator_png", cred_option_authenticator_png],
  ["cred_option_authenticator_svg", cred_option_authenticator_svg],
  ["cred_option_authenticator_white_png", cred_option_authenticator_white_png],
  ["cred_option_authenticator_white_svg", cred_option_authenticator_white_svg],
  ["cred_option_certificate_png", cred_option_certificate_png],
  ["cred_option_certificate_svg", cred_option_certificate_svg],
  ["cred_option_certificate_white_png", cred_option_certificate_white_png],
  ["cred_option_certificate_white_svg", cred_option_certificate_white_svg],
  ["cred_option_fido_png", cred_option_fido_png],
  ["cred_option_fido_svg", cred_option_fido_svg],
  ["cred_option_fido_white_png", cred_option_fido_white_png],
  ["cred_option_fido_white_svg", cred_option_fido_white_svg],
  ["cred_option_forgot_png", cred_option_forgot_png],
  ["cred_option_forgot_svg", cred_option_forgot_svg],
  ["cred_option_forgot_white_png", cred_option_forgot_white_png],
  ["cred_option_forgot_white_svg", cred_option_forgot_white_svg],
  ["cred_option_generic_app_png", cred_option_generic_app_png],
  ["cred_option_generic_app_svg", cred_option_generic_app_svg],
  ["cred_option_generic_app_white_png", cred_option_generic_app_white_png],
  ["cred_option_generic_app_white_svg", cred_option_generic_app_white_svg],
  ["cred_option_github_png", cred_option_github_png],
  ["cred_option_github_svg", cred_option_github_svg],
  ["cred_option_github_white_png", cred_option_github_white_png],
  ["cred_option_github_white_svg", cred_option_github_white_svg],
  ["cred_option_linkedin_png", cred_option_linkedin_png],
  ["cred_option_linkedin_svg", cred_option_linkedin_svg],
  ["cred_option_linkedin_white_png", cred_option_linkedin_white_png],
  ["cred_option_linkedin_white_svg", cred_option_linkedin_white_svg],
  ["cred_option_passkey_png", cred_option_passkey_png],
  ["cred_option_passkey_svg", cred_option_passkey_svg],
  ["cred_option_passkey_white_png", cred_option_passkey_white_png],
  ["cred_option_passkey_white_svg", cred_option_passkey_white_svg],
  ["cred_option_password_png", cred_option_password_png],
  ["cred_option_password_svg", cred_option_password_svg],
  ["cred_option_password_white_png", cred_option_password_white_png],
  ["cred_option_password_white_svg", cred_option_password_white_svg],
  ["documentation_png", documentation_png],
  ["documentation_svg", documentation_svg],
  ["documentation_white_png", documentation_white_png],
  ["documentation_white_svg", documentation_white_svg],
  ["dropdown_caret_png", dropdown_caret_png],
  ["dropdown_caret_svg", dropdown_caret_svg],
  ["dropdown_caret_white_png", dropdown_caret_white_png],
  ["dropdown_caret_white_svg", dropdown_caret_white_svg],
  ["generic_app_gif", generic_app_gif],
  ["marching_ants_gif", marching_ants_gif],
  ["marching_ants_white_gif", marching_ants_white_gif],
  ["microsoft_logo_png", microsoft_logo_png],
  ["microsoft_logo_white_png", microsoft_logo_white_png],
  ["microsoft_logo_svg", microsoft_logo_svg],
  ["microsoft_logo_white_svg", microsoft_logo_white_svg],
  ["personal_account_png", personal_account_png],
  ["personal_account_svg", personal_account_svg],
  ["personal_account_white_png", personal_account_white_png],
  ["personal_account_white_svg", personal_account_white_svg],
  ["picker_account_aad_png", picker_account_aad_png],
  ["picker_account_aad_svg", picker_account_aad_svg],
  ["picker_account_add_png", picker_account_add_png],
  ["picker_account_add_svg", picker_account_add_svg],
  ["picker_account_msa_png", picker_account_msa_png],
  ["picker_account_msa_svg", picker_account_msa_svg],
  ["picker_more_png", picker_more_png],
  ["picker_more_svg", picker_more_svg],
  ["picker_more_white_png", picker_more_white_png],
  ["picker_more_white_svg", picker_more_white_svg],
  ["picker_verify_authenticator_png", picker_verify_authenticator_png],
  ["picker_verify_authenticator_svg", picker_verify_authenticator_svg],
  ["picker_verify_authenticator_white_png", picker_verify_authenticator_white_png],
  ["picker_verify_authenticator_white_svg", picker_verify_authenticator_white_svg],
  ["picker_verify_call_png", picker_verify_call_png],
  ["picker_verify_call_svg", picker_verify_call_svg],
  ["picker_verify_call_white_png", picker_verify_call_white_png],
  ["picker_verify_call_white_svg", picker_verify_call_white_svg],
  ["picker_verify_code_png", picker_verify_code_png],
  ["picker_verify_code_svg", picker_verify_code_svg],
  ["picker_verify_code_white_png", picker_verify_code_white_png],
  ["picker_verify_code_white_svg", picker_verify_code_white_svg],
  ["picker_verify_email_png", picker_verify_email_png],
  ["picker_verify_email_svg", picker_verify_email_svg],
  ["picker_verify_email_white_png", picker_verify_email_white_png],
  ["picker_verify_email_white_svg", picker_verify_email_white_svg],
  ["picker_verify_fluent_authenticator_svg", picker_verify_fluent_authenticator_svg],
  ["picker_verify_fluent_authenticator_white_svg", picker_verify_fluent_authenticator_white_svg],
  ["picker_verify_sms_png", picker_verify_sms_png],
  ["picker_verify_sms_svg", picker_verify_sms_svg],
  ["picker_verify_sms_white_png", picker_verify_sms_white_png],
  ["picker_verify_sms_white_svg", picker_verify_sms_white_svg],
  ["signin_options_png", signin_options_png],
  ["signin_options_svg", signin_options_svg],
  ["signin_options_white_png", signin_options_white_png],
  ["signin_options_white_svg", signin_options_white_svg],
  ["xbox_remote_qrcode_svg", xbox_remote_qrcode_svg],
]);
