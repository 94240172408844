import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { HistoryContext } from "../history-context";
import { HistoryActionType } from "../history-reducer";

/**
 * If there is a pop state event (back/forward browser button is clicked), this function determines whether
 * to navigate back or forward based on comparing the state of the event to what's currently stored as history index.
 * Here, we also reset the value of the stored history index to either 0 or the one returned by the state
 * @returns Hook used for pop state event listener, which determines browser back/forward button actions
 */
export const useOnPopStateEventListener = () => {
  const navigate = useNavigate();
  const {
    historyState: { historyIndex: currentHistoryIndex },
    dispatchStateChange: dispatchHistoryStateChange,
  } = useContext(HistoryContext);

  return async (event: PopStateEvent) => {
    if (!event || typeof event.state === "undefined" || event.state === null) {
      return;
    }

    const newHistoryIndex = event.state;
    // compare state from event to updated stored history index. If the pushed state is less than
    // the stored history index, then we know that the page was previously navigated forward and
    // we want to navigate back. Otherwise we want to navigate forward
    if (newHistoryIndex < currentHistoryIndex) {
      // navigate back
      navigate(-1);
    } else {
      // navigate forward
      navigate(1);
    }

    // reset the history index
    if (newHistoryIndex < 0) {
      dispatchHistoryStateChange({
        type: HistoryActionType.SetHistoryIndexAction,
        payload: 0,
      });
    } else {
      dispatchHistoryStateChange({
        type: HistoryActionType.SetHistoryIndexAction,
        payload: newHistoryIndex,
      });
    }
  };
};
