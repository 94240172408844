import { makeStyles } from "@griffel/react";

export const useDebugTraceStylesFabric = makeStyles({
  traceSection: {
    marginTop: "10px",
  },
  traceLink: {
    "text-decoration": "none",
    fontSize: "13px",
  },
});

export type DebugTraceStylesFabric = ReturnType<typeof useDebugTraceStylesFabric>;
