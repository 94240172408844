import React, { useContext, useState } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../../../config/styles-config";
import { ExternalClassName } from "../../../constants/constants";
import { EnvironmentName } from "../../../constants/environmentbanner-constants";
import GlobalConfig from "../../../global-config";
import { GlobalContext } from "../../../global-context";
import { isCssAnimationSupported } from "../../../utilities/browser-helper";
import Background from "../../background";
import DirectionWrapper from "../../direction-wrapper";
import EnvironmentBanner from "../../environment-banner";
import Footer from "../../footer/fabric/footer-fabric";
import Header from "../../header";
import LayoutContent, { useLayoutContentStyles } from "../../layout-content";

type LightboxLayoutProps = {
  styles: {
    disableLightbox: string;
    inner: string;
    innerAlwaysShowBackground: string;
    innerWithAppBranding: string;
    innerWithPopup: string;
    lightboxCover: string;
    middle: string;
    outer: string;
    paginationView: string;
    paginationViewWithIdentityBanner: string;
    wide: string;
    transparent: string;
  };
  bannerEnvironment: EnvironmentName;
};

/**
 * LightboxLayout component
 * @param props The properties for this component
 * @param props.styles The styles to apply to various parts of this component
 * @param props.bannerEnvironment The environment to show the banner for
 * @param props.children The child elements to render inside this component
 * @returns PLACEHOLDER - NEEDS TO BE UPDATED
 */
const LightboxLayoutFabric: React.FC<LightboxLayoutProps> = function LightboxLayoutFabric({
  styles,
  bannerEnvironment,
  children,
}) {
  const {
    globalState,
    globalState: {
      showMoreOptions,
      isWideView,
      styles: { alwaysShowBackground, backgroundLogoUrl, useTransparentLightbox },
    },
  } = useContext(GlobalContext);
  const { hideFooter } = GlobalConfig.instance;
  const [showDebugInfo, setShowDebugInfo] = useState(false);
  const [focusOnDebugCopyLink, setFocusOnDebugCopyLink] = useState(false);
  const [focusOnEllipsis, setFocusOnEllipsis] = useState(false);

  const { useLayoutAnimateStyles } = StylesConfig.instance;
  const animateStyles = useLayoutAnimateStyles();
  const animationSupported = isCssAnimationSupported();

  const middleClassNames = mergeClasses(styles.middle, ExternalClassName.middle);
  let innerClassName = mergeClasses(
    styles.inner,
    isWideView ? styles.wide : "",
    useTransparentLightbox ? styles.transparent : "",
    alwaysShowBackground ? styles.innerAlwaysShowBackground : "",
    showMoreOptions ? styles.innerWithPopup : "",
    // @TODO: Figure out a way to detect app-branding based on all app-branding server data properties
    //        and not just the background url logo. Currently this is responsible for a CSS bug where
    //        there is no spacing between the lightbox / vertical split layout and the sign-in options
    //        tile if the branding for the app does not include a logo but does include a friendly app
    //        name and/or sign-in/sign-up descriptions.
    backgroundLogoUrl ? styles.innerWithAppBranding : "",
  );

  // Add animation styles for lightbox/inner container when animation is supported
  if (animationSupported) {
    innerClassName = mergeClasses(innerClassName, animateStyles.fadeIn);
  }

  const shouldShowBanner = bannerEnvironment !== EnvironmentName.None && bannerEnvironment;

  return (
    <DirectionWrapper testID="lightbox-layout">
      <Background />
      <div id="outer" data-testid="outer" className={styles.outer} role="presentation">
        {shouldShowBanner && <EnvironmentBanner />}
        <Header />
        <div id="middle" className={middleClassNames} role="main">
          <LayoutContent
            focusOnDebugCopyLink={focusOnDebugCopyLink}
            setFocusOnEllipsis={setFocusOnEllipsis}
            showDebugInfo={showDebugInfo}
            setShowDebugInfo={setShowDebugInfo}
            styles={useLayoutContentStyles(globalState, innerClassName)}
          >
            {children}
          </LayoutContent>
        </div>
        {!hideFooter && (
          <Footer
            isDebugInfoShown={showDebugInfo}
            focusOnEllipsis={focusOnEllipsis}
            onDebugItemClick={(e) => {
              e.preventDefault();
              setFocusOnDebugCopyLink(true);
              setShowDebugInfo(!showDebugInfo);
            }}
          />
        )}
      </div>
    </DirectionWrapper>
  );
};

export default LightboxLayoutFabric;
