import { makeStyles } from "@griffel/react";

export const usePushNotificationsViewStylesFabric = makeStyles({
  listItems: {
    listStyleType: "disc",
    marginLeft: "1em",
    paddingLeft: "1em",
  },
});

export type PushNotificationsViewStylesFabric = ReturnType<
  typeof usePushNotificationsViewStylesFabric
>;
