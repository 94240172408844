import {
  type GctResult,
  parseGetCredentialTypeResponse,
} from "../../utilities/api-helpers/get-credential-type-helper";
import { getFidoSupport } from "../../utilities/browser-helper";
import { ConfigWrapper } from "../../utilities/config-wrapper";
import { type ICountryInfo, parseCountryList } from "../../utilities/country-helper";
import type { ServerData } from "../../utilities/server-data";
import { cleanseUsername, htmlUnescape } from "../../utilities/strings-helper";
import { LoginState, transformCredentialFlags } from "./login-constants";

export const defaultLoginConfig = {
  allowPhone: true,
  allowSkype: true,
  allowedIdentities: 0,
  appBrandedSignInHeader: "",
  breakBrandingSigninString: false,
  canaryValidationUrl: "",
  changePasswordUrl: "",
  countryList: [] as ICountryInfo[],
  credentialTypeResult: {} as Partial<GctResult>,
  defaultCountry: "",
  defaultLoginOptions: 0,
  defaultSignInHeader: "",
  disambigRenameUrl: "",
  doIfExists: false,
  externalCanary: "",
  facebookFedUrl: "",
  fedPartnerName: "",
  fedQs: "",
  fedState: 0,
  fedUrl: "",
  fidoHelpUrl: "",
  fidoAllowList: [] as string[],
  fidoChallenge: "",
  fidoUseAllowedIdentities: false,
  flowTokenCookieChunk: false,
  flowTokenCookieName: "",
  forgetUrl: "",
  forgotUsernameUrl: "",
  foundMsas: "",
  gctFederationFlags: 0,
  getCredentialTypeUrl: "",
  githubFedUrl: "",
  googleFedUrl: "",
  hasWizardBehavior: false,
  hideLoginDescription: false,
  idpRedirectTimeoutMs: 2000,
  improvePhoneDisambiguation: false,
  ipv6ExperimentUrl: "",
  isExternalFederationDisallowed: false,
  isFederationDisabled: false,
  isFidoSupportedHint: false,
  isOtcLoginDisabled: false,
  isPasskeySupported: false,
  isRemoteConnectSignup: false,
  isRemoteNGCSupported: false,
  isScoobe: false,
  isTokenBroker: false,
  linkedInFedUrl: "",
  lockUsername: false,
  loginDescription: "",
  loginMode: 0,
  loginStringsVariant: 0,
  logoutUrl: "",
  lostAuthenticatorUrl: "",
  noCookiesUrl: "",
  otherIdpPostParams: {},
  passwordViewHeader: "",
  postedForceSignIn: false,
  postedLoginState: 0,
  postedUsername: "",
  postProofType: 0,
  prefillUsername: "",
  promotedFedCredType: 0,
  protocolRefreshUrl: "",
  // random sequence of characters for padding passport posts
  randomBlob: "",
  rawQueryString: "",
  remoteConnectAppName: "",
  remoteConnectUserCode: "",
  resetPasswordPrefillParam: "mn",
  resetPasswordUrl: "",
  riskySignIn: false,
  serverErrorText: "",
  serverValidationErrors: [] as string[],
  showFidoInline: false,
  showForgotUsernameLink: false,
  showOfflineAccount: false,
  showOnlyGithubOnCredPicker: false,
  showPushNotifications: false,
  showRemoteConnectLocationPage: false,
  showSignup: true,
  showSwitchUser: false,
  signupUrl: "",
  signupUrlPostParams: {},
  staySignInUrl: "",
  switchDisambig: false,
  upgradeRedirectWithUsernameUrl: "",
  useForgetUserIframe: false,
  useInlinePhoneNumber: true,
  useResetPasswordUrlForPasswordRequiredError: false,
  writePasswordToPropertyBag: false,
  ztdUpnHint: "",
};

const configWrapper = new ConfigWrapper(defaultLoginConfig);

/**
 * The config wrapper that should be used to access Login config properties
 */
export default configWrapper;

/* ********* ServerData helpers ********** */

/**
 * Initialize the Login config object from ServerData
 * @param serverData The IDP-specific server data object that should be used to initialize the Login config
 */
export function initLoginConfig(serverData: ServerData) {
  const mappedServerData: Partial<typeof defaultLoginConfig> = {};

  if (serverData?.iLoginMode) {
    mappedServerData.loginMode = serverData.iLoginMode;
  }

  if (serverData?.oAppCobranding) {
    const appBranding = serverData.oAppCobranding;

    if (appBranding?.signinDescription) {
      mappedServerData.loginDescription = appBranding.signinDescription;
    }

    if (appBranding?.signinPasswordTitle) {
      mappedServerData.passwordViewHeader = appBranding.signinPasswordTitle;
    }

    if (appBranding?.signinTitle) {
      mappedServerData.appBrandedSignInHeader = appBranding.signinTitle;
    }
  }

  if (serverData?.iPromotedFedCredType) {
    mappedServerData.promotedFedCredType = serverData.iPromotedFedCredType;
  }

  if (serverData?.urlLinkedInFed) {
    mappedServerData.linkedInFedUrl = serverData.urlLinkedInFed;
  }

  if (serverData?.urlGitHubFed) {
    mappedServerData.githubFedUrl = serverData.urlGitHubFed;
  }

  if (serverData?.urlGoogleFed) {
    mappedServerData.googleFedUrl = serverData.urlGoogleFed;
  }

  if (serverData?.urlFacebookFed) {
    mappedServerData.facebookFedUrl = serverData.urlFacebookFed;
  }

  if (serverData?.fAllowPhoneSignIn !== undefined || serverData?.fAllowPhoneInput !== undefined) {
    mappedServerData.allowPhone = !!(serverData?.fAllowPhoneSignIn || serverData?.fAllowPhoneInput);
  }

  if (serverData?.fAllowSkypeNameLogin !== undefined) {
    mappedServerData.allowSkype = serverData?.fAllowSkypeNameLogin;
  }

  if (serverData?.fShowSignInWithFidoOnUsernameView !== undefined) {
    mappedServerData.showFidoInline = serverData.fShowSignInWithFidoOnUsernameView;
  }

  if (serverData?.fShowForgotUsernameLink !== undefined) {
    mappedServerData.showForgotUsernameLink = serverData.fShowForgotUsernameLink;
  }

  if (serverData?.urlForgotUsername) {
    mappedServerData.forgotUsernameUrl = serverData.urlForgotUsername;
  }

  if (serverData?.fOfflineAccountVisible !== undefined) {
    mappedServerData.showOfflineAccount = serverData.fOfflineAccountVisible;
  }

  if (serverData?.fShowSignInWithGitHubOnlyOnCredPicker !== undefined) {
    mappedServerData.showOnlyGithubOnCredPicker = serverData.fShowSignInWithGitHubOnlyOnCredPicker;
  }

  if (serverData?.fIsFidoSupported !== undefined) {
    mappedServerData.isFidoSupportedHint = serverData.fIsFidoSupported;
  }

  if (serverData?.urlNoCookies) {
    mappedServerData.noCookiesUrl = serverData.urlNoCookies;
  }

  if (serverData?.iFedState) {
    mappedServerData.fedState = serverData.iFedState;
  }

  if (serverData?.iDefaultLoginOptions) {
    mappedServerData.defaultLoginOptions = serverData.iDefaultLoginOptions;
  }

  if (serverData?.urlFed) {
    mappedServerData.fedUrl = serverData.urlFed;
  }

  if (serverData?.sFedQS) {
    mappedServerData.fedQs = serverData.sFedQS;
  }

  if (serverData?.iLoginStringsVariantId) {
    mappedServerData.loginStringsVariant = serverData.iLoginStringsVariantId;
  }

  if (serverData?.sRemoteConnectAppName) {
    mappedServerData.remoteConnectAppName = serverData.sRemoteConnectAppName;
  }

  if (serverData?.urlIPv6Experiment) {
    mappedServerData.ipv6ExperimentUrl = serverData.urlIPv6Experiment;
  }

  if (serverData?.sFedPartnerName) {
    mappedServerData.fedPartnerName = serverData.sFedPartnerName;
  }

  if (serverData?.urlStaySignIn) {
    mappedServerData.staySignInUrl = serverData.urlStaySignIn;
  }

  if (serverData?.strSignInHeader) {
    mappedServerData.defaultSignInHeader = serverData.strSignInHeader;
  }

  if (serverData?.fBreakBrandingSigninString) {
    mappedServerData.breakBrandingSigninString = serverData.fBreakBrandingSigninString;
  }

  if (serverData?.iGctFederationFlags) {
    mappedServerData.gctFederationFlags = serverData.iGctFederationFlags;
  }

  if (serverData?.fIsExternalFederationDisallowed !== undefined) {
    mappedServerData.isExternalFederationDisallowed = !!serverData.fIsExternalFederationDisallowed;
  }

  if (serverData?.fIsFedDisabled !== undefined) {
    mappedServerData.isFederationDisabled = serverData.fIsFedDisabled;
  }

  if (serverData?.fIsRemoteNGCSupported !== undefined) {
    mappedServerData.isRemoteNGCSupported = serverData.fIsRemoteNGCSupported;
  }

  if (serverData?.fIsOtcLoginDisabled !== undefined) {
    mappedServerData.isOtcLoginDisabled = serverData.fIsOtcLoginDisabled;
  }

  if (serverData?.urlGetCredentialType) {
    mappedServerData.getCredentialTypeUrl = serverData.urlGetCredentialType;
  }

  if (serverData?.sSMSCtryPhoneData) {
    mappedServerData.countryList = parseCountryList(serverData.sSMSCtryPhoneData, true);
  }

  if (serverData?.sPrefSMSCountry) {
    mappedServerData.defaultCountry = serverData.sSMSCtryPhoneData;
  }

  if (serverData?.sProofType) {
    mappedServerData.postProofType = parseInt(serverData.sProofType, 10);
  }

  if (serverData?.fUseInlinePhoneNumber !== undefined) {
    mappedServerData.useInlinePhoneNumber = serverData.fUseInlinePhoneNumber;
  }

  if (serverData?.fCBShowSignUp !== undefined) {
    mappedServerData.showSignup = serverData.fCBShowSignUp;
  }

  if (serverData?.urlSignUp) {
    mappedServerData.signupUrl = serverData.urlSignUp;
  }

  if (serverData?.sRandomBlob) {
    mappedServerData.randomBlob = serverData.sRandomBlob;
  }

  if (serverData?.sFoundMSAs) {
    mappedServerData.foundMsas = serverData.sFoundMSAs;
  }

  if (serverData?.sRawQueryString) {
    mappedServerData.rawQueryString = serverData.sRawQueryString;
  }

  if (serverData?.fPOST_ForceSignin !== undefined) {
    mappedServerData.postedForceSignIn = serverData.fPOST_ForceSignin;
  }

  if (serverData?.fUseWizardBehavior !== undefined) {
    mappedServerData.hasWizardBehavior = serverData.fUseWizardBehavior;
  }

  if (serverData?.oSignUpPostParams) {
    mappedServerData.signupUrlPostParams = serverData.oSignUpPostParams;
  }

  if (serverData?.fDoIfExists !== undefined) {
    mappedServerData.doIfExists = serverData.fDoIfExists;
  }

  if (serverData?.urlResetPassword) {
    mappedServerData.resetPasswordUrl = serverData.urlResetPassword;
  }

  if (serverData?.urlFidoHelp) {
    mappedServerData.fidoHelpUrl = serverData.urlFidoHelp;
  }

  if (serverData?.sExternalCanary) {
    mappedServerData.externalCanary = serverData.sExternalCanary;
  }

  if (serverData?.urlCanaryValidation) {
    mappedServerData.canaryValidationUrl = serverData.urlCanaryValidation;
  }

  if (serverData?.fIsRemoteConnectSignup !== undefined) {
    mappedServerData.isRemoteConnectSignup = serverData.fIsRemoteConnectSignup;
  }

  if (serverData?.urlDisambigRename) {
    mappedServerData.disambigRenameUrl = serverData.urlDisambigRename;
  }

  if (serverData?.fShowSwitchUser !== undefined) {
    mappedServerData.showSwitchUser = serverData.fShowSwitchUser;
  }

  // we have logic dependent on this property being undefined
  mappedServerData.otherIdpPostParams = serverData.oUrlOtherIdpPostParams;

  if (serverData?.fSwitchDisambig !== undefined) {
    mappedServerData.switchDisambig = serverData.fSwitchDisambig;
  }

  if (serverData?.iAllowedIdentities) {
    mappedServerData.allowedIdentities = serverData.iAllowedIdentities;
  }

  if (serverData?.sFidoChallenge) {
    mappedServerData.fidoChallenge = serverData.sFidoChallenge;
  }

  if (serverData?.fFidoUseAllowedIdentities !== undefined) {
    mappedServerData.fidoUseAllowedIdentities = serverData.fFidoUseAllowedIdentities;
  }

  if (serverData?.arrFidoAllowList) {
    mappedServerData.fidoAllowList = serverData.arrFidoAllowList;
  }

  if (serverData?.urlLostAuthenticator) {
    mappedServerData.lostAuthenticatorUrl = serverData.urlLostAuthenticator;
  }

  if (serverData?.sResetPasswordPrefillParam) {
    mappedServerData.resetPasswordPrefillParam = serverData.sResetPasswordPrefillParam;
  }

  if (serverData?.urlLogin) {
    mappedServerData.protocolRefreshUrl = serverData.urlLogin;
  }

  if (serverData?.urlUpgradeRedirectWithUsernane) {
    mappedServerData.upgradeRedirectWithUsernameUrl = serverData.urlUpgradeRedirectWithUsernane;
  }

  if (serverData?.sRemoteConnectUserCode) {
    mappedServerData.remoteConnectUserCode = serverData.sRemoteConnectUserCode;
  }

  if (serverData?.fImprovePhoneDisambig) {
    mappedServerData.improvePhoneDisambiguation = serverData.fImprovePhoneDisambig;
  }

  if (serverData?.fFlowTokenCookieChunk) {
    mappedServerData.flowTokenCookieChunk = serverData.fFlowTokenCookieChunk;
  }

  if (serverData?.sFTCookieName) {
    mappedServerData.flowTokenCookieName = serverData.sFTCookieName;
  }

  if (serverData?.urlForget) {
    mappedServerData.forgetUrl = serverData.urlForget;
  }

  if (serverData?.urlLogout) {
    mappedServerData.logoutUrl = serverData.urlLogout;
  }

  if (serverData?.fUseForgetUserIframe) {
    mappedServerData.useForgetUserIframe = serverData.fUseForgetUserIframe;
  }

  if (serverData?.fIsRiskySignInDetected) {
    mappedServerData.riskySignIn = true;
  }

  if (serverData?.fLockUsername) {
    mappedServerData.lockUsername = true;
  }

  if (serverData?.sPOST_PaginatedLoginState) {
    mappedServerData.postedLoginState =
      parseInt(serverData.sPOST_PaginatedLoginState, 10) || LoginState.Unknown;
  }

  if (serverData?.sErrTxt) {
    mappedServerData.serverErrorText = serverData.sErrTxt;
  }

  if (serverData?.arrValErrs) {
    mappedServerData.serverValidationErrors = serverData.arrValErrs;
  }

  if (serverData?.sPOST_Username) {
    mappedServerData.postedUsername = serverData.sPOST_Username;
  }

  if (serverData?.sPrefillUsername) {
    mappedServerData.prefillUsername = serverData.sPrefillUsername;
  }

  if (serverData?.sZtdUpnHint) {
    mappedServerData.ztdUpnHint = serverData.sZtdUpnHint;
  }

  if (serverData?.fShowRemoteConnectLocationPage !== undefined) {
    mappedServerData.showRemoteConnectLocationPage = serverData.fShowRemoteConnectLocationPage;
  }

  if (serverData?.fShowPushNotifications !== undefined) {
    mappedServerData.showPushNotifications = serverData?.fShowPushNotifications;
  }

  if (serverData?.oGetCredTypeResult) {
    const unsafeUsername = htmlUnescape(
      serverData.sPOST_Username ||
        serverData.sSignInUsername ||
        serverData.sPrefillUsername ||
        serverData.sZtdUpnHint ||
        "",
    );

    const isFidoSupported = getFidoSupport(!!serverData.fIsFidoSupported);

    mappedServerData.credentialTypeResult = parseGetCredentialTypeResponse(
      serverData?.urlGoToAADError || "",
      serverData.oGetCredTypeResult,
      {
        showSignup: serverData?.fCBShowSignUp,
      },
      cleanseUsername(unsafeUsername),
      isFidoSupported,
      false,
      "",
      transformCredentialFlags,
      serverData?.iAllowedIdentities,
      serverData?.oUrlOtherIdpPostParams,
    );
  }

  if (serverData?.fHideLoginDesc !== undefined) {
    mappedServerData.hideLoginDescription = serverData.fHideLoginDesc;
  }

  if (serverData?.fUseResetPwdUrlForPwdRequiredErr !== undefined) {
    mappedServerData.useResetPasswordUrlForPasswordRequiredError =
      serverData.fUseResetPwdUrlForPwdRequiredErr;
  }

  if (serverData?.urlChangePassword) {
    mappedServerData.changePasswordUrl = serverData.urlChangePassword;
  }

  if (serverData?.fWritePasswordToPropertyBag) {
    mappedServerData.writePasswordToPropertyBag = serverData.fWritePasswordToPropertyBag;
  }

  if (serverData?.fIsScoobe) {
    mappedServerData.isScoobe = serverData.fIsScoobe;
  }

  if (serverData?.fIsTokenBroker) {
    mappedServerData.isTokenBroker = serverData.fIsTokenBroker;
  }

  if (serverData?.fIsPasskeySupportEnabled !== undefined) {
    mappedServerData.isPasskeySupported = serverData.fIsPasskeySupportEnabled;
  }

  configWrapper.initialize(mappedServerData);
}
