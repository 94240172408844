import { makeStaticStyles, makeStyles, shorthands } from "@griffel/react";

export const useStaticHipChallengeTwoUiStylesFabric = makeStaticStyles({
  "img.cssWLSPHIPAudio": {
    marginTop: "2px !important",
    marginLeft: "2px !important",
  },
});

export const useHipChallengeTwoUiStylesFabric = makeStyles({
  hipChallengeMenuButtonStyle: {
    float: "left",
    marginRight: "5px",
  },
  hipChallengeMenu: {
    display: "flex",
    justifyContent: "flex-end",
    float: "right",
  },
  hipChallengeMenuButtonStyleWithBorder: {
    float: "left",
    marginRight: "5px",
    paddingRight: "5px",
    ...shorthands.borderRight("1px", "solid", "#0000FF"),
  },
  hipChallengeNextButtonContainerStyle: {
    marginTop: "20px",
    float: "right",
  },
  hipContentStyle: {
    "& table": { display: "grid" },
    "& table tr": {
      display: "grid",
      marginBottom: "2px",
      "& input": {
        ...shorthands.borderWidth("1px !important"), // adding important was unavoidable here as the iframe renders style on the element directly.
        height: "32px",
        ":focus": {
          outlineColor: "#006fc9",
          outlineWidth: "2px",
          outlineStyle: "solid",
        },
      },
    },
    "& table td:first-child": { width: 0, display: "contents" },
  },
});

export type HipChallengeTwoUiStylesFabric = ReturnType<typeof useHipChallengeTwoUiStylesFabric>;
