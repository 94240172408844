import { makeStyles } from "@griffel/react";

export const useAgreementViewStylesFabric = makeStyles({
  layout: {
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    overflowX: "hidden",
  },
});

export type AgreementViewStylesFabric = ReturnType<typeof useAgreementViewStylesFabric>;
