import React, { useContext } from "react";
import { mergeClasses } from "@griffel/react";
import StylesConfig from "../config/styles-config";
import { LayoutTemplateType } from "../constants/constants";
import { GlobalContext } from "../global-context";

/**
 * BackgroundLogo component
 * @returns PLACEHOLDER - NEEDS TO BE UPDATED
 */
const BackgroundLogo: React.FC = function BackgroundLogo() {
  const {
    globalState: {
      styles: { backgroundLogoUrl, layoutTemplate },
    },
  } = useContext(GlobalContext);
  const { useBackgroundLogoStyles } = StylesConfig.instance;

  const logoStyles = useBackgroundLogoStyles();
  const classNames = mergeClasses(
    logoStyles.backgroundLogo,
    layoutTemplate === LayoutTemplateType.VerticalSplit ? logoStyles.verticalSplit : "",
  );

  if (!backgroundLogoUrl) {
    return null;
  }

  return (
    <img
      className={classNames}
      data-testid="backgroundLogo"
      alt="presentation"
      src={backgroundLogoUrl}
    />
  );
};

export default BackgroundLogo;
