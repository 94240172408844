import { makeStyles, shorthands } from "@griffel/react";

export const useOptionsMenuButtonStylesFabric = makeStyles({
  menu: {
    position: "absolute",
    backgroundColor: "#fff",
    backgroundClip: "paddingBox",
    ...shorthands.border("1px", "solid", "rgba(0, 0, 0, 0.1)"),
    zIndex: 2,
    top: 0,
    right: "10px",
    width: "160px",
  },
  menuButtonImageStyle: {
    backgroundColor: "transparent",
    ...shorthands.borderWidth("0px"),
    cursor: "pointer",
    minWidth: "fit-content",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  accessibleImageAlign: {
    verticalAlign: "middle",
  },
  menuMargin: {
    ...shorthands.margin("0px"),
  },
  menuLink: {
    ...shorthands.borderWidth("0px"),
    textAlign: "left",
    width: "100%",
    display: "block",
    paddingTop: "11px",
    paddingBottom: "13px",
    paddingLeft: "12px",
    paddingRight: "12px",
    backgroundColor: "rgba(0,0,0,.05)",
    color: "inherit",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#e6e6e6;",
      cursor: "pointer",
      color: "inherit",
    },
    ":focus": {
      backgroundColor: "rgba(0,0,0,.1)",
      outlineWidth: "1px",
      outlineColor: "#000",
      outlineStyle: "dashed",
    },
    ":active": {
      backgroundColor: "rgba(0,0,0,.3)",
    },
  },
});

export type OptionsMenuButtonStylesFabric = ReturnType<typeof useOptionsMenuButtonStylesFabric>;
