import { type Flavors } from "../constants";
import { FlavorToStylesConfigMap, StylesConfigFabric } from "../styles/constants.styles";
import { ConfigWrapper } from "../utilities/config-wrapper";

export const defaultStylesConfig = { ...StylesConfigFabric }; // Defaults to fabric styles pre-initialization
const stylesConfig = new ConfigWrapper(defaultStylesConfig);

/**
 * The config wrapper that should be used to access the styles config properties
 */
export default stylesConfig;

/**
 * Initialize the styles config object from ServerData. This function should be called once per App, outside
 * of the component render cycle.
 * @param flavor The flavor of the active app
 */
export function initStylesConfig(flavor: Flavors) {
  const mappedFlavorStyles: Partial<typeof defaultStylesConfig> =
    FlavorToStylesConfigMap.get(flavor) ?? defaultStylesConfig;

  stylesConfig.initialize(mappedFlavorStyles);
}
