import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { mergeClasses } from "@griffel/react";
import {
  TextButtonContainer,
  TextButtonFabric,
} from "../../components/text-button/fabric/text-button-fabric";
import StylesConfig from "../../config/styles-config";
import { ViewId } from "../../constants/routing-constants";
import GlobalConfig from "../../global-config";
import { GlobalContext } from "../../global-context";
import { GlobalActionType } from "../../global-reducer";
import { useActivateView } from "../../hooks/use-activate-view";
import { useNavigateDirection } from "../../hooks/use-navigate-direction";
import { FOCUS_TIMEOUT } from "../../styles/fabric/layout-animate-fabric.styles";
import { useTelemetryState } from "../../telemetry-helpers/use-telemetry-state";
import { getText } from "../../utilities/request/request-helper";
import { htmlEscape, replaceTokens } from "../../utilities/strings-helper";
import {
  type AgreementViewProps,
  type LinkAgreementState,
  AgreementType,
} from "../agreement-view-interface";
import { agreementViewStringsFabric } from "./agreement-view-strings-fabric";

/**
 * A shared view that displays the agreement text for hosted screens. Accessed via a link on the lightbox-footer component.
 * @flavor Fabric
 * @param props The props for this component
 * @param props.hostingFlow The flow that this agreement view is hosted in
 * @returns A rendered instance of this shared view
 */
const AgreementViewFabric: React.FC<AgreementViewProps> = function AgreementViewFabric({
  hostingFlow,
}) {
  const {
    footerImpressumUrl,
    footerA11yConformeUrl,
    hideTopViewAgreementBackButton,
    privacyLinkExternal,
    termsOfUseLinkExternal,
  } = GlobalConfig.instance;
  const {
    dispatchStateChange: dispatchGlobal,
    globalState: {
      styles: { termsOfUseUrl, privacyUrl },
    },
  } = useContext(GlobalContext);

  const agreementDiv = "idDiv_iAgreement";

  const { generalVisitLink, genericErrorNoResponseTryAgain, generalBack } =
    agreementViewStringsFabric;

  const { useAgreementViewStyles, useCommonStyles, useTextButtonContainerStyles } =
    StylesConfig.instance;
  const commonStyles = useCommonStyles();
  const agreementViewStyles = useAgreementViewStyles();
  const { agreementLayout } = useTextButtonContainerStyles();

  const location = useLocation();
  const navigate = useNavigateDirection();

  const [downloadError, setDownloadError] = useState(false);
  const [agreementHtml, setAgreementHtml] = useState("");

  const telemetryState = useTelemetryState();

  const bottomButtonReference = useRef<HTMLButtonElement>(null);
  const topButtonReference = useRef<HTMLButtonElement>(null);

  const linkAgreementState = location.state as LinkAgreementState;
  const agreementType = linkAgreementState?.agreementType;

  const isAgreementLinkExternal = !!(
    (agreementType === AgreementType.Impressum && footerImpressumUrl) ||
    (agreementType === AgreementType.A11yConforme && footerA11yConformeUrl) ||
    (agreementType === AgreementType.Privacy && privacyLinkExternal) ||
    (agreementType === AgreementType.TermsOfUse && termsOfUseLinkExternal)
  );

  const hideTopButton = isAgreementLinkExternal || hideTopViewAgreementBackButton;

  // Navigate back using the stored prevPath instead of using "-1" to account for scenarios where user is navigating within the same view/route
  // (e.g., going from TOU to Privacy). The prevPath is the current non-agreement view.
  const onBack = () => {
    navigate(ViewId.ViewAgreement, linkAgreementState.prevPath, true, {
      state: linkAgreementState,
    });
  };

  const buildAccessibleHtml = (text: string): string => {
    const result = text.trim();
    const htmlpTagFirst = '<p id="agreementTitle" tabindex="-1">';
    const htmlpTagOpen = '<p tabindex="-1">';
    const htmlpTagClose = "</p>";
    const newLineRegex = /(?:\r\n|\r|\n)/g;
    const emptyParagraphRegex = new RegExp(htmlpTagOpen + htmlpTagClose, "g");
    // Wrap agreement text in <p id="agreementTitle">...</p>
    const baseHtml = htmlpTagFirst + result + htmlpTagClose;
    // Replace newline characters with </p><p tabindex="-1">
    const withNewline = baseHtml.replace(newLineRegex, htmlpTagClose + htmlpTagOpen);
    // Remove any empty paragraphs
    const htmlResult = withNewline.replace(emptyParagraphRegex, "");

    return htmlResult;
  };

  const fetchAgreementText = useCallback(async () => {
    try {
      const processingStartTime = new Date();
      const url = agreementType === AgreementType.Privacy ? privacyUrl : termsOfUseUrl;
      const responseText = await getText(url, {
        processingStartTime,
        telemetryState,
      });
      const htmlText = buildAccessibleHtml(responseText);
      setAgreementHtml(htmlText);
    } catch {
      setDownloadError(true);
    }
  }, [privacyUrl, termsOfUseUrl, agreementType, telemetryState]);

  const getAgreementHtml = useCallback((): void => {
    const getExternalLink = (): string => {
      switch (agreementType) {
        case AgreementType.Impressum:
          return footerImpressumUrl;
        case AgreementType.A11yConforme:
          return footerA11yConformeUrl;
        case AgreementType.Privacy:
          return privacyUrl;
        case AgreementType.TermsOfUse:
          return termsOfUseUrl;
        default:
          return "";
      }
    };

    // If an external link is present for Privacy or Terms of Use, or if the agreement link
    // is for Impressum or A11yConforme/French Decree, then show a string with the agreement link
    if (isAgreementLinkExternal) {
      const externalLink = getExternalLink();
      const formattedText = replaceTokens(generalVisitLink, htmlEscape(externalLink));
      const result = buildAccessibleHtml(formattedText);
      setAgreementHtml(result);
    } else {
      // Make a request for the agreement text file if the hosted url link is present for Terms of Use or Privacy
      fetchAgreementText();
    }

    dispatchGlobal({
      type: GlobalActionType.DataLoaded,
      view: ViewId.ViewAgreement,
    });
  }, [
    agreementType,
    footerA11yConformeUrl,
    footerImpressumUrl,
    privacyUrl,
    termsOfUseUrl,
    dispatchGlobal,
    fetchAgreementText,
    isAgreementLinkExternal,
    generalVisitLink,
  ]);

  useActivateView(ViewId.ViewAgreement, hostingFlow, {
    showIdentityBanner: false,
    isWideView: true,
    loadingData: true,
  });

  useEffect(() => {
    // Reset the view component to its default values and reset focus on the button. This is necessary because the agreements share the same route via the react-router link component.
    // If a user navigates between agreements or within same agreement, react-router does not unmount the component and mount a new instance.
    setDownloadError(false);
    setAgreementHtml("");
    setTimeout(() => {
      const elementRef = hideTopButton
        ? bottomButtonReference?.current
        : topButtonReference?.current;
      elementRef?.focus();
    }, FOCUS_TIMEOUT);
    getAgreementHtml();
  }, [getAgreementHtml, linkAgreementState, hideTopButton]);

  return (
    <div className={commonStyles.section} data-testid="agreements">
      {downloadError && (
        <div className={mergeClasses(commonStyles.row, commonStyles.textBody)}>
          <div
            id="error"
            role="alert"
            aria-live="assertive"
            aria-relevant="text"
            aria-atomic="true"
          >
            {genericErrorNoResponseTryAgain}
          </div>
        </div>
      )}
      {!hideTopButton && (
        <TextButtonContainer customCss={agreementLayout}>
          <TextButtonFabric
            label={generalBack}
            onClick={onBack}
            hasFocus
            ariaDescribedBy={agreementDiv}
            ref={topButtonReference}
          />
        </TextButtonContainer>
      )}
      {agreementHtml && (
        <>
          <pre
            className={mergeClasses(
              commonStyles.textBody,
              commonStyles.textBlockBody,
              agreementViewStyles.layout,
            )}
            id={agreementDiv}
            role="alert"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: agreementHtml }}
          />
          <TextButtonContainer customCss={hideTopButton ? "" : agreementLayout}>
            <TextButtonFabric
              label={generalBack}
              onClick={onBack}
              ariaDescribedBy={agreementDiv}
              hasFocus={hideTopButton}
              buttonId="viewAgreementBack"
              ref={bottomButtonReference}
            />
          </TextButtonContainer>
        </>
      )}
    </div>
  );
};

export default AgreementViewFabric;
