import { makeStyles, shorthands } from "@griffel/react";
import { MOBILE_LAYOUT_SIZE } from "./constants-fabric.styles";

export const useBackgroundLogoStylesFabric = makeStyles({
  backgroundLogo: {
    maxWidth: "256px",
    maxHeight: "36px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "24px",
    animationDuration: "1s",
    animationName: {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },

    [MOBILE_LAYOUT_SIZE]: {
      marginTop: "24px",
    },
  },

  verticalSplit: {
    marginTop: "44px",
    ...shorthands.padding(0, "44px"),

    [MOBILE_LAYOUT_SIZE]: {
      ...shorthands.padding(0),
    },
  },
});

export type BackgroundLogoStylesFabric = ReturnType<typeof useBackgroundLogoStylesFabric>;
