import { HighContrastTheme } from "../constants/constants";

let ieVersionCache: number;

/**
 * isSvgSupported function
 * @returns True if the browser supports SVG, false if not
 */
/* eslint-disable compat/compat */
export const isSvgSupported =
  !!document.createElementNS &&
  !!document.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGRect;
/* eslint-enable compat/compat */

/**
 * Check if CSS Animation is supported
 * @returns True if the browser supports CSS animation, false if not
 */
export const isCssAnimationSupported = (): boolean => {
  const prefixes = ["Webkit", "Moz", "O"];
  const elem = document.createElement("div");

  // Check if browser supports animation without any prefixes. If it does, then animation will be supported without any prefixes.
  let supported = elem.style.animationName !== undefined;

  // If browser does not support non-prefixed animations, iterate over prefixes and check if it's set with the AnimationName property instead.
  if (!supported) {
    const supportedPrefix = prefixes.find(
      // allow any here because the property name is dynamically constructed
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (prefix: string) => elem.style[`${prefix}AnimationName` as any] !== undefined,
    );

    supported = !!supportedPrefix;
  }

  return supported;
};

export const isEdgeClientBrowser = (): boolean =>
  navigator.userAgent.toLowerCase().indexOf("edgeclient/") > -1;

/**
 * Check if history API is supported on the browser (https://developer.mozilla.org/en-US/docs/Web/API/History_API).
 * This function is used when handling the browser history. We want to ensure that a pop state event (navigating history)
 * is only fired when history is supported.
 * (Inspired from KO function isHistorySupported in BrowserControl.js- line 374)
 * @returns if history API is supported on browser
 */
export const isHistorySupported = (): boolean => {
  const dummyState = "__history_test";
  let historySupported = false;
  if (window) {
    historySupported =
      window.history &&
      typeof window.history.state !== "undefined" &&
      typeof window.onpopstate !== "undefined";
  }

  if (historySupported) {
    try {
      window.history.replaceState(dummyState, "");
      if (window.history.state !== dummyState) {
        // In Android 4.4, the HTML5 History API exists, but calling into does nothing and throws no exception
        historySupported = false;
      } else if (isEdgeClientBrowser()) {
        historySupported = false;
      }
    } catch (e) {
      // In some hosts (WinZip), the HTML5 History API exists, but calling into it barfs.
      historySupported = false;
    }
  }

  return historySupported;
};

/**
 * Convert rgb or rbga values to hex.
 * @param rgba String equivalent of an rgb or rgba color.
 * @returns Hex equivalent of the value supplied; if a hex value is supplied, it's unchanged.
 * If an empty, null, or improper value is supplied, an empty string is returned.
 */
export const rgbaToHex = (rgba: string) => {
  if (!rgba) {
    return "";
  }

  // If we already have hex, return unchanged.
  if (rgba.toUpperCase().match(/^#(([0-9A-F]{3}){1,2})|([0-9A-F]{8})/)) {
    return rgba;
  }

  // If not rgb or rgba string, return empty string.
  if (!rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/)!) {
    return "";
  }

  // Extract rgb/rgba numerical values from string and map to array
  // convert opacity to bits for hex conversion
  // convert decimal to hex (radix 16)
  // drop "NaN" for rgb values (no opacity)
  // add hash and concatenate individual hex values
  return `#${rgba
    .match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/)!
    .slice(1)
    .map((n: string, i: number) =>
      (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n))
        .toString(16)
        .padStart(2, "0")
        .replace("NaN", ""),
    )
    .join("")}`;
};

/**
 * Check if high contrast mode is enabled
 * @returns True if the high contrast mode is enabled, false if not
 */
/* istanbul ignore next */
export const isHighContrast = () => {
  const body = document.getElementsByTagName("body")[0];
  const span = document.createElement("span");
  span.style.borderLeftColor = "red";
  span.style.borderRightColor = "blue";
  span.style.position = "absolute";
  span.style.top = "-999px";
  body.appendChild(span);

  const style = window.getComputedStyle(span);
  const isHighContrastActive = style.borderLeftColor === style.borderRightColor;

  body.removeChild(span);
  return isHighContrastActive;
};

/**
 * Determine if the high contrast theme is light or dark
 * @returns empty string if high contrast is not enabled; "light" or "dark" based on the Windows high contrast theme inferred from the background color.
 */
export const getHighContrastTheme = (): string => {
  let hcTheme = HighContrastTheme.none;

  if (isHighContrast()) {
    const body = document.getElementsByTagName("body")[0];
    const style = window.getComputedStyle(body);
    let bgColor = rgbaToHex(style.backgroundColor.toLowerCase().replace(/ /g, ""));

    if (bgColor) {
      // Windows Aquatic theme uses rgb(32,32,32)/#202020
      // Windows Dusk theme uses rgb(45,50,54)/#2d3236
      // Windows Night sky and older high contrast dark use black
      const darkThemes = ["#000000", "#000", "#202020", "#2d3236"];

      // trim opacity value if present in hex
      bgColor = bgColor.length === 7 ? bgColor : bgColor.slice(0, -2);

      if (darkThemes.includes(bgColor)) {
        hcTheme = HighContrastTheme.dark;
      }

      // Windows Desert theme uses rgb(255,250,239)/#fffaef
      // Windows older default high contrast light uses white
      const lightThemes = ["#ffffff", "#fff", "#fffaef"];

      if (lightThemes.includes(bgColor)) {
        hcTheme = HighContrastTheme.light;
      }
    }
  }

  return hcTheme;
};

/**
 * Gets the IE version if the browser is IE
 * @returns IE version if on IE, else returns -1
 */
export const getIEVersion = (): number => {
  // http://codepen.io/gapcode/pen/vEJNZN/
  const ua = window.navigator.userAgent;

  // Example string: Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.1; WOW64; Trident/6.0)
  const msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  }

  // Example string: Mozilla/5.0 (Windows NT 6.1; WOW64; Trident/7.0; AS; rv:11.0) like Gecko
  const trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf("rv:");
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
  }

  // Reference on the Edge-specific tokens in the user agent string:
  // https://learn.microsoft.com/en-us/microsoft-edge/web-platform/user-agent-guidance#user-agent-strings
  // Example string: Mozilla/5.0 Chrome/107.0.0.0 Mobile Safari/537.36 Edge/40.15254.603
  const legacyEdge = ua.indexOf("Edge/");
  if (legacyEdge > 0) {
    // IE 12 => return version number
    return parseInt(ua.substring(legacyEdge + 5, ua.indexOf(".", legacyEdge)), 10);
  }

  // Example string: Mozilla/5.0 Chrome/115.0.0.0 Safari/537.36 Edg/115.0.1901.183
  const edge = ua.indexOf("Edg/");
  if (edge > 0) {
    // IE 12 => return version number
    return parseInt(ua.substring(edge + 4, ua.indexOf(".", edge)), 10);
  }

  return -1;
};

/**
 * Check browser settings to see if fido is supported
 * @param isFidoSupportedHint fallback value from server if client cannot decide whether fido is supported
 * @returns flag that indicates whether fido is supported
 */
export const getFidoSupport = (isFidoSupportedHint: boolean): boolean => {
  let windowCredentials;
  let publicKeyCredential;

  if (window.navigator.credentials) {
    windowCredentials = window.navigator.credentials;
  }

  if (window.PublicKeyCredential) {
    publicKeyCredential = window.PublicKeyCredential;
  }

  const supportsStandard =
    windowCredentials !== undefined &&
    windowCredentials.create !== undefined &&
    windowCredentials.get !== undefined &&
    publicKeyCredential !== undefined &&
    publicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable !== undefined;

  if (!supportsStandard) {
    return false;
  }

  // only standard API is supported, so let server decide if FIDO2 is supported or not
  return isFidoSupportedHint;
};

/**
 * @returns flag that indicates whether user's platform has the necessary authenticator
 */
export const isPlatformAuthenticatorAvailable = async (): Promise<boolean> => {
  if (window.PublicKeyCredential) {
    return window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
  }

  return false;
};

/**
 * @param version the IE version threshold to check for
 * @returns flag that indicates whether the IE version is newer than or the same version as the input value if the browser is IE.
 */
export const isIENewerThan = (version: number): boolean => {
  if (ieVersionCache === undefined) {
    ieVersionCache = getIEVersion();
  }

  return ieVersionCache !== -1 && ieVersionCache >= version;
};

/**
 * @returns flag that indicates whether the browser version is IE10 or newer. This informs us if certain features are available such as
 *     - changing the `type` attribute of input elements
 *     - CSS pseudo-elements like ::-ms-reveal, ::-ms-check etc.
 */
export const isIENewerThan10 = (): boolean => isIENewerThan(10);

/**
 * Gets keyboard-focusable elements within a specified element
 * Stolen and modified from: https://zellwk.com/blog/keyboard-focusable-elements/
 * @param {HTMLElement} element - element to search within
 * @returns {HTMLElement[]} keyboard-focusable elements
 */
export const getKeyboardFocusableElements = function getKeyboardFocusableElements(
  element: HTMLElement,
) {
  const focusableElements = element.querySelectorAll(
    'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])',
  );
  const filteredElements: HTMLElement[] = [];
  focusableElements.forEach((el) => {
    if (!el.hasAttribute("disabled") && !el.getAttribute("aria-hidden")) {
      filteredElements.push(el as HTMLElement);
    }
  });
  return filteredElements;
};
