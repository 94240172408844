import React from "react";
import { DescriptionFabric } from "@msidentity/SISU/components/description/fabric/description-fabric";
import { TitleFabric } from "@msidentity/SISU/components/title/fabric/title-fabric";
import { FlowId, ViewId } from "@msidentity/SISU/constants/routing-constants";
import { useActivateView } from "@msidentity/SISU/hooks/use-activate-view";
import { useDocumentTitle } from "@msidentity/SISU/hooks/use-document-title";
import SignUpConfig from "../../../signup-config";

/**
 * UsernameView component
 * @returns A rendered instance of this component
 */
export const UsernameViewFabric: React.FC = function UsernameViewFabric() {
  const { signUpDescription } = SignUpConfig.instance;

  useActivateView(ViewId.SignUp, FlowId.Signup);

  const title = getLocalString("Signup_Title");
  useDocumentTitle(title);

  // TODO: implement the rest of the Username View component
  return (
    <>
      <TitleFabric titleId="usernameViewTitle" title={title} />
      <DescriptionFabric descriptionId="usernameViewDescription">
        {signUpDescription}
      </DescriptionFabric>
    </>
  );
};
