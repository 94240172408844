import { type Flavors } from "@msidentity/SISU/constants";
import { initGlobalConfig } from "@msidentity/SISU/global-config";
import { type GlobalState, createGlobalState } from "@msidentity/SISU/global-context";
import { type ServerData } from "@msidentity/SISU/utilities/server-data";
import { initSignUpConfig } from "../../flows/signup/signup-config";
import { type SignUpState, createSignUpState } from "../../flows/signup/signup-context";

/**
 * Parse the ServerData object sent from Account and return the initial config/state for the app contexts.
 * IMPORTANT: This should be called at module scope, NOT inside the render function!!
 * @param serverData The ServerData object sent from the Account server and read from the window object.
 * @param activeFlavor The rendered flavor
 * @returns The initial state for the global and signup contexts.
 */
export function initSignUpFromServerData(
  serverData: ServerData,
  activeFlavor: Flavors,
): {
  initialGlobalState: GlobalState;
  initialSignUpState: SignUpState;
} {
  // Initialize global config
  initGlobalConfig(serverData);

  // Initialize signup config
  initSignUpConfig(serverData);

  const initialGlobalState = createGlobalState(serverData, activeFlavor);
  const initialSignUpState = createSignUpState(serverData);

  return {
    initialGlobalState,
    initialSignUpState,
  };
}
