import React from "react";
import { FormattedMessage, IntlProvider } from "react-intl";

export interface ITextWithBindingsProps {
  /* The string containing the placeholder bindings */
  textWithBindings: string;
  /* The bindings that should replace the placeholders
   * Example
   *
   * 1. Update the base resx string as a separate PR (Note it's not recommended to insert breaks and lists into live strings, just an illustration)
   *  <name>Test_String</name>
   *  <value>A sample string.with an &lt;myLink&gt;embedded link&lt;/myLink&gt; and &lt;boldText&gt;bold text &lt;/boldText&gt; then: &lt;lineBreak&gt;&lt;/lineBreak&gt; 1. List item one. &lt;lineBreak&gt;&lt;/lineBreak&gt; 2. List item two.</value>
   *
   * 2. Retrieve the test string in code.
   *  const theString = getLocalString("Test_String");
   *
   * 3. Define the replacement elements, matching the name to the element name being replaced. Note that "chunks" will contain the
   *  text that should be inserted back into the element.
   *  Include any attributes required for the embedded element (aria-label, id, target, onClick, etc.)
   *
   *  const myLink = (chunks) => <a href={theUrlVar} target="_blank" rel="noreferrer noopener">{chunks}</a>;
   *  const boldText = (chunks: any) => <span id="testId" className={boldFormatClassName}>{chunks}</span>;
   *  const {lineBreak} = commonBindings;
   *
   * 4. Pass string and bindings as props
   *    textWithBindings={theString}
   *    embeddedBindings={{ myLink, boldText, lineBreak }}
   */
  embeddedBindings: {};
}

/**
 * @param props ITextWithBindingsProps
 * @returns Component wrapper for strings that have embedded bindings used to alter a portion
 * of the string such as links, css, etc.
 */
export const FormattedTextWithBindings: React.FC<ITextWithBindingsProps> =
  function FormattedTextWithBindings(props) {
    const { textWithBindings, embeddedBindings } = props;

    return (
      <IntlProvider locale="en" defaultLocale="en">
        <FormattedMessage
          id="not_used"
          defaultMessage={textWithBindings}
          values={embeddedBindings}
        />
      </IntlProvider>
    );
  };

export const commonBindings = {
  italicizedText: (text: string) => <i>{text}</i>,
  boldText: (text: string) => <b>{text}</b>,
  lineBreak: () => <br />,
  registeredTrademark: () => <span>&#174;</span>,
  trademark: () => <span>&#8482;</span>,
};
