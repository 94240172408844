import { useEffect } from "react";

export const useCloseOnOutsideClick = (
  menuRef: React.RefObject<HTMLInputElement>,
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  useEffect(() => {
    const handleClickOutside = (ev: Event) => {
      if (menuRef.current && !menuRef.current.contains(ev.target as Node)) {
        setOpenMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    // long-term, focus should not leave the menu pop-up unless esc is pressed
    document.addEventListener("focusin", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("focusin", handleClickOutside);
    };
  }, [menuRef, setOpenMenu]);
};
