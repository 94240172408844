import { makeStyles } from "@griffel/react";
import * as styleConstants from "./constants-fabric.styles";

export const useBannerLogoStylesFabric = makeStyles({
  bannerLogo: {
    maxHeight: "36px",
  },

  bannerLogoText: {
    fontFamily: "Verdana",
    fontSize: "16px",
    color: "#181818",
    wordWrap: "break-word",
  },

  logo: {
    maxWidth: "256px",
    height: `${styleConstants.LOGO_HEIGHT}px`,
  },
});

export type BannerLogoStylesFabric = ReturnType<typeof useBannerLogoStylesFabric>;
