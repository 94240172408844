import { useContext, useEffect, useState } from "react";
import { FlowId, ViewId } from "../constants";
import GlobalConfig from "../global-config";
import { GlobalContext } from "../global-context";
import { ExceptionHelper } from "../telemetry-helpers/exception-helper";
import { PerfHelper } from "../telemetry-helpers/perf-helper";
import { getStandardDimensions, TelemetryEventType } from "../telemetry-helpers/telemetry-helper";
import { useTelemetryState } from "../telemetry-helpers/use-telemetry-state";

export const useLoadTelemetry = (loadEventFunc?: () => void) => {
  const { telemetry } = GlobalConfig.instance;
  const {
    globalState: { activeFlavor },
  } = useContext(GlobalContext);

  const [isPltEventFired, setIsPltEventFired] = useState(false);
  const telemetryState = useTelemetryState();

  useEffect(() => {
    /** @internal */
    function logPltEvent() {
      const { view, flow } = telemetryState.dimensions;

      if (!isPltEventFired && (view !== ViewId.None || flow !== FlowId.None)) {
        /* We need to wait until the window's onLoad event handler complete so that the data
         * in PerformanceTiming is fully populated, so we schedule this to run 100ms after
         * the event handler ends.
         */
        setTimeout(
          () =>
            telemetry.addEvent({
              _table: TelemetryEventType.Plt,
              ...PerfHelper.getPerformanceMetrics(),
              dimensions: getStandardDimensions({
                activeView: view!,
                activeFlow: flow!,
                activeFlavor,
              }),
            }),
          100,
        );
        setIsPltEventFired(true);
      }
    }

    /** @private */
    function errorEventHandler(event: ErrorEvent) {
      if (!ExceptionHelper.isErrorLogged(event)) {
        ExceptionHelper.logException(event, telemetryState);
      }
    }

    /** @private */
    const loadEventHandler = () => {
      logPltEvent();
      loadEventFunc?.();
    };

    window.addEventListener("error", errorEventHandler);

    if (document.readyState === "complete") {
      loadEventHandler();
    } else {
      window.addEventListener("load", loadEventHandler);
    }

    return () => {
      window.removeEventListener("load", loadEventHandler);
      window.removeEventListener("error", errorEventHandler);
    };
  }, [telemetry, telemetryState, isPltEventFired, activeFlavor, loadEventFunc]);
};
