// alphabetically sorted with None at the top
export enum ViewId {
  None = "None",
  AccountPicker = "AccountPicker",
  AccountTransferError = "AccountTransferError",
  AccountTransferOtc = "AccountTransferOtc",
  AccountTransferQrCode = "AccountTransferQrCode",
  AcwAddEmail = "AcwAddEmail",
  AcwAddPhone = "AcwAddPhone",
  AddPassword = "AddPassword",
  AddVerificationCode = "AddVerificationCode",
  AliasAccrualCollect = "AliasAccrualCollect",
  AliasAccrualCollectPassword = "AliasAccrualCollectPassword",
  AliasAccrualEvictionInfo = "AliasAccrualEvictionInfo",
  AliasAccrualSuggestions = "AliasAccrualSuggestions",
  AliasAccrualVerify = "AliasAccrualVerify",
  AppStoreRedirect = "AppStoreRedirect",
  ConfirmSend = "ConfirmSend",
  Challenge = "Challenge",
  ContactEmail = "ContactEmail",
  CredentialPicker = "CredentialPicker",
  EnterRecoveryCode = "EnterRecoveryCode",
  Error = "Error",
  FetchSessionsProgress = "FetchSessionsProgress",
  Fido = "Fido",
  IdpDisambiguation = "IdpDisambiguation",
  IdpRedirect = "IdpRedirect",
  IdpRedirectSpeedbump = "IdpRedirectSpeedbump",
  Kmsi = "Kmsi",
  NewPassword = "NewPassword",
  OneTimeCode = "OneTimeCode",
  OwnershipChallenge = "OwnershipChallenge",
  Password = "Password",
  PhoneDisambiguation = "PhoneDisambiguation",
  ProofConfirmation = "ProofConfirmation",
  RecoveryCode = "RecoveryCode",
  StartWaitFlow = "StartWaitFlow",
  RemoteConnectCanaryValidation = "RemoteConnectCanaryValidation",
  RemoteLoginPolling = "RemoteLoginPolling",
  RemoteNgc = "RemoteNgc",
  ResetPassword = "ResetPassword",
  ResetPasswordSignInName = "ResetPwdSignInName",
  ResetPasswordSuccess = "ResetPasswordSuccess",
  ResetPasswordProofPicker = "ResetPasswordProofPicker",
  SecurityInfoUpdated = "SecurityInfoUpdated",
  ServiceAbuseLanding = "ServiceAbuseLanding",
  ServiceAbusePhoneNumberCollection = "ServiceAbusePhoneNumberCollection",
  SetSinglePassword = "SetSinglePassword",
  SetupAuthenticator = "SetupAuthenticator",
  SignInOptions = "SignInOptions",
  SignUp = "SignUp",
  SkypeGoodNews = "SkypeGoodNews",
  SsoConsent = "SsoConsent",
  SubmitRequest = "SubmitRequest",
  TransferLogin = "TransferLogin",
  Username = "Username",
  ValidateAccount = "ValidateAccount",
  VerifyIdentity = "VerifyIdentity",
  ViewAgreement = "ViewAgreement",
  WaitFlow = "WaitFlow",
}

export enum FlowId {
  None = "None",
  AccountTransfer = "AccountTransfer",
  Acw = "Acw",
  AliasAccrual = "AliasAccrual",
  AppStoreRedirect = "AppStoreRedirect",
  Kmsi = "Kmsi",
  Login = "Login",
  OwnershipChallenge = "OwnershipChallenge",
  ResetPassword = "ResetPassword",
  ResetPasswordSignInName = "ResetPasswordSignInName",
  ServiceAbuse = "ServiceAbuse",
  Signup = "Signup",
  SsoConsent = "SsoConsent",
  TransferLogin = "TransferLogin",
}
