import { type CXHType } from "./cxh-handler-types";

/**
 * Imports the CXH js file. The purpose of this function is so that we can load CXH asynchorounsly.
 * Instead of calling CXH on intial load, we only call this function/import CXH in scenarios
 * where it is needed
 */
export const getCxh = async function getCxh() {
  // @ts-ignore
  const { CXH } = await import("../../js/cxh/cxh");
  return CXH as CXHType;
};

/**
 * This method is meant to be provided by for the SSOConsentView for Win10Host on MSA, which we are calling WindowsFabric.
 * It will prevent the form submission and instead call the CXH API to cancel the login flow.
 */
export const cancelLogin = async () => {
  const CXH = await getCxh();
  CXH.setAuthErrorAndComplete(CXH.ClientHRs.UserCanceled, CXH.ServerErrors.LoginCanceled);
};

/**
 * This method clears the userpassword from the property bag from previous requests
 */
export const handleClearPassword = async () => {
  const CXH = await getCxh();

  CXH.propertyBagSetAsync(CXH.PropertyBagItemNames.EncryptedPassword);
};

/**
 * Encrypts the provided password and sets encrypted value in the public property bag
 * @param newPassword password to encrypt
 * @param writePasswordToPropertyBag server data property writePasswordToPropertyBag
 * @param onSuccessHandler on success handler
 */
export const handleOnPasswordUpdate = async (
  newPassword: string,
  writePasswordToPropertyBag: boolean,
  onSuccessHandler: () => void,
) => {
  const CXH = await getCxh();
  if (writePasswordToPropertyBag) {
    CXH.encryptUserDataAsync(newPassword, (valueEncrypted: string) => {
      CXH.propertyBagSetAsync(
        CXH.PropertyBagItemNames.EncryptedPassword,
        valueEncrypted,
        onSuccessHandler,
      );
    });
  }
};

/**
 * Handles back button event either through a provided back event or through CXH
 * @param onBackButton Handler for back button
 */
export const handleBackButtonEvent = async (onBackButton?: () => {}) => {
  const CXH = await getCxh();

  if (onBackButton) {
    CXH.showBackButton(true);
    CXH.handleBackButton(onBackButton);
  } else {
    CXH.showBackButton(false);
  }
};

/**
 * Give CXH a hint that we are now navigating to a third party website, so that it can handle the back button sanely.
 */
export const setBackNavigationCheckpointBeforeRedirect = async () => {
  const CXH = await getCxh();

  CXH.setBackNavigationCheckpoint();
};

/**
 * Show the given Lottie file for Win11 OOBE.
 * @param graphicAnimationSource Lottie file to be shown alongside the webapp in OOBE
 */
export const showGraphicAnimationFromSource = async (graphicAnimationSource: string) => {
  const CXH = await getCxh();
  CXH.showGraphicAnimation(graphicAnimationSource);
};

/**
 * Sets a value in the volatile property bag using the provided name asynchronously.
 */
export const handleOobeMsaConsentDisplayed = async () => {
  const CXH = await getCxh();

  CXH.volatilePropertyBagSetAsync(
    "Identity",
    CXH.PropertyBagItemNames.WasMsaConsentDisplayed,
    "true",
  );
};
