import { makeStyles, shorthands } from "@griffel/react";
import * as styleConstants from "./constants-fabric.styles";

export const useIdentityBannerStylesFabric = makeStyles({
  identityBanner: {
    height: `${styleConstants.IDENTITY_BANNER_HEIGHT}px`,
    ...shorthands.margin(
      `${styleConstants.FORM_GROUP_MARGIN}px`,
      0,
      `${styleConstants.FORM_GROUP_MARGIN}px`,
    ),
    lineHeight: `${styleConstants.IDENTITY_BANNER_HEIGHT}px`,
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
  },
});

export type IdentityBannerStylesFabric = ReturnType<typeof useIdentityBannerStylesFabric>;
