import { makeStyles, shorthands } from "@griffel/react";

export const useLinkButtonStylesFabric = makeStyles({
  link: {
    color: "#0067b8",
    "text-decoration": "none",
    backgroundColor: "transparent",
    ...shorthands.borderStyle("none"),
    ":hover": {
      "text-decoration": "underline",
      color: "#666",
      backgroundColor: "transparent",
      ...shorthands.borderStyle("none"),
      cursor: "pointer",
    },
  },
  noPadding: {
    // do not inherit the native button padding
    ...shorthands.padding("0px"),
  },
  anchorNoFocus: {
    fontSize: "0.85rem",
    ":hover": {
      color: "#0067b8",
    },
  },

  /*
  The color property is automatically adjusted when high contrast is enabled, which means that the link button will receive the system theme for text when high contrast is enabled.
  We need to instead set the color to the system theme for link text, so that it can continue to look like a link.
  */
  highContrast: {
    /* Microsoft Edge and web standards implementations of high contrast support */
    "@media (forced-colors: active)": {
      color: "LinkText",
    },
    /* Legacy implementations of high contrast support (IE and Microsoft Edge Legacy) */
    "@media (-ms-high-contrast: active)": {
      color: "-ms-hotlight",
    },
  },
});

export type LinkButtonStylesFabric = ReturnType<typeof useLinkButtonStylesFabric>;
