import { type HistoryState } from "./history-context";

/**
 * Action types that are accepted by the history reducer
 */
export enum HistoryActionType {
  SetHistoryIndexAction,
}

type SetHistoryIndexAction = {
  type: HistoryActionType.SetHistoryIndexAction;
  payload: number;
};

/**
 * Union of all actions that can be dispatched to the history reducer to update history state
 */
export type HistoryActions = SetHistoryIndexAction;

/**
 * History state reducer
 * @param state The current state
 * @param action The action to perform on the current state
 * @returns The new state
 */
export default function historyReducer(state: HistoryState, action: HistoryActions): HistoryState {
  switch (action.type) {
    case HistoryActionType.SetHistoryIndexAction:
      return { ...state, historyIndex: action.payload };

    default:
      throw new Error(`HistoryReducer received unexpected action ${action.type}`);
  }
}
