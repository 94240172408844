import React from "react";
import { type IButtonProps } from "./button/button-interface";
import { ButtonFabric } from "./button/fabric/button-fabric";
import { type IButtonFabricProps } from "./button/fabric/button-fabric-interface";
import { type IAccessibleImageProps, AccessibleImage } from "./accessible-image";

type IImageButtonProps = IButtonProps &
  IButtonFabricProps & {
    /** The props for the accessible image */
    accessibleImageProps: IAccessibleImageProps;
  };

/**
 * Image Button component
 * @param imageButtonProps The properties for this component
 * @returns an instance of the shared image button component
 */
export const ImageButton = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<IImageButtonProps>
>((props, forwardRef) => {
  const { children, accessibleImageProps, ...buttonProps } = props;
  const { accessibleImages, style, isUrl, altText, role, dataTestId } = accessibleImageProps;

  return (
    <ButtonFabric {...buttonProps} ref={forwardRef}>
      <AccessibleImage
        accessibleImages={accessibleImages}
        style={style}
        isUrl={isUrl}
        altText={altText}
        role={role}
        dataTestId={dataTestId}
      />
      {children}
    </ButtonFabric>
  );
});
